import React from 'react'
import { EmptyPage } from '../../components/templates'
import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>

                    <div className='modalLogin__wrapper--content forgot-password-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Glemt passord </h2>

                            <p className='subtitle'>Her kommer det noe tekst om det med <br /> glemt passord</p>

                            <div className='loginMobile'>
                                <div className='input'>
                                    <label>Epost</label>
                                    <input type="name"/>
                                </div>

                                <div className='btnContinueWrapper'>
                                    <button  className='btn btn-primary lg'>Fortsett</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>              
            </div>
  
        </EmptyPage> 
    )
}