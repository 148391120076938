import React from 'react'
import { Default } from '../../components/templates'
import Image1 from '../../assets/images/events.png'

import './style.scss'

export default function Events(): JSX.Element{
    return(        
        <Default headerColor="background">

            <div className='aboutEvents'>

                <div className='aboutEvents__header background'>

                    <div className='breadCrumbs article'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <a href="#" className='breadCrumbs__item'>Breadcrumb</a>
                                    <span className='icon-CaretRight'></span>
                                    <a href="#" className='breadCrumbs__item last'>Breadcrumb</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='intro'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='wrapper'>
                                        <h1>Arrangementer</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat dolor nisi molestie non risus volutpat. Netus dapibus nisi dolor aliquet lobortis vitae nullam. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            
                <div className='aboutEvents__list'>
  
                    <div className="section">   
                        <div className='container'>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h2>Nye arrangement</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div> 
                    <div className="section">   
                        <div className='container'>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h2>Tidligere arrangement</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                                <div className='col-md-6 col-lg-4'>
                                    <a href='#' className="item">
                                        <figure className='img aspectRatio--405x328'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <h3 className='name'>Høstauksjonen</h3>
                                            <span className="line">23.oktober - 19:00 </span>
                                            <span className="line">Tordenskiolds gate 5, Oslo</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div> 

                </div>

            </div>   
            <></>     
        </Default>        
    )
}