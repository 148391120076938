import React from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'
import ImageItem from '../../../assets/images/image-moda-list.png'

import './style.scss'

export default function modalLesMer(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLesMer active'>
                <div className='modalLesMer__wrapper'>
                    <div className='modalLesMer__wrapper--content'>
                        
                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                       
                        <div className='content title'>
                            <div className='wrapper'>

                                <h2>Vintage og fashion</h2>

                            </div>
                        </div>

                        <div className='content list'>
                            <div className='wrapper'>

                                <div className="listItems">

                                    <a href="#">
                                        <div className="item">
                                            <div className='image'>
                                                <figure>
                                                    <img src={ImageItem} alt="" />
                                                </figure>
                                            </div>
                                            <div className="info">
                                                <h3>En overskrift over ca to linjer her en plasss</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium.</p>
                                            </div>
                                        </div>
                                    </a>

                                    <a href="#">
                                        <div className="item">
                                            <div className='image'>
                                                <figure>
                                                    <img src={ImageItem} alt="" />
                                                </figure>
                                            </div>
                                            <div className="info">
                                                <h3>En overskrift over ca to linjer her en plasss</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium.</p>
                                            </div>
                                        </div>
                                    </a>

                                    <a href="#">
                                        <div className="item">
                                            <div className='image'>
                                                <figure>
                                                    <img src={ImageItem} alt="" />
                                                </figure>
                                            </div>
                                            <div className="info">
                                                <h3>En overskrift over ca to linjer her en plasss</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium.</p>
                                            </div>
                                        </div>
                                    </a>

                                    <a href="#">
                                        <div className="item">
                                            <div className='image'>
                                                <figure>
                                                    <img src={ImageItem} alt="" />
                                                </figure>
                                            </div>
                                            <div className="info">
                                                <h3>En overskrift over ca to linjer her en plasss</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium.</p>
                                            </div>
                                        </div>
                                    </a>                                    

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div> 
  
        </EmptyPage> 
    )
}