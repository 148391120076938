import React from 'react'
import ImageInstagram from '../../../assets/images/instagramImage.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import ImageGallery1 from '../../../assets/images/article-gallery.png'
import ImageGallery2 from '../../../assets/images/article-gallery-2.png'
import './style.scss'

export default function ObjectGallery(): JSX.Element{

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        fade: true,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {               
                slidesToShow: 1,
                slidesToScroll: 1
            }
          }
          
        ]
    };

    return(
        <>
        <div className="objectGalery">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                                    
                        <div className="objectGalery__wrapper">
                            <div className="items">
                                <Slider {...settings}>

                                    <div className="item">
                                        <div className="wrapper">
                                            <figure>
                                                <img src={ImageGallery1} alt="" />                                
                                            </figure>
                                            <h3>Edvard Munchs «Selvportrett» fra 1895</h3>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="wrapper">
                                            <figure>
                                                <img src={ImageGallery2} alt="" />                                
                                            </figure>
                                            <h3>Consectetur adipiscing elit. Amet pulvinar quam fames non nisl. Facilisis nec aenean amet, nunc.</h3>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="wrapper">
                                            <figure>
                                                <img src={ImageGallery1} alt="" />                                
                                            </figure>
                                            <h3>Edvard Munchs «Selvportrett» fra 1895</h3>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="wrapper">
                                            <figure>
                                                <img src={ImageGallery2} alt="" />                                
                                            </figure>
                                            <h3>Edvard Munchs «Selvportrett» fra 1895</h3>
                                        </div>
                                    </div>

                                </Slider>                                        
                            </div>                            
                        </div>
                        
                    </div>                       
                </div>
            </div>
        </div>
        </>
    )
}