import React from 'react'
import ImageHero from '../../../assets/images/image-hero.jpg'
import './style.scss'

export default function HeaderOptions(): JSX.Element{

    return(
        <>
        <div className='headerOptions'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                        <h1>Mine betalingskort</h1>                         
                    </div>
                </div>
            </div>    
        </div>      
        </>
    )
}