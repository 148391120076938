import react from 'react';
import Image1 from '../../../assets/images/excellentList1.png'
import './style.scss'


export default function ExcellentList(): JSX.Element{

    return(
        <>
        <div className="objectInContractList">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10">

                        <div className="objectInContractList__header">
                            <h3 className='title'>Objekter i Kontrakt: 2418685 (3)</h3>
                        </div>
                        

                        <div className="objectInContractList__list">

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>
                                        
                                        <div className="line showMobile">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag orange'>Signer</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag orange'>Signer kontrakt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                    </div>
                                </div>
                            </a>    

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag red'>Ikke solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag red'>Ikke solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags'><span className='tag orange'>Har vært ute 2 ganger. Ønsker du å sette ned minsteprisen?</span></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag red'>Ikke solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag red'>Ikke solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags'><span className='tag gray'>Kommer på auksjon 10/12-21</span></div>
                                    </div>
                                </div>
                            </a>                       

                        </div>

                    </div>
                </div>
            </div>
        </div>





















        </>
    )
}