import * as React from 'react'

interface TemplateProp{
    children: React.FC|JSX.Element
}

export default function Default(props: TemplateProp){
    return(
        <>
            <main>
                {props.children}
            </main>
        </>
    )
}
