import React from 'react'
import { EmptyPage } from '../../components/templates'
import './style.scss'

export default function Prototype(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='container prototype icons'>
                <div className='row'>

                    <div className="col-12">
                        <header className="index-header mt-5">
                            <h3>Icons</h3>
                        </header>
                    </div>

                    <div className='col-12'>                        
                        <code>{`
                            <span class="icon-ArrowDown"></span>
                        `}</code>
                    </div>
                   
                    <div className='col-md-4'>
                        <span className='icon-Order'></span>
                        <span>icon-Order</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Upload'></span>
                        <span>icon-Upload</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-ArrowDown'></span>
                        <span>icon-ArrowDown</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-ArrowLeft'></span>
                        <span>icon-ArrowLeft</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-ArrowRight'></span>
                        <span>icon-ArrowRight</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-ArrowUp'></span>
                        <span>icon-ArrowUp</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-ArrowUpRight'></span>
                        <span>icon-ArrowUpRight</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Bell'></span>
                        <span>icon-Bell</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Bid'></span>
                        <span>icon-Bid</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-CalendarBlank'></span>
                        <span>icon-CalendarBlank</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-CaretDown'></span>
                        <span>icon-CaretDown</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-CaretLeft'></span>
                        <span>icon-CaretLeft</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-CaretRight'></span>
                        <span>icon-CaretRight</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-CaretUp'></span>
                        <span>icon-CaretUp</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Check'></span>
                        <span>icon-Check</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Coin'></span>
                        <span>icon-Coin</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Cookie'></span>
                        <span>icon-Cookie</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-CreditCard'></span>
                        <span>icon-CreditCard</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-DotsThreeOutline'></span>
                        <span>icon-DotsThreeOutline</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-DownloadSimple'></span>
                        <span>icon-DownloadSimple</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-EnvelopeSimple'></span>
                        <span>icon-EnvelopeSimple</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Eye'></span>
                        <span>icon-Eye</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-EyeSlash'></span>
                        <span>icon-EyeSlash</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-FilePdf'></span>
                        <span>icon-FilePdf</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-FileText'></span>
                        <span>icon-FileText</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Filter'></span>
                        <span>icon-Filter</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Gear'></span>
                        <span>icon-Gear</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Handbag'></span>
                        <span>icon-Handbag</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-HandPointing'></span>
                        <span>icon-HandPointing</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Heart'></span>
                        <span>icon-Heart</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Heart-full'></span>
                        <span>icon-Heart-full</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Info'></span>
                        <span>icon-Info</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Lightbulb'></span>
                        <span>icon-Lightbulb</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-LinkSimple'></span>
                        <span>icon-LinkSimple</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-MagnifyingGlass'></span>
                        <span>icon-MagnifyingGlass</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Menu'></span>
                        <span>icon-Menu</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Package'></span>
                        <span>icon-Package</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Pencil'></span>
                        <span>icon-Pencil</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Plus'></span>
                        <span>icon-Plus</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Less'></span>
                        <span>icon-Less</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-PlusCircle'></span>
                        <span>icon-PlusCircle</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Question'></span>
                        <span>icon-Question</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-SignOut'></span>
                        <span>icon-SignOut</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Smiley'></span>
                        <span>icon-Smiley</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Trash'></span>
                        <span>icon-Trash</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Truck'></span>
                        <span>icon-Truck</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-User'></span>
                        <span>icon-User</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-WarningCircle'></span>
                        <span>icon-WarningCircle</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Wine'></span>
                        <span>icon-Wine</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-X'></span>
                        <span>icon-X</span>
                    </div>
                    <div className='col-md-4'>
                        <span className='icon-Padlock'></span>
                        <span>icon-Padlock</span>
                    </div>

                </div>            
            </div>
  

        </EmptyPage> 
    )
}