import React from 'react'
import { Default } from '../../../../components/templates'
import Visa from '../../../../assets/images/visa.svg'
import Master from '../../../../assets/images/mastercard.svg'
import './style.scss'

export default function ModalForMultipleObjects(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalForMultipleObjects active'> */}
        <div className='modalForMultipleObjects active'>
            <div className='modalForMultipleObjects__wrapper'>
                <form className='modalForMultipleObjects__wrapper--content heightInitial'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Fullfør betaling</h2>
                            </div>

                            <div className='form-group'>
                                <label className='iconCard' htmlFor="Kortnummer">Kortnummer</label>
                                <div className="iconInput">
                                    <span className='icon-CreditCard'></span>
                                    <input type="text" defaultValue="**** 3456" id="Kortnummer"/>
                                </div>
                            </div>
                            
                            <div className='form-group'>
                                <label htmlFor="Utløpsdato">Utløpsdato MM / ÅÅÅÅ</label>
                                <div className="iconInput">
                                    <span className='icon-CalendarBlank'></span>
                                    <input type="tel" defaultValue="10 / 2024" id="Utløpsdato"/>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="cvc">UVertefiserings </label>
                                <div className="iconInput">
                                    <span className='icon-Padlock'></span>
                                    <input type="tel" defaultValue="***" id="cvc"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <div className="priceWrapper">
                            <div className='value'> 2.500 NOK</div>
                        </div>
                        <div className='btns'>
                            <button className='btn btn-gray sm'>Avslutt</button>
                            <button className='btn btn-primary sm'>Fullfør betaling</button>
                        </div>
                    </div>

                </form>
            </div>              
        </div>     
        <></>
        
        </Default>        
    )
}