import React,{useState} from 'react'
import ImageObject from '../../../assets/images/object1.jpg'
import ImageObject2 from '../../../assets/images/object2.png'
import ImageObject3 from '../../../assets/images/object3.png'
import ImageObject4 from '../../../assets/images/object4.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import './style.scss'


interface ObjectTabsProps{
    onTabSelect: (tab:string)=>unknown
}


export default function ObjectsTabs(props:ObjectTabsProps): JSX.Element{

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1420,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 4,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 1200,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 3,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 890,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 2,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 575,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 1,
                slidesToScroll: 1
            }
          }
          
        ]
    };


    return(
        <>
        <div className='objectsTabs'>
            
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='objectsTabs__navTabs'>
                            <button onClick={()=>{props.onTabSelect("siste")}} data-tab="1" className='active'>Siste sjanse</button>
                            <button onClick={()=>{props.onTabSelect("nye")}} data-tab="2">Nye objekter</button>
                        </div>                        
                    </div>

                    <div className='objectsTabs__content'>

                        <div data-tab="1" className="objectsTabs__content--wrapper active">
                            
                            <Slider {...settings}>
                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">
                                            <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject} alt="" />
                                            </figure>
                                        </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Erik Werenskiold</a>
                                        <div className='line'>Speiling</div>
                                        <div className='line'>200.000 - 250.000 NOK</div>
                                        <div className='line'>Klassisk auksjon: Kat.nr. 5</div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">
                                            <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject} alt="" />
                                            </figure>
                                        </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Lysestake</a>
                                        <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                        <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                       <a href="#">
                                        <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject3} alt="" />
                                            </figure>
                                       </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Chanel</a>
                                        <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                        <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">
                                            <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject4} alt="" />
                                            </figure>
                                        </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Hérmes</a>
                                        <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                        <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">
                                            <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject2} alt="" />
                                            </figure>
                                        </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Lysestake</a>
                                        <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                        <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                    </div>
                                </div>
                            </Slider>

                        </div>

                    </div>


                </div>
            </div>
        
        </div>        
        </>
    )
}