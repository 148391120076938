import React from 'react'
import { Default } from '../../../components/templates'
import ArticleImage1 from '../../../assets/images/article-image-item-1.png'
import ArticleImage2 from '../../../assets/images/article-image-item-2.png'
import ArticleImage3 from '../../../assets/images/article-image-item-3.png'
import ArticleImage4 from '../../../assets/images/article-image-item-4.png'
import ArticleImage5 from '../../../assets/images/article-image-item-5.png'
import ArticleImage6 from '../../../assets/images/article-image-item-6.png'
import Dot from '../../../assets/images/dot.svg'

import './style.scss'

export default function PageList(): JSX.Element{
    return(        
        <Default headerColor="background">

            <div className='articles'>

                <div className='articles__header background'>

                    <div className='breadCrumbs article'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <a href="#" className='breadCrumbs__item'>Hjem</a>
                                    <span className='icon-CaretRight'></span>
                                    <a href="#" className='breadCrumbs__item last'>Artikler</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='intro'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='wrapper'>
                                        <h1>Artikler</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat dolor nisi molestie non risus volutpat. Netus dapibus nisi dolor aliquet lobortis vitae nullam. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            
                <div className='articles__list'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                
                                <div className="categories">
                                    <div className="categories__seeAll">
                                        <button>Nye arrangement</button>
                                    </div>
                                    <div className="categories__items">
                                        <a href="#" className='item active'>Vis alle</a>
                                        <a href="#" className='item'>Kunst</a>
                                        <a href="#" className='item'>Unge samlere</a>
                                        <a href="#" className='item'>Klokker</a>
                                        <a href="#" className='item'>Vin</a>
                                        <a href="#" className='item'><img src={Dot} alt="" /></a>
                                    </div>
                                </div>

                                <div className="items">

                                    <div className="item">
                                        <img src={ArticleImage6} alt="" />
                                        <div className="info">
                                            <span>Unge samlere</span>
                                            <h3>Møt Silje Sigurdsen</h3>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <img src={ArticleImage5} alt="" />
                                        <div className="info">
                                            <span>Vin</span>
                                            <h3>10 råd som gjør deg til vinekspert </h3>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <img src={ArticleImage4} alt="" />
                                        <div className="info">
                                            <span>Vin</span>
                                            <h3>Disse vinene solgte vi i oktober</h3>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <img src={ArticleImage3} alt="" />
                                        <div className="info">
                                            <span>Klokker</span>
                                            <h3>Klokke som investering?</h3>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <img src={ArticleImage2} alt="" />
                                        <div className="info">
                                            <span>Klokker</span>
                                            <h3>Heuer Monaco brukt av Steve McQueen</h3>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <img src={ArticleImage1} alt="" />
                                        <div className="info">
                                            <span>Vintage</span>
                                            <h3>Nå er det vintage-vesker som gjelder</h3>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>                
                </div>

                <div className="articles__pagination">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="wrapper">
                                    <div className="column">
                                        <button><span className='icon-ArrowLeft'></span><i className='desktop'>Forrige side</i><i className='mobile'>Forrige</i></button>    
                                    </div>
                                    <div className="column"><span className='pages'>2/3</span></div>
                                    <div className="column">
                                        <button><i className='desktop'>Neste side</i><i className='mobile'>Neste</i> <span className='icon-ArrowRight'></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>   
            <></>     
        </Default>        
    )
}