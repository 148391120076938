import { useState, useEffect, useRef } from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'
import '../style.scss'

export default function ModalObjectView(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalObjectView active'>
                <div className='modalObjectView__wrapper'>
                    <div className='modalObjectView__wrapper--content'>

                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                        
                        <div className='content'>
                            <div className='wrapper'>

                                <div className='title'>
                                    <h2>Vikår og betingelser</h2>
                                </div>

                                <p>Scelerisque risus pretium, nullam id sem. Eu, vel, proin arcu morbi sit id. Cum facilisi viverra non, maecenas sed lorem nisl accumsan.</p>

                                <p>Dui aliquam sed volutpat molestie dui enim. Turpis sit id nibh diam amet, mauris congue quis. Congue odio cras tortor aliquet dui risus. Donec non libero, cursus nibh nunc, risus tempor, odio. Urna arcu libero, ac ut. Mollis quam ornare commodo consectetur quam mauris, adipiscing semper. Risus eget porttitor morbi faucibus diam elit. Ut velit quis egestas sit ut dui ac ac. </p>

                                <p>Tempor eget diam phasellus placerat vitae at varius enim. Elementum morbi nec id gravida odio in nulla. In gravida dis consectetur id a, tincidunt commodo sem augue. Feugiat morbi in ac sollicitudin id ac, sodales. Etiam est dui in tempor sed suscipit. d ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illoSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore  inventore </p>

                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illoSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore  inventore </p>

                                <p>Sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore  inventore </p>

                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa </p>
                                
                            </div>
                        </div>

                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Lukk vindu</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}