import React from 'react'
import {
    PasswordInput
} from '../../../components/primitives'
import SelectSearch from 'react-select-search'
import './style.scss'

export default function ModalEditProfile(): JSX.Element{
    return(
        <>
 

        {/* Add .active class to enable login modal: <div className='modalEditProfile active'> */}
        <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Endre Adresse</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="address">Gateadresse</label>
                                <input type="text" defaultValue="Sorgenfrigata 10A" id="address"/>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="postal-code">Postnummer</label>
                                <input type="tel" defaultValue="0367" id="postal-code"/>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="city">Poststed</label>
                                <input type="text" defaultValue="0367" id="city"/>
                            </div>
                            
                            <div className='form-group'>
                                <label htmlFor="country">Land</label>
                                <input type="text" defaultValue="Norge" id="country"/>
                            </div>                 

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>    

        {/* -------- Endre passord -------- */}
        {/* <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Endre passord</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="Passord">Gammalt passord</label>
                                <PasswordInput/>                                        
                            </div>    

                            <div className='form-group'>
                                <label htmlFor="Passord">Nytt passord</label>
                                <PasswordInput/>                                        
                            </div>                             

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>     */}
        {/* -------- Endre passord -------- */}

        {/* -------- Endre personalia -------- */}
        {/* <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Endre personalia</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="name">Navn</label>
                                <input type="text" defaultValue="Elisabeth" id="name"/>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="last-name">Etterna vn</label>
                                <input type="text" defaultValue="Henriksen" id="last-name"/>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="birth">Født</label>
                                <input type="text" defaultValue="01.05.1996" id="birth"/>
                            </div>                            

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>     */}
        {/* -------- Endre personalia -------- */}

        {/* -------- Endre kontakt -------- */}
        {/* <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Endre kontakt</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="email">E-post</label>
                                <input type="email" defaultValue="elisabethhenriksen@gmail.com" id="email"/>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="tel">Mobil</label>
                                <input type="tel" defaultValue="92098647" id="tel"/>
                            </div>                     

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>   */}
        {/* -------- Endre kontakt -------- */}

        {/* -------- Endre Levering av vin -------- */}
        {/* <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Levering av vin</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="place">Utleveringssted</label>
                                <div className='select'>
                                    <SelectSearch
                                        options={[
                                            { value: 'option1', name: 'Option1'},
                                            { value: 'Fornebu', name: 'Fornebu'},
                                            { value: 'option2', name: 'Option2'},
                                            { value: 'option3', name: 'Option3'},
                                            { value: 'option4', name: 'Option4'},
                                            { value: 'option5', name: 'Option5'}
                                        ]}
                                        placeholder="Utleveringssted"
                                        value={'Fornebu'}
                                    />
                                </div>
                                <div className='hint-text' >Her kan du velge utlevering av vin. Har du ikke valgt noe hentes vinen hos oss på Fornebu.</div>                                      
                            </div>

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>   */}
        {/* -------- Endre Levering av vin -------- */}

        {/* -------- Endre Annet -------- */}
        {/* <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Annet</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="lenguage">Språk</label>
                                <div className='select'>
                                    <SelectSearch
                                        options={[
                                            { value: 'option1', name: 'Option1'},
                                            { value: 'Fornebu', name: 'Fornebu'},
                                            { value: 'option2', name: 'Option2'},
                                            { value: 'option3', name: 'Option3'},
                                            { value: 'option4', name: 'Option4'},
                                            { value: 'option5', name: 'Option5'}
                                        ]}
                                        placeholder="Utleveringssted"
                                        value={'Fornebu'}
                                    />
                                </div>
                                <div className='hint-text' >Her kan du velge utlevering av vin. Har du ikke valgt noe hentes vinen hos oss på Fornebu.</div>    
                            </div>

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>     */}
        {/* -------- Endre Annet -------- */}

        </>
    )
}