import React from 'react'
import ImageimageTextBlockFull from '../../../assets/images/image-text-block.jpg'
import './style.scss'

export default function ImageTextBlockFull(): JSX.Element{

    return(
        <>
        <div className='imageTextBlockFull'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='imageTextBlockFull__image'>
                            <figure className='img aspectRatio--624x596'>
                                <img src={ImageimageTextBlockFull} alt="" />
                            </figure>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='imageTextBlockFull__content'>
                            <div className='content'>
                                <h2>Blomqvist har over 150 år med erfaring</h2>
                                <div className='mobileImage'>
                                    <figure className='img aspectRatio--318x232'>
                                        <img src={ImageimageTextBlockFull} alt="" />
                                    </figure>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non sem ultrices magna rutrum purus, et velit.</p>
                                <a className="btn btn-light lg" href="#">Lær mer om historien</a>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}