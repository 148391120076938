import React from 'react'
import ObjectImage from '../../../assets/images/object-image-modal.png'
import ObjectImage2 from '../../../assets/images/object-image-modal.png'
import ObjectImage3 from '../../../assets/images/object-image-modal.png'
import ObjectImage4 from '../../../assets/images/object-image-modal.png'
import ZoomMobile from '../../../assets/images/zoom-mobile.svg'
import InnerImageZoom from 'react-inner-image-zoom';
import ObjectImageZoom from '../../../assets/images/object-image-modal-lg.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import './style.scss'

export default function ModalObjectImage(): JSX.Element{

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return(
        <>
        {/* Add .active class to enable login modal: <div className='modalObjectImage active'> */}
        <div className='modalObjectImage active'>

            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
            
            <div className='modalObjectImage__wrapper desktop'>
                
                <h2 className='objectName'>Sausenebb</h2>      
                
                <div className="gallery">
                    <div className="item active">
                        <figure className='img aspectRatio--1x1'>
                            <img src={ObjectImage} alt="" />
                        </figure>
                    </div>
                    <div className="item">
                        <figure className='img aspectRatio--1x1'>
                            <img src={ObjectImage2} alt="" />
                        </figure>
                    </div>
                    <div className="item">
                        <figure className='img aspectRatio--1x1'>
                            <img src={ObjectImage3} alt="" />
                        </figure>
                    </div>
                    <div className="item">
                        <figure className='img aspectRatio--1x1'>
                            <img src={ObjectImage4} alt="" />
                        </figure>
                    </div>
                </div>

                <div className="feaaturedIMage">
                    <div className="wrappper">
                        <InnerImageZoom src={ObjectImage} zoomSrc={ObjectImageZoom} hideCloseButton={true} />
                    </div>                    
                </div>

            </div>

            <div className='modalObjectImage__wrapper mobile'>

                <img className='zoomMobile' src={ZoomMobile} alt="" />   

                <div className="ObjectImageSlider">
                    <Slider {...settings}>
                        <div className='item'>
                            <div className='image'>                            
                                <img src={ObjectImage} alt="" />                            
                            </div>
                        </div>

                        <div className='item'>
                            <div className='image'>                            
                                <img src={ObjectImage} alt="" />                            
                            </div>
                        </div>

                        <div className='item'>
                            <div className='image'>                            
                                <img src={ObjectImage} alt="" />                            
                            </div>
                        </div>

                        <div className='item'>
                            <div className='image'>                            
                                <img src={ObjectImage} alt="" />                            
                            </div>
                        </div>                    
                    </Slider>                    
                </div>
            </div>

        </div>     
        </>
    )
}