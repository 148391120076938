import React from 'react'
import { Default } from '../../../../components/templates'
import BankID from '../../../../assets/images/embed-BankID.png'
import './style.scss'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span>Mine kjøp og betalinger</a>
                            <h1 >Delbetaling med Walley</h1>                         
                        </div>
                    </div>
                </div>
            </div>

            <div className='mock'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <span>Her må Seeds finne ut hvordan dette skal være </span>
                        </div>
                    </div>
                </div>
            </div>
            <></>

        </Default>
    )
}