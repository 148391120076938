import React from 'react'
import { Default } from '../../../components/templates'
import Image1 from '../../../assets/images/excellentList1.png'
import SelectSearch from 'react-select-search'
import {
    MyFavoriteList
} from '../../../components/modules'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span>Min side</a>
                            <h1>Mine favoritter</h1>                         
                        </div>
                    </div>
                </div>    
            </div>

            <MyFavoriteList/>

            <></>

        </Default>        
    )
}