import react from 'react';
import Image1 from '../../../assets/images/excellentList1.png'
import Image2 from '../../../assets/images/excellentList2.png'
import Image3 from '../../../assets/images/excellentList3.png'
import './style.scss'



export default function SelectItemsForPayment(): JSX.Element{

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10">
                    <div className="selectItemsForPayment">
                        <div className="selectItemsForPayment__header">
                            <div className='info'>
                                <span className='title'>Velg objekter for betaling</span>                                
                            </div>
                            <div className='options'>
                                <button className='btn-link'>Velg alle</button>
                                <button className='btn-link'>Avslutt</button>
                            </div>
                        </div>
                        <div className="selectItemsForPayment__list">

                            <div className="item">                             
                                <input type="checkbox" id="item1" name="item1"  />                                
                                <label htmlFor="item1">
                                    <div className="content">
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <h3 className='name'>Navn på objekt</h3>
                                                <div className="number desktop">Objektnummer: <strong >141151-1</strong></div>
                                                <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="delivery">Levering : <strong>Postpakke</strong></div>
                                            </div>
                                            <div className="payment">
                                                <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="line"> Autotrekk: 14.10.2022  |  Visa **** 4385</div>
                                            </div>
                                        </div>
                                    </div>
                                </label>

                            </div>

                            <div className="item">
                                <input type="checkbox" id="item2" name="item2"  />
                                <label htmlFor="item2">
                                    <div className="content">
                                        <div className="image">
                                            <img src={Image2} alt="" />
                                        </div>
                                            <div className="info">
                                            <div className="data">
                                                <h3 className='name'>Navn på objekt</h3>
                                                <div className="number desktop">Objektnummer: <strong>141151-1</strong></div>
                                                <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="delivery">Utlevering : <strong> Hentes på Fornebu</strong></div>
                                            </div>
                                            <div className="payment">
                                                <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="line"> Autotrekk: 14.10.2022  |  Visa **** 4385</div>
                                            </div>
                                        </div>  
                                    </div>
                                </label> 
                            </div>

                            <div className="item">
                                <input type="checkbox" id="item3" name="item3"/>
                                <label htmlFor="item3">
                                    <div className="content">
                                        <div className="image">
                                            <img src={Image3} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <h3 className='name'>Navn på objekt</h3>
                                                <div className="number desktop">Objektnummer: <strong>141151-1</strong></div>
                                                <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="delivery">Utlevering : <strong>Hentes i Bergen fra 10.01.22</strong></div>
                                            </div>
                                            <div className="payment">
                                                <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="line"> Autotrekk: 14.10.2022 | Visa **** 4385</div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </label>                                
                            </div>

                            <div className="item">                             
                                <input type="checkbox" id="item4" name="item4"  />                                
                                <label htmlFor="item4">
                                    <div className="content">
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <h3 className='name'>Navn på objekt</h3>
                                                <div className="number desktop">Objektnummer: <strong >141151-1</strong></div>
                                                <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="delivery">Levering : <strong>Postpakke</strong></div>
                                            </div>
                                            <div className="payment">
                                                <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="line"> Autotrekk: 14.10.2022  |  Visa **** 4385</div>
                                            </div>
                                        </div>
                                    </div>
                                </label>

                            </div>

                            <div className="item">
                                <input type="checkbox" id="item5" name="item5"  />
                                <label htmlFor="item5">
                                    <div className="content">
                                        <div className="image">
                                            <img src={Image2} alt="" />
                                        </div>
                                            <div className="info">
                                            <div className="data">
                                                <h3 className='name'>Navn på objekt</h3>
                                                <div className="number desktop">Objektnummer: <strong>141151-1</strong></div>
                                                <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="delivery">Utlevering : <strong> Hentes på Fornebu</strong></div>
                                            </div>
                                            <div className="payment">
                                                <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="line"> Autotrekk: 14.10.2022  |  Visa **** 4385</div>
                                            </div>
                                        </div>  
                                    </div>
                                </label> 
                            </div>

                            <div className="item">
                                <input type="checkbox" id="item6" name="item6"/>
                                <label htmlFor="item6">
                                    <div className="content">
                                        <div className="image">
                                            <img src={Image3} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <h3 className='name'>Navn på objekt</h3>
                                                <div className="number desktop">Objektnummer: <strong>141151-1</strong></div>
                                                <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="delivery">Utlevering : <strong>Hentes i Bergen fra 10.01.22</strong></div>
                                            </div>
                                            <div className="payment">
                                                <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                                <div className="line"> Autotrekk: 14.10.2022 | Visa **** 4385</div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </label>                                
                            </div>

                            <div className='btnWrapper'>
                                <button className='btn btn-primary sm'>Gå til betaling for 5 objekter</button>
                            </div>

                        </div>
                    </div>                        
                </div>
            </div>
        </div>   
        </>
    )
}