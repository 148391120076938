import React, {useState} from 'react'
import {Header, Footer, GridView, HeaderSticky, HiddenMenu, ModalLogin} from '../../modules'

interface TemplateProp{
    children: React.FC|JSX.Element[]
    headerColor?: "light"|"green-15"|"blue-20"|"dark-blue"|"olive-40"|"copper-40"|"background"
    frontpage?:boolean
}

export default function Default(props: TemplateProp){
    const [hiddenMenu, setHiddenMenu] = useState<boolean>(false)

    const toggle = ()=>{
        setHiddenMenu(!hiddenMenu)
    }

    return(
        <>
            <ModalLogin/>
            <HiddenMenu active={hiddenMenu} toggle={toggle}/>
            <HeaderSticky toggle={toggle} />
            <Header frontpage={props.frontpage} color={props.headerColor??"light"} toggle={toggle} />
            <main>
                {props.children}
            </main>                
            <Footer/>
            <GridView/>
        </>
    )
}
