import React from 'react'
import { Default } from '../../../components/templates'
import ImageFeatured from '../../../assets/images/article-feature-image.png'
import { 
    RelatedArticles,
    GalleryArticle
} from '../../../components/modules'

export default function Article(): JSX.Element{
    return(        
        <Default headerColor="background">

            <div className='article'>

                <div className='article__header background'>

                    <div className='breadCrumbs article'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <a href="#" className='breadCrumbs__item'>Breadcrumb</a>
                                    <span className='icon-CaretRight'></span>
                                    <a href="#" className='breadCrumbs__item last'>Breadcrumb</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='title'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h1>Headline here</h1>                         
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="article__imageFeature background">
                    <div className='container'>
                        <div className='row'>
                            <div className='offset-lg-1 col-lg-10'>
                                <figure className='img aspectRatio--1062x698'>
                                    <img src={ImageFeatured} alt="" />                                
                                </figure>
                                <span className='figcaption'>Lorem ipsum dolor sit amet.</span>
                            </div>
                        </div>
                    </div>                                
                </div>

                <div className="article__content"> 

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                  
                                <div className="article__content--intro">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id sapien laoreet nunc viverra phasellus. Quis velit, habitasse odio eros. Odio vel, tellus aliquam diam interdum. Fames pellentesque eu eu facilisis massa purus turpis. Venenatis ultrices arcu enim quam malesuada. Adipiscing.</p>
                                </div>

                                <div className="article__content--wrapper">

                                    <h2>Headline here</h2>   

                                    <p>In euismod eu nisl nulla faucibus tincidunt tincidunt. At imperdiet netus enim tortor. Vitae, duis nunc donec et consequat sed sollicitudin cursus in. At purus.</p>

                                </div>   

                            </div> 
                        </div> 
                    </div> 

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="article__content--wrapper">

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies. Tellus consectetur id sit sit leo aliquet sed vulputate. At ornare egestas pellentesque et. Enim ac penatibus quam fermentum aliquet. Arcu justo in egestas vitae varius eget accumsan.</p>

                                </div>   

                            </div>
                        </div>
                    </div> 

                    <GalleryArticle/>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="article__content--wrapper">

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies. Tellus consectetur id sit sit leo aliquet sed vulputate. At ornare egestas pellentesque et. Enim ac penatibus quam fermentum aliquet. Arcu justo in egestas vitae varius eget accumsan.</p>

                                </div>   

                            </div>
                        </div>
                    </div> 

                </div>            
            </div>   
            <RelatedArticles/>   
        </Default>        
    )
}