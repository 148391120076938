import React from 'react'
import Image1 from '../../../assets/images/info-card-1.png'
import Image2 from '../../../assets/images/info-card-2.png'
import './style.scss'

export default function InfoCard(): JSX.Element{

    return(
        <>
        <div className='infoCard'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>

                        <div className='infoCard__wrapper'>

                            <div className="item">
                                <div className="image">
                                    <h3>Levering og henting</h3>
                                    <figure className='img aspectRatio--370x279'>
                                        <img src={Image1} alt="" />
                                    </figure>
                                </div>
                                <div className="info">
                                    <h3>Levering og henting</h3>
                                    <p>Tellus proin vel facilisis id morbi sit eu libero. Eu vulputate diam ac adipiscing.</p>
                                    <button className='btn btn-secondary lg'>Finn ut mer</button>
                                </div>
                            </div>

                            <div className="item">
                                <div className="image">
                                    <h3>Online vurdering</h3>
                                    <figure className='img aspectRatio--370x279'>
                                        <img src={Image2} alt="" />
                                    </figure>
                                </div>
                                <div className="info">
                                    <h3>Online vurdering</h3>
                                    <p>Tellus proin vel facilisis id morbi sit eu libero. Eu vulputate diam ac adipiscing.</p>
                                    <button className='btn btn-secondary lg'>Finn ut mer</button>
                                </div>
                            </div>

                        </div>        

                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}