import React,{useState} from 'react'
import ImageObject from '../../../assets/images/object1.jpg'
import ImageObject2 from '../../../assets/images/object2.png'
import './style.scss'

export default function MyFavorite(): JSX.Element{

    const [options,setOptions] = useState(false)
    const toggleOptions = ()=>{
        setOptions(!options)
    }

    const [modalEdit,setModalEdit] = useState(false)
    const toggleModaEdit = ()=>{
        setModalEdit(!modalEdit)
    }
    
    const [modalMakeAbid,setmodalMakeAbid] = useState(false)
    const toggleModalMakeAbid = ()=>{
        setmodalMakeAbid(!modalMakeAbid)
    }

    return(
        <>
            <div className="my-favorite-list">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10">

                            <div className="my-favorite-list__header">
                                <span className='title showMobile'>Aktive bud</span>
                                <div className="options">
                                    <button className='hideMobile'><span className='icon-Pencil'></span></button>                                    
                                    <button className='showMobile' onClick={toggleOptions}><span className='icon-DotsThreeOutline'></span></button>
                                </div>

                                <div className={"optionsMobile " + `${options?'active':''}`}>
                                    <div className='optionsMobile__wrapper'>
                                        <div className='optionsMobile__wrapper--content'>

                                            <div className='btnCloseModalWrapper'>
                                                <button className='btnCloseModal' aria-label='Close' onClick={toggleOptions}><span className='icon-X'></span></button>
                                            </div>
                                            
                                            <div className='content' id='contentH'>
                                                <div className='wrapper' id='wrapperH'>

                                                    <div className="items">
                                                        <div className="item">
                                                            <button onClick={toggleModaEdit}><span className='icon-Trash'></span>Slett favoritter fra listen</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="my-favorite-list__list">

                                <div className="item">
                                    <div className="image">
                                        <img src={ImageObject} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <div className="name">
                                                <h3>Navn på objekt</h3>
                                            </div>
                                            <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                            <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                            <div className="line showMobile">Neste bud: <strong>9.000 NOK</strong></div>
                                            <div className="line showMobile">Tid igjen: <strong>15d 6t 15min</strong></div>
                                            <div className="tags showMobile">
                                                <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                                <span className="tag green">Du leder</span>
                                            </div>
                                        </div>
                                        <div className="status">
                                            <div className="tags">
                                                <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                                <span className="tag green">Du leder</span>
                                            </div>
                                            <div className="line">Tid igjen: <strong>15d 6t 15min</strong></div>
                                            <div className="line">Nåværende bud: <strong>9.000 NOK</strong></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src={ImageObject2} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <div className="name">
                                                <h3>Navn på objekt</h3>
                                            </div>
                                            <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                            <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                            <div className="line showMobile">Neste bud: <strong>9.000 NOK</strong></div>   
                                            <div className="line showMobile">Tid igjen: <strong>15d 6t 15min</strong></div>
                                            <div className="tags showMobile">
                                                <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                                <span className="tag red">Du er overbydd</span>
                                            </div>
                                        </div>
                                        <div className="status">
                                            <div className="tags">
                                                <span className="tag gray">Gi bud</span>
                                                <span className="tag red">Du er overbydd</span> 
                                            </div>
                                            <div className="line">Tid igjen: <strong>15d 6t 15min</strong></div>
                                            <div className="line">Neste bud: <strong>9.000 NOK</strong></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src={ImageObject2} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <div className="name">
                                                <h3>Kunstnernavn her</h3>
                                            </div>
                                            <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                            <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                            <div className="line showMobile">Klassisk auksjon: <strong>Kat.nr. 5</strong></div>
                                            <div className="line showMobile">Dato: <strong>07.11.2021 Kl 19:00</strong></div>
                                            <div className="tags showMobile"></div>
                                        </div>
                                        <div className="status">
                                            <div className="tags"></div>
                                            <div className="line">Klassisk auksjon: <strong>Kat.nr. 5</strong></div>
                                            <div className="line">Auksjons dato: <strong>07.11.2021 Kl 19:00</strong></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src={ImageObject} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <div className="name">
                                                <h3>Navn på objekt</h3>
                                            </div>
                                            <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                            <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                            <div className="line showMobile">Solgt for: <strong>9.000 NOK</strong></div>
                                            <div className="tags showMobile">
                                                <span className="tag gray">Du vant</span>
                                            </div>
                                        </div>
                                        <div className="status">
                                            <div className="tags">
                                                <span className="tag gray">Du vant</span>
                                            </div>
                                            <div className="line">Solgt for: <strong>9.000 NOK</strong></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src={ImageObject2} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <div className="name">
                                                <h3>Kunstnernavn her</h3>
                                            </div>
                                            <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                            <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                            <div className="line showMobile">Solgt for: <strong>9.000 NOK</strong></div>
                                            <div className="tags showMobile"></div>
                                        </div>
                                        <div className="status">
                                            <div className="tags"></div>
                                            <div className="line">Solgt for: <strong>9.000 NOK</strong></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <img src={ImageObject2} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                        <div className="name">
                                                <h3>Navn på objekt</h3>
                                            </div>
                                            <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                            <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                            <div className="line showMobile">Solgt for: <strong>9.000 NOK</strong></div>
                                            <div className="tags showMobile">
                                                <span className="tag gray">Du ble overbydd</span>
                                            </div>
                                        </div>
                                        <div className="status">
                                            <div className="tags">
                                                <span className="tag gray">Du ble overbydd</span>
                                            </div>
                                            <div className="line">Solgt for: <strong>9.000 NOK</strong></div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            {/* Modal: Delete favorite (Velg objekter for sletting) */}
                            <div className={"listEditMobile " + `${modalEdit?'active':''}`}>
                                <div className='listEditMobile__wrapper'>
                                    <div className='listEditMobile__wrapper--content'>

                                        <div className='btnCloseModalWrapper'>
                                            <h3>Velg objekter for sletting</h3>
                                            <button className='btnCloseModal' aria-label='Close' onClick={toggleModaEdit}><span className='icon-X'></span></button>
                                        </div>
                                        
                                        <div className='content' id='contentH'>
                                            <div className='wrapper' id='wrapperH'>

                                                <div className="list">

                                                    <div className="item">
                                                        <input type="checkbox" id="item1" name="item1"  />
                                                        <label htmlFor="item1">
                                                            <div className="content">
                                                                <div className="image">
                                                                    <img src={ImageObject} alt="" />
                                                                </div>
                                                                <div className="info">
                                                                    <div className="data">
                                                                        <div className="name">
                                                                            <h3>Navn på objekt</h3>
                                                                        </div>
                                                                        <div className="line">Neste bud: <strong>9.000 NOK</strong></div>
                                                                        <div className="line">Tid igjen: <strong>15d 6t 15min</strong></div>
                                                                        <div className="tags">
                                                                            <span className="tag green">Du leder</span>
                                                                            <span className="tag gray btn">Gi bud</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div className="item">
                                                        <input type="checkbox" id="item2" name="item2"  />
                                                        <label htmlFor="item2">
                                                            <div className="content">
                                                                <div className="image">
                                                                    <img src={ImageObject2} alt="" />
                                                                </div>
                                                                <div className="info">
                                                                    <div className="data">
                                                                        <div className="name">
                                                                            <h3>Navn på objekt</h3>
                                                                        </div>
                                                                        <div className="line">Neste bud: <strong>9.000 NOK</strong></div>   
                                                                        <div className="line">Tid igjen: <strong>15d 6t 15min</strong></div>
                                                                        <div className="tags">
                                                                            <span className="tag red">Du er overbydd</span>
                                                                            <span className="tag gray btn">Gi bud</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label> 
                                                    </div>

                                                    <div className="item">
                                                        <input type="checkbox" id="item3" name="item3"  />
                                                        <label htmlFor="item3">
                                                            <div className="content">
                                                                <div className="image">
                                                                    <img src={ImageObject} alt="" />
                                                                </div>
                                                                <div className="info">
                                                                    <div className="data">
                                                                        <div className="name">
                                                                            <h3>Navn på objekt</h3>
                                                                        </div>
                                                                        <div className="line">Neste bud: <strong>9.000 NOK</strong></div>
                                                                        <div className="line">Tid igjen: <strong>15d 6t 15min</strong></div>
                                                                        <div className="tags">
                                                                            <span className="tag green">Du leder</span>
                                                                            <span className="tag gray btn">Gi bud</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div className="item">
                                                        <input type="checkbox" id="item4" name="item4"  />
                                                        <label htmlFor="item4">
                                                            <div className="content">
                                                                <div className="image">
                                                                    <img src={ImageObject2} alt="" />
                                                                </div>
                                                                <div className="info">
                                                                    <div className="data">
                                                                        <div className="name">
                                                                            <h3>Navn på objekt</h3>
                                                                        </div>
                                                                        <div className="line">Neste bud: <strong>9.000 NOK</strong></div>   
                                                                        <div className="line">Tid igjen: <strong>15d 6t 15min</strong></div>
                                                                        <div className="tags">
                                                                            <span className="tag red">Du er overbydd</span>
                                                                            <span className="tag gray btn">Gi bud</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label> 
                                                    </div>



                                                </div>

                                            </div>
                                        </div>

                                        <div className="btnWrapper">
                                            <button className='btn btn-gray sm' onClick={toggleModaEdit}>Avslutt</button>
                                            <button className='btn btn-primary sm'>Slett valgte (44)</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* Modal: Delete favorite (Velg objekter for sletting) */}

                            {/* Modal: Make an offer (Gi bud) */}
                            <div className={"makeAbidMobile " + `${modalMakeAbid?'active':''}`}>
                                <div className='makeAbidMobile__wrapper'>
                                    <div className='makeAbidMobile__wrapper--content'>

                                        <div className='btnCloseModalWrapper'>
                                            <button className='btnCloseModal' aria-label='Close' onClick={toggleModalMakeAbid}><span className='icon-X'></span></button>
                                        </div>
                                        
                                        <div className='content' id='contentH'>
                                            <div className='wrapper' id='wrapperH'>

                                                <div className="title">
                                                    <h2>Gi bud</h2>
                                                    <h3>Velg ditt nye maksbuds</h3>
                                                </div>

                                                <div className="list">
                                                    <div className="item active"><span>1 500 NOK</span></div>
                                                    <div className="item"><span>2 000 NOK</span></div>
                                                    <div className="item"><span>2 500 NOK</span></div>
                                                    <div className="item"><span>3 000 NOK</span></div>
                                                    <div className="item"><span>3 500 NOK</span></div>
                                                    <div className="item"><span>4 500 NOK</span></div>
                                                    <div className="item"><span>5 000 NOK</span></div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="btnWrapper">
                                            <button className='btn btn-gray sm' onClick={toggleModalMakeAbid}>Avslutt</button>
                                            <button className='btn btn-primary sm'>Gi bud</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* Modal: Make an offer (Gi bud)*/}

                        </div>
                    </div>
                </div>
            </div>   
        </>
    )
}