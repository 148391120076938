import React from 'react'
import { EmptyPage } from '../../components/templates'
import VippsLogoColor from '../../assets/images/vipps-logo-color.svg'

import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>
                
                    <div className='modalLogin__wrapper--content info-vipps-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Så kommer det flere <br /> steg - resten av logg inn<br /> prosedyren hentes fra vipps </h2>
                            <img className='vippsLogoColor' src={VippsLogoColor} alt="Vipps" />
                        </div>
                    </div>

                </div>              
            </div>
  
        </EmptyPage> 
    )
}