import React from 'react'
import ImageBLock9 from '../../../assets/images/image-block-9.png'
import './style.scss'

export default function ImageBlockLandscape1(): JSX.Element{

    return(
        <>
        <div className='imageBlockLandscapet-2'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='imageBlockLandscapet-2__wrapper'>

                            <div className="items">
                                <div className="item item1">
                                    <figure className='img aspectRatio--405x288'>
                                        <img src={ImageBLock9} alt="" />
                                    </figure>                                
                                </div>
                                <div className="item item1">
                                    <figure className='img aspectRatio--405x288'>
                                        <img src={ImageBLock9} alt="" />
                                    </figure>                                
                                </div>
                            </div>
                                
                        </div>   
                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}