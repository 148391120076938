import React from 'react'
import { Default } from '../../components/templates'
import Emptyframe from '../../assets/images/emtyframe.png'
import './style.scss'
import { 
    CookieBanner
} from '../../components/modules'

export default function Error404(): JSX.Element{
    return(        
        <Default headerColor="copper-40">
            <></>        
            <div className="error404-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="error404-1__wrapper">

                                <div className="frameWrapper">

                                    <div className="frame">
                                        <img src={Emptyframe} alt="" />
                                        <div className="content">
                                            <h1 className='title'>
                                                Vi har <br />
                                                dessverre ikke <br />
                                                noe å vise <br />
                                                på denne <br />
                                                siden
                                            </h1>
                                            <div className="linksWrapper">
                                                <a href="#">Gå tilbake forige side</a>
                                                <a href="#">Gjør et søk</a>
                                            </div>
                                        </div>
                                    </div>

                                </div> 

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Default>        
    )
}