import react from 'react';
import ImageObject from '../../../assets/images/object1.jpg'
import ImageObject2 from '../../../assets/images/object2.png'
import './style.scss'



export default function MyFavoriteListEdit(): JSX.Element{

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10">
                    <div className="my-favorite-list-edit">
                        <div className="my-favorite-list-edit__header">
                            <div className='info'>
                                <span className='title'>Velg objekter for sletting</span>                                
                            </div>
                            <div className='options'>
                                <button className='btn-link'>Slett valgte favoritter</button>
                                <button className='btn-link'>Velg alle</button>
                                <button className='btn-link'>Avslutt</button>
                            </div>
                        </div>
                        <div className="my-favorite-list-edit__list">

                            <div className="item">                             
                                <input type="checkbox" id="item1" name="item1"  />                                
                                <label htmlFor="item1">
                                    <div className="content">
                                        <div className="image">
                                            <img src={ImageObject} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <div className="name">
                                                    <h3>Navn på objekt</h3>
                                                </div>
                                                <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                                <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                                <div className="line showMobile">Neste bud: <strong>9.000 NOK</strong></div>
                                                <div className="line showMobile">Tid igjen: <strong>15d 6t 15min</strong></div>
                                                <div className="tags showMobile">
                                                    <span className="tag gray btn">Gi bud</span>
                                                    <span className="tag green">Du leder</span>
                                                </div>
                                            </div>
                                            <div className="status">
                                                <div className="tags">
                                                    <span className="tag gray btn">Gi bud</span>
                                                    <span className="tag green">Du leder</span>
                                                </div>
                                                <div className="line">Tid igjen: <strong>15d 6t 15min</strong></div>
                                                <div className="line">Nåværende bud: <strong>9.000 NOK</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                </label>

                            </div>

                            <div className="item">
                                <input type="checkbox" id="item2" name="item2"  />
                                <label htmlFor="item2">
                                    <div className="content">
                                        <div className="image">
                                            <img src={ImageObject2} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <div className="name">
                                                    <h3>Navn på objekt</h3>
                                                </div>
                                                <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                                <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                                <div className="line showMobile">Neste bud: <strong>9.000 NOK</strong></div>   
                                                <div className="line showMobile">Tid igjen: <strong>15d 6t 15min</strong></div>
                                                <div className="tags showMobile">
                                                    <span className="tag gray btn">Gi bud</span>
                                                    <span className="tag red">Du er overbydd</span>
                                                </div>
                                            </div>
                                            <div className="status">
                                                <div className="tags">
                                                    <span className="tag gray btn">Gi bud</span>
                                                    <span className="tag red">Du er overbydd</span> 
                                                </div>
                                                <div className="line">Tid igjen: <strong>15d 6t 15min</strong></div>
                                                <div className="line">Neste bud: <strong>9.000 NOK</strong></div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </label> 
                            </div>

                            <div className="item">
                                <input type="checkbox" id="item3" name="item3"  />
                                <label htmlFor="item3">
                                    <div className="content">
                                        <div className="image">
                                            <img src={ImageObject2} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <div className="name">
                                                    <h3>Kunstnernavn her</h3>
                                                </div>
                                                <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                                <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                                <div className="line showMobile">Klassisk auksjon: <strong>Kat.nr. 5</strong></div>
                                                <div className="line showMobile">Dato: <strong>07.11.2021 Kl 19:00</strong></div>
                                                <div className="tags showMobile"></div>
                                            </div>
                                            <div className="status">
                                                <div className="tags"></div>
                                                <div className="line">Klassisk auksjon: <strong>Kat.nr. 5</strong></div>
                                                <div className="line">Auksjons dato: <strong>07.11.2021 Kl 19:00</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                </label> 
                            </div>

                            <div className="item">
                                <input type="checkbox" id="item4" name="item4"  />
                                <label htmlFor="item4">
                                    <div className="content">
                                        <div className="image">
                                            <img src={ImageObject} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <div className="name">
                                                    <h3>Navn på objekt</h3>
                                                </div>
                                                <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                                <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                                <div className="line showMobile">Solgt for: <strong>9.000 NOK</strong></div>
                                                <div className="tags showMobile">
                                                    <span className="tag gray">Du vant</span>
                                                </div>
                                            </div>
                                            <div className="status">
                                                <div className="tags">
                                                    <span className="tag gray">Du vant</span>
                                                </div>
                                                <div className="line">Solgt for: <strong>9.000 NOK</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                </label> 
                            </div>

                            <div className="item">
                                <input type="checkbox" id="item5" name="item5"  />
                                <label htmlFor="item5">
                                    <div className="content">
                                        <div className="image">
                                            <img src={ImageObject2} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                                <div className="name">
                                                    <h3>Kunstnernavn her</h3>
                                                </div>
                                                <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                                <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                                <div className="line showMobile">Solgt for: <strong>9.000 NOK</strong></div>
                                                <div className="tags showMobile"></div>
                                            </div>
                                            <div className="status">
                                                <div className="tags"></div>
                                                <div className="line">Solgt for: <strong>9.000 NOK</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                </label> 
                            </div>

                            <div className="item">
                                <input type="checkbox" id="item6" name="item6"  />
                                <label htmlFor="item6">
                                    <div className="content">
                                        <div className="image">
                                            <img src={ImageObject2} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="data">
                                            <div className="name">
                                                    <h3>Navn på objekt</h3>
                                                </div>
                                                <div className="line hideMobile">Objektnummer: <strong>141127-2</strong> </div>
                                                <div className="line hideMobile">Vurdering: <strong>8.000 - 9.000 NOK</strong></div>
                                                <div className="line showMobile">Solgt for: <strong>9.000 NOK</strong></div>
                                                <div className="tags showMobile">
                                                    <span className="tag gray">Du ble overbydd</span>
                                                </div>
                                            </div>
                                            <div className="status">
                                                <div className="tags">
                                                    <span className="tag gray">Du ble overbydd</span>
                                                </div>
                                                <div className="line">Solgt for: <strong>9.000 NOK</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                </label> 
                            </div>             

                        </div>
                    </div>                        
                </div>
            </div>
        </div>   
        </>
    )
}