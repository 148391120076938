import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

// Prototype
import Prototype from './prototype'
import Buttons from './prototype/buttons'
import Grid from './prototype/grid'
import Typograph from './prototype/typograph'
import Icons from './prototype/icons'

// Modal Login
import LogInModal1 from './prototype/login-modal-1'
import LogInModal2 from './prototype/login-modal-2'
import LogInModal3 from './prototype/login-modal-3'
import LogInModal4 from './prototype/login-modal-4'
import LogInModal5 from './prototype/login-modal-5'
import LogInModal6 from './prototype/login-modal-6'
import LogInModal7 from './prototype/login-modal-7'
import LogInModal8 from './prototype/login-modal-8'
import LogInModal9 from './prototype/login-modal-9'
import LogInModal10 from './prototype/login-modal-10'
import LogInModal11 from './prototype/login-modal-11'
import LogInModal12 from './prototype/login-modal-12'
import LogInModal13 from './prototype/login-modal-13'

// Front Page
import Frontpage from './frontpage'

// Page list
import PageListKunst from './page-list/kunst'
import PageListTilslagsliste from './page-list/tilslagsliste'
import PageListVintageOgFashion from './page-list/vintage-og-fashion'
import PageListHoydepunkterKlassiskAuksjon from './page-list/hoydepunkter-klassisk-auksjon'

// Page list modal modal
import PageListModalCategory from './page-list/modal-filter/kategorier-og-sortering'
import PageListModalLesMer from './page-list/modal-les-mer/les-mer'
import VelgKategori from './page-list/modal-filter/velg-kategori'
import VelgLand from './page-list/modal-filter/velg-land'
import VelgOmrade from './page-list/modal-filter/velg-omrade'
import Sortering from './page-list/modal-filter/sortering'
import VelgKategori2 from './page-list/modal-filter/velg-kategori-2'
import VelgKategori3 from './page-list/modal-filter/velg-kategori-3'

// Object view
import ObjectView from './object-view/classic-auction'
import ObjectView2 from './object-view/online-auction'
import ObjectView3 from './object-view/wine-auction'
import ObjectViewModal1 from './object-view/modal/les-mer-beskrivelse'
import ObjectViewModal2 from './object-view/modal/les-mer-omkostninger'
import ObjectViewModal3 from './object-view/modal/se-hele-budhistorikken'
import ObjectViewModal4 from './object-view/modal/se-fraktalternativer'
import ObjectViewModal5 from './object-view/modal/bring-servicepakke'
import ObjectViewModal6 from './object-view/modal/registrer-forhandsbud'
import ObjectViewModal7 from './object-view/modal/registrer-telefonbud'
import ObjectViewModal8 from './object-view/modal/vikar-og-betingelser'
import ObjectViewModal9 from './object-view/modal/kontakt-ekspert'
import ObjectViewModal10 from './object-view/modal/objekt-zoom'

/// Search
import Search1 from './search/søkeresultat'
import Search2 from './search/nytt-søk'
import Search3 from './search/søkeresultat-lagre-søk'
import SearchModal from './search/search-modal'
import SuggestedSearchModal from './search/suggested-search-modal'

// Article
import Articles from './article/articles'
import HowToSell from './article/how-to-sell'
import ArticlePage from './article/article-page'
import ArticlePageV2 from './article/article-page-v2'
import ArticlePageV3 from './article/article-page-v3'
import ArticlePageImageBlock from './article/article-page-image-block'
import ArticlePageVideo from './article/article-page-video'
import ArticlePageBoxTextComponents from './article/article-page-text-components'
import ArticlePageGallery from './article/article-page-gallery'

// My Page
import MyPageMainPage from './my-page/main-page'
import MyPageMainPage2 from './my-page/main-page-2'
import MyPageMainPage3 from './my-page/main-page-3'
import MyPageMainPage4 from './my-page/main-page-4'
import MyPageProfile from './my-page/profile'
import EditPersonalia from './my-page/edit-personalia'
import EditPassord from './my-page/edit-passord'
import EditKontakt from './my-page/edit-kontakt'
import EditAdresse from './my-page/edit-adresse'
import EditLevering from './my-page/edit-levering'
import EditAnnet from './my-page/edit-annet'
import MyCreditCards from './my-page/my-credit-cards'
import EditCard from './my-page/edit-card'
import RemoveCard from './my-page/remove-card'
import MyPurchasesPayments from './my-page/purchases-payments/my-purchases-payments'
import MyPurchasesPaymentsObject from './my-page/purchases-payments/my-purchases-payments-object'
import MyPurchasesPaymentsObject2 from './my-page/purchases-payments/my-purchases-payments-object-2'
import MyPurchasesPaymentsObject3 from './my-page/purchases-payments/my-purchases-payments-object-3'
import ModalOmkostninger from './my-page/purchases-payments/modal/omkostninger'
import Fraktalternativer from './my-page/purchases-payments/modal/fraktalternativer'
import BringServicepakke from './my-page/purchases-payments/modal/bring-servicepakke'
import PaymentForMultipleObjects from './my-page/payment-for-multiple-objects/my-purchases-payments'
import SelectItemsForPayment from './my-page/payment-for-multiple-objects/select-items-for-payment'
import ObjectsForPayment from './my-page/payment-for-multiple-objects/objects-for-payment'
import MdalFullPayment from './my-page/payment-for-multiple-objects/modal/full-payment'
import PaymentCompleted from './my-page/payment-for-multiple-objects/modal/payment-completed'
import FullPaymentNewCard from './my-page/payment-for-multiple-objects/modal/full-payment-new-card'
import BekreftMedPankID from './my-page/payment-for-multiple-objects/bekreft-med-bankID'
import PartialPaymentWalley from './my-page/payment-for-multiple-objects/partial-payment-Walley'
import Betaling from './my-page/payment-for-one-object/betaling'
import SalesAndContracts1 from './my-page/my-sales-and-contracts/sales-and-contracts-1'
import SalesAndContracts2 from './my-page/my-sales-and-contracts/sales-and-contracts-2'
import SalesAndContractsContract from './my-page/my-sales-and-contracts/contract'
import SalesAndContractsObject1 from './my-page/my-sales-and-contracts/object-for-sale-1'
import SalesAndContractsObject2 from './my-page/my-sales-and-contracts/object-for-sale-2'
import SalesAndContractsObject3 from './my-page/my-sales-and-contracts/object-for-sale-3'
import ModalChangeMinimumPrice from './my-page/my-sales-and-contracts/modal-change-minimum-price'
import MyFavorites from './my-page/my-favorites'
import MyFavoritesEdit from './my-page/my-favorites-edit'
import MyFavoritesModalSortering from './my-page/my-favorites/modal-sortering'
import MyFavoritesModalSearch from './my-page/my-favorites/modal-search'
import CustomizeAlerts from './my-page/customize-alerts'
import MyBids from './my-page/my-bids'
import SavedSearches from './my-page/saved-searches'
import Newsletter from './my-page/newsletter'
import NewsletterSubscribe from './my-page/newsletter-subscribe'
import TermsPrivacy from './my-page/terms-privacy'
import Alerts from './my-page/alerts'
import OnlineAssessment from './my-page/online-assessment'

// Auctions pages
import AuctionsHomePage from './auctions/home'
import AuctionsClassicPrevious from './auctions/classic-previous'
import AuctionsClassicNext from './auctions/classic-next'

//About
import AboutEmployees from './about-employees'
import AboutEvents from './about-events'
import ContactUs from './contact-us'
import AboutSellBlomqvist from './about-sell-blomqvist'
import ShopAtBlomqvist from './about-shop-at-blomqvist'
import AboutBlomqvist from './about-blomqvist'

//Cookie Banner
import CookieBanner1 from './cookie-banner-1'
import CookieBanner2 from './cookie-banner-2'
import CookieBanner3 from './cookie-banner-3'

//Error 404 / 405
import Error4041 from './error-404-1'
import Error4042 from './error-404-2'
import Error4043 from './error-404-3'
import Error405 from './error-405'

//Hero
import Hero1 from './hero-1'
import Hero2 from './hero-2'
import Hero3 from './hero-3'
import Hero4 from './hero-4'
import Hero5 from './hero-5'
import Hero6 from './hero-6'
import Hero7 from './hero-7'
import Hero8 from './hero-8'
import Hero9 from './hero-9'

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route path="/prototype">
                    <Prototype/>
                </Route>
                <Route path="/buttons">
                    <Buttons/>
                </Route>
                <Route path="/grid">
                    <Grid/>
                </Route>
                <Route path="/typograph">
                    <Typograph/>
                </Route>
                <Route path="/icons">
                    <Icons/>
                </Route>
                <Route path="/frontpage">
                    <Frontpage/>
                </Route>
                <Route path="/page-list/kunst">
                    <PageListKunst/>
                </Route>
                <Route path="/page-list/tilslagsliste">
                    <PageListTilslagsliste/>
                </Route>
                <Route path="/page-list/vintage-og-fashion">
                    <PageListVintageOgFashion/>
                </Route>
                <Route path="/page-list/høydepunkter-klassisk-auksjon">
                    <PageListHoydepunkterKlassiskAuksjon/>
                </Route>
                <Route path="/login-modal-1">
                    <LogInModal1/>
                </Route>
                <Route path="/login-modal-2">
                    <LogInModal2/>
                </Route>
                <Route path="/login-modal-3">
                    <LogInModal3/>
                </Route>
                <Route path="/login-modal-4">
                    <LogInModal4/>
                </Route>
                <Route path="/login-modal-5">
                    <LogInModal5/>
                </Route>
                <Route path="/login-modal-6">
                    <LogInModal6/>
                </Route>
                <Route path="/login-modal-7">
                    <LogInModal7/>
                </Route>
                <Route path="/login-modal-8">
                    <LogInModal8/>
                </Route>
                <Route path="/login-modal-9">
                    <LogInModal9/>
                </Route>
                <Route path="/login-modal-10">
                    <LogInModal10/>
                </Route>
                <Route path="/login-modal-11">
                    <LogInModal11/>
                </Route>
                <Route path="/login-modal-12">
                    <LogInModal12/>
                </Route>
                <Route path="/login-modal-13">
                    <LogInModal13/>
                </Route>
                <Route path="/page-list/modal-filter/kategorier-og-sortering">
                    <PageListModalCategory/>
                </Route>
                <Route path="/page-list/modal-les-mer">
                    <PageListModalLesMer/>
                </Route>
                <Route path="/page-list/modal-filter/velg-kategori">
                    <VelgKategori/>
                </Route>
                <Route path="/page-list/modal-filter/velg-land">
                    <VelgLand/>
                </Route>   
                <Route path="/page-list/modal-filter/velg-omrade">
                    <VelgOmrade/>
                </Route> 
                <Route path="/page-list/modal-filter/sortering">
                    <Sortering/>
                </Route>   
                <Route path="/page-list/modal-filter/velg-kategori-2">
                    <VelgKategori2/>
                </Route> 
                <Route path="/page-list/modal-filter/velg-kategori-3">
                    <VelgKategori3/>
                </Route>
                <Route path="/object-view/classic-auction">
                    <ObjectView/>
                </Route>  
                <Route path="/object-view/online-auction">
                    <ObjectView2/>
                </Route>      
                <Route path="/object-view/wine-auction">
                    <ObjectView3/>
                </Route>  
                <Route path="/object-view/modal/les-mer-beskrivelse">
                    <ObjectViewModal1/>
                </Route>   
                <Route path="/object-view/modal/les-mer-omkostninger">
                    <ObjectViewModal2/>
                </Route>      
                <Route path="/object-view/modal/se-hele-budhistorikken">
                    <ObjectViewModal3/>
                </Route> 
                <Route path="/object-view/modal/se-fraktalternativer">
                    <ObjectViewModal4/>
                </Route> 
                <Route path="/object-view/modal/bring-servicepakke">
                    <ObjectViewModal5/>
                </Route> 
                <Route path="/object-view/modal/registrer-forhandsbud">
                    <ObjectViewModal6/>
                </Route> 
                <Route path="/object-view/modal/registrer-telefonbud">
                    <ObjectViewModal7/>
                </Route> 
                <Route path="/object-view/modal/vikar-og-betingelser">
                    <ObjectViewModal8/>
                </Route> 
                <Route path="/object-view/modal/kontakt-ekspert">
                    <ObjectViewModal9/>
                </Route> 
                <Route path="/object-view/modal/objekt-zoom">
                    <ObjectViewModal10/>
                </Route>
                <Route path="/search/søkeresultat">
                    <Search1/>
                </Route>     
                <Route path="/search/nytt-søk">
                    <Search2/>
                </Route>           
                <Route path="/search/søkeresultat-lagre-søk">
                    <Search3/>
                </Route>     
                <Route path="/search/search-modal">
                    <SearchModal/>
                </Route>
                <Route path="/search/suggested-search-modal">
                    <SuggestedSearchModal/>
                </Route>
                <Route path="/article/articles">
                    <Articles/>
                </Route>  
                <Route path="/article/how-to-sell">
                    <HowToSell/>
                </Route>   
                <Route path="/article/article-page">
                    <ArticlePage/>
                </Route>   
                <Route path="/article/article-page-v2">
                    <ArticlePageV2/>
                </Route>  
                <Route path="/article/article-page-v3">
                    <ArticlePageV3/>
                </Route>     
                <Route path="/article/article-page-image-block">
                    <ArticlePageImageBlock/>
                </Route> 
                <Route path="/article/article-page-video">
                    <ArticlePageVideo/>
                </Route>  
                <Route path="/article/article-page-text-components">
                    <ArticlePageBoxTextComponents/>
                </Route>
                <Route path="/article/article-page-gallery">
                    <ArticlePageGallery/>
                </Route>
                <Route path="/my-page/main-page">
                    <MyPageMainPage/>
                </Route> 
                <Route path="/my-page/main-page-2">
                    <MyPageMainPage2/>
                </Route>   
                <Route path="/my-page/main-page-3">
                    <MyPageMainPage3/>
                </Route>  
                <Route path="/my-page/main-page-4">
                    <MyPageMainPage4/>
                </Route>
                <Route path="/my-page/profile">
                    <MyPageProfile/>
                </Route>
                <Route path="/my-page/edit-personalia">
                    <EditPersonalia/>
                </Route>
                <Route path="/my-page/edit-passord">
                    <EditPassord/>
                </Route>  
                <Route path="/my-page/edit-kontakt">
                    <EditKontakt/>
                </Route>   
                <Route path="/my-page/edit-adresse">
                    <EditAdresse/>
                </Route>  
                <Route path="/my-page/edit-levering">
                    <EditLevering/>
                </Route> 
                <Route path="/my-page/edit-annet">
                    <EditAnnet/>
                </Route>   
                <Route path="/my-page/my-credit-cards">
                    <MyCreditCards/>
                </Route>                                                                  
                <Route path="/my-page/edit-card">
                    <EditCard/>
                </Route>     
                <Route path="/my-page/remove-card">
                    <RemoveCard/>
                </Route>   
                <Route path="/auctions/home">
                    <AuctionsHomePage/>
                </Route>
                <Route path="/auctions/classic-previous">
                    <AuctionsClassicPrevious/>
                </Route>   
                <Route path="/auctions/classic-next">
                    <AuctionsClassicNext/>
                </Route>                
                <Route path="/my-page/my-purchases-payments">
                    <MyPurchasesPayments/>
                </Route>
                <Route path="/my-page/my-purchases-payments-object">
                    <MyPurchasesPaymentsObject/>
                </Route> 
                <Route path="/my-page/my-purchases-payments-object-2">
                    <MyPurchasesPaymentsObject2/>
                </Route>     
                <Route path="/my-page/my-purchases-payments-object-3">
                    <MyPurchasesPaymentsObject3/>
                </Route>       
                <Route path="/my-page/modal-omkostninger">
                    <ModalOmkostninger/>
                </Route>  
                <Route path="/my-page/modal-fraktalternativer">
                    <Fraktalternativer/>
                </Route>     
                <Route path="/my-page/modal-bring-servicepakke">
                    <BringServicepakke/>
                </Route>   
                <Route path="/my-page/payment-for-multiple-objects">
                    <PaymentForMultipleObjects/>
                </Route>  
                <Route path="/my-page/select-items-for-payment">
                    <SelectItemsForPayment />
                </Route>       
                <Route path="/my-page/objects-for-payment">
                    <ObjectsForPayment/>
                </Route>     
                <Route path="/my-page/modal-full-payment">
                    <MdalFullPayment/>
                </Route> 
                <Route path="/my-page/payment-completed">
                    <PaymentCompleted />
                </Route>
                <Route path="/my-page/full-payment-new-card">
                    <FullPaymentNewCard />
                </Route>
                <Route path="/my-page/bekreft-med-bankID">
                    <BekreftMedPankID/>
                </Route>                 
                <Route path="/my-page/partial-payment-Walley">
                    <PartialPaymentWalley/>
                </Route>
                <Route path="/my-page/betaling">
                    <Betaling/>
                </Route>
                <Route path="/my-page/sales-and-contracts-1">
                    <SalesAndContracts1/>
                </Route>
                <Route path="/my-page/sales-and-contracts-2">
                    <SalesAndContracts2/>
                </Route>
                <Route path="/my-page/sales-and-contracts-contract">
                    <SalesAndContractsContract/>
                </Route>
                <Route path="/my-page/sales-and-contracts-object-1">
                    <SalesAndContractsObject1/>
                </Route>
                <Route path="/my-page/sales-and-contracts-object-2">
                    <SalesAndContractsObject2/>
                </Route>
                <Route path="/my-page/sales-and-contracts-object-3">
                    <SalesAndContractsObject3/>
                </Route>
                <Route path="/my-page/modal-change-minimum-price">
                    <ModalChangeMinimumPrice/>
                </Route>                
                <Route path="/my-page/my-favorites">
                    <MyFavorites/>
                </Route>
                <Route path="/my-page/my-favorites-edit">
                    <MyFavoritesEdit/>
                </Route>                
                <Route path="/my-page/my-favorites-modal-sortering">
                    <MyFavoritesModalSortering/>
                </Route> 
                <Route path="/my-page/my-favorites-modal-search">
                    <MyFavoritesModalSearch/>
                </Route>      
                <Route path="/my-page/customize-alerts">
                    <CustomizeAlerts/>
                </Route> 
                <Route path="/my-page/my-bids">
                    <MyBids/>
                </Route> 
                <Route path="/my-page/saved-searches">
                    <SavedSearches />
                </Route> 
                <Route path="/my-page/newsletter">
                    <Newsletter />
                </Route> 
                <Route path="/my-page/newsletter-subscribe">
                    <NewsletterSubscribe />
                </Route> 
                <Route path="/about-employees">
                    <AboutEmployees />
                </Route>   
                <Route path="/about-events">
                    <AboutEvents />
                </Route>  
                <Route path="/contact-us">
                    <ContactUs />
                </Route>                              
                <Route path="/about-sell-blomqvist">
                    <AboutSellBlomqvist />
                </Route> 
                <Route path="/about-shop-at-blomqvist">
                    <ShopAtBlomqvist />
                </Route> 
                <Route path="/about-blomqvist">
                    <AboutBlomqvist/>
                </Route> 
                <Route path="/my-page/terms-privacy">
                    <TermsPrivacy/>
                </Route> 
                <Route path="/my-page/alerts">
                    <Alerts/>
                </Route> 
                <Route path="/my-page/online-assessment">
                    <OnlineAssessment/>
                </Route> 
                <Route path="/cookie-banner-1">
                    <CookieBanner1/>
                </Route> 
                <Route path="/cookie-banner-2">
                    <CookieBanner2/>
                </Route> 
                <Route path="/cookie-banner-3">
                    <CookieBanner3/>
                </Route> 
                <Route path="/error-404-1">
                    <Error4041/>
                </Route>
                <Route path="/error-404-2">
                    <Error4042/>
                </Route>  
                <Route path="/error-404-3">
                    <Error4043/>
                </Route> 
                <Route path="/error-405">
                    <Error405/>
                </Route> 
                <Route path="/hero-1">
                    <Hero1/>
                </Route> 
                <Route path="/hero-2">
                    <Hero2/>
                </Route> 
                <Route path="/hero-3">
                    <Hero3/>
                </Route> 
                <Route path="/hero-4">
                    <Hero4/>
                </Route> 
                <Route path="/hero-5">
                    <Hero5/>
                </Route> 
                <Route path="/hero-6">
                    <Hero6/>
                </Route> 
                <Route path="/hero-7">
                    <Hero7/>
                </Route>
                <Route path="/hero-8">
                    <Hero8/>
                </Route>    
                <Route path="/hero-9">
                    <Hero9/>
                </Route>   

            </Switch>
        </Router>
    );
}