import React from 'react'
import { Default } from '../../../components/templates'
import SelectSearch from 'react-select-search'
import './style.scss'

export default function OnlineAssessment(): JSX.Element{

            
    return(        
        <Default headerColor="light">
            <></>               
            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                            <h1>Online vurdering</h1>                         
                        </div>
                    </div>
                </div>    
            </div>
            
            <div className="onlineAssessment">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">

                            <div className="onlineAssessment__form">

                                <div className="profile">
                                    <div className="profile__header">
                                        <h3 className="title">Personalia</h3>
                                        <button><span className='icon-Pencil'></span></button>                                        
                                    </div>
                                    <div className="profile__content">
                                        <div className='name'>Elisabeth Henriksen</div>
                                        <div className='line'>E-post: henrelis@gmail.com</div>
                                        <div className='line'>Mobil: 92081307</div>                                   
                                    </div>
                                </div>                                                        

                                <div className="section">

                                    <h3 className='title'>Objekt</h3>

                                    <div className='form-group'>
                                        <label>Kategori</label>
                                        <div className='select'>
                                            <SelectSearch
                                                options={[
                                                    { value: 'Kunst', name: 'Kunst'},
                                                    { value: 'Kategori1', name: 'Kategori'},
                                                    { value: 'Kategori2', name: 'Kategori2'},
                                                    { value: 'Kategori3', name: 'Kategori3'},
                                                    { value: 'Kategori4', name: 'Kategori4'}
                                                ]}
                                                placeholder="Kategori"
                                                value={'Kunst'}
                                            />
                                        </div> 
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="name">Navn på kunstner/formgiver</label>
                                        <input type="text" defaultValue="" id="name"/>
                                        <span className='errorMessage'>* sjekk at dette feltet er rett fylt ut</span> 
                                        <div className="hint-text">Dersom man ikke vet, skrive gjerne "ukjent." </div>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="title">Tittel/beskrivelse*</label>
                                        <input type="text" defaultValue="" id="title"/>
                                        <span className='errorMessage'>* sjekk at dette feltet er rett fylt ut</span> 
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="measurement">Mål i centimeter*</label>
                                        <input type="text" defaultValue="" id="measurement"/>
                                        <span className='errorMessage'>* sjekk at dette feltet er rett fylt ut</span> 
                                        <div className="hint-text">Legg gjerne inn høyde og bredde, og lengde eller diameter der det er aktuelt. Kunst måles uten ramme.</div>
                                    </div>
                                    
                                    <div className='form-group'>
                                        <label htmlFor="age">Datering/alder*</label>
                                        <input type="text" defaultValue="" id="age"/>
                                        <span className='errorMessage'>* sjekk at dette feltet er rett fylt ut</span> 
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="material">Teknikk/materiale*</label>
                                        <input type="text" defaultValue="" id="material"/>
                                        <span className='errorMessage'>* sjekk at dette feltet er rett fylt ut</span> 
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="damage">Skader</label>
                                        <textarea name="" id="damage" placeholder='Vennligst beskriv skader på objekter dersom du kan se noen'></textarea>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="others">Annet</label>
                                        <textarea name="" id="others"  placeholder='Lorem ipsum dolor sit amet, consectetur adipiscing eli '></textarea>
                                    </div>

                                </div>


                                <div className="section">
                                    <h3 className='title'>Last opp bilder</h3>
                                    <div className='uploadArea'>
                                        <p>Last opp bilder av objektet</p>
                                        <button className='btn btn-gray'>
                                            <span className='icon-Upload'></span>
                                            <span className='desktop'>Velg filer </span>
                                            <span className='mobile'>Last opp bilder</span></button>
                                    </div>
                                </div>

                                <div className="section">
                                    <h3 className='title'>Samtykke</h3>
                                    <div className="terms">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit purus in id interdum vestibulum nunc ipsum vitae lectus. Mi, orci, blandit euismod in vitae, lectus congue nullam. Vestibulum fermentum aliquet posuere eget justo, fermentum. Hac nam non sollicitudin neque sem.</p>
                                        <div className='acceptTerms'>
                                            <input type="checkbox" id="terms" name="terms" />
                                            <label htmlFor="terms">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</label>
                                        </div>                                        
                                    </div>
                                </div>


                                <div className="submitWrapper">
                                    <button type='submit' className='btn btn-gray lg'>Få vurdering</button>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </Default>        
    )
}

function createRef() {
    throw new Error('Function not implemented.');
}
