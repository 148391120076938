import React from 'react'
import ImagepopularAuctions from '../../../assets/images/popular-auctions.jpg'
import './style.scss'

export default function PopularAuctions(): JSX.Element{

    return(
        <>
        <div className='popularAuctions'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='popularAuctions__image'>
                            <figure className='img aspectRatio--624x596'>
                                <img src={ImagepopularAuctions} alt="" />
                            </figure>
                        </div>
                    </div>
                    <div className='offset-lg-1 col-lg-5'>
                        <div className='popularAuctions__list'>
                            <h2>Populære auksjoner</h2>
                            <ul>
                                <li><a href="#">Kunst <span className="icon-CaretRight"></span></a></li>
                                <li><a href="#">Vin og brennevin <span className="icon-CaretRight"></span></a></li>
                                <li><a href="#">Antikviteter <span className="icon-CaretRight"></span></a></li>
                                <li><a href="#">Vintage og fashion <span className="icon-CaretRight"></span></a></li>
                                <li><a href="#">Klokker og ur <span className="icon-CaretRight"></span></a></li>
                                <li><a href="#">Bondeantikviteter <span className="icon-CaretRight"></span></a></li>
                            </ul>
                            <a className="btn btn-secondary lg" href="#">Se alle auksjoner</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}