import React from 'react'
import { EmptyPage } from '../../components/templates'
import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>
                
                    <div className='modalLogin__wrapper--content register-inputs--content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <button className='btnBackModal'><span className='icon-ArrowLeft'></span></button>

                        <div className='content'>
                            <h2>Registrer deg som kunde</h2>      

                            <div className='registerWrapper'>
                                <div className='input'>
                                    <label>Fornavn*</label>
                                    <input type="text"/>
                                </div>
                                <div className='input'>
                                    <label>Etternavn*</label>
                                    <input type="name"/>
                                </div>
                                <div className='input'>
                                    <label>E-post*</label>
                                    <input type="name"/>
                                </div>
                                <div className='input'>
                                    <label>Passord*</label>
                                    <input type="password"/>
                                </div>
                                <div className='input'>
                                    <label>Gjenta passord*</label>
                                    <input type="password"/>
                                </div>
                                <div className='termsWrapper'>
                                    <input type="checkbox" id="terms" name="terms" />
                                    <label htmlFor="terms" >Ved å opprette konto godtar du Blomqvist <br /> sine <a href="#">vilkår og betingelser</a>. </label>
                                </div>
                                <div className='btnRegisterWrapper'>
                                    <button  className='btn btn-primary lg'>Opprett konto</button>
                                </div>
                            </div>
                            <div className='linkLoginWrapper'>
                                <p>Har du allerede en konto?  <a href="$">Logg inn</a></p>
                            </div>
                        </div>   
                    </div>                
                
                </div>              
            </div>
  
        </EmptyPage> 
    )
}