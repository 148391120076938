import React from 'react'
import ImageObject1 from '../../../assets/images/product-list1.png'
import ImageObject2 from '../../../assets/images/product-list2.png'
import ImageObject3 from '../../../assets/images/product-list3.png'
import './style.scss'

export default function PageListFilter(): JSX.Element{

    return(
        <>
        <div className='pageListFilter'>

            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='pageListFilter__filterSearch'>
                            <div className='wrapperFilterSearch'>
                                <button className='filter'><span className='icon-Filter'></span><p>Kategorier og sortering</p></button>
                                <div className='inputSearch'>
                                    <button className='buttonDesktop'><span className='icon-MagnifyingGlass'></span></button>
                                    <button className='buttonMobile'><span className='icon-MagnifyingGlass'></span></button>
                                    <input type="text" placeholder='Søk i listen'/>
                                    <button className='save'>Lagre søk</button>
                                </div>
                            </div>
                            <div className='total'>
                                <span>128 objekter</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='pageListFilter__list'>

                            <div className='item'>
                                <div className='image'>
                                    <div className='content'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">                                       
                                            <img src={ImageObject1} alt="" />                                       
                                        </a>
                                    </div>
                                </div>
                                <div className='info'>
                                    <a href="#" className='name'>Lysestake</a>
                                    <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                    <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                </div>
                            </div>

                            <div className='item'>
                                <div className='image'>
                                    <div className='content'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">                                       
                                            <img src={ImageObject2} alt="" />                                       
                                        </a>                                        
                                    </div>
                                </div>
                                <div className='info'>
                                    <a href="#" className='name'>Lysestake</a>
                                    <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                    <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                </div>
                            </div>

                            <div className='item'>
                                <div className='image'>
                                    <div className='content'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">                                       
                                            <img src={ImageObject3} alt="" />                                       
                                        </a>
                                    </div>
                                </div>
                                <div className='info'>
                                    <a href="#" className='name'>Lysestake</a>
                                    <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                    <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                </div>
                            </div>

                            <div className='item'>
                                <div className='image'>
                                    <div className='content'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">                                       
                                            <img src={ImageObject3} alt="" />                                       
                                        </a>
                                    </div>
                                </div>
                                <div className='info'>
                                    <a href="#" className='name'>Lysestake</a>
                                    <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                    <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                </div>
                            </div>

                            <div className='item'>
                                <div className='image'>
                                    <div className='content'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">                                       
                                            <img src={ImageObject1} alt="" />                                       
                                        </a>
                                    </div>
                                </div>
                                <div className='info'>
                                    <a href="#" className='name'>Lysestake</a>
                                    <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                    <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='pageListFilter__pagination'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='wrapper'>
                                <div className='prev'><button>Forrige</button></div>
                                <div className='page'><button>2</button>/<button>3</button></div>
                                <div className='next'><button>Neste</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>        
        </>
    )
}