import React,{useState} from 'react'
import ImageObject from '../../../assets/images/object1.jpg'
import ImageObject2 from '../../../assets/images/object2.png'
import ImageObject3 from '../../../assets/images/object3.png'
import ImageObject4 from '../../../assets/images/object4.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import './style.scss'

export default function MyFavorite(): JSX.Element{

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 890,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
          }
          
        ]
    };


    return(
        <>
        <div className='myFavorite'>
            
            <div className='container'>
                <div className='row'>

                    <div className='myFavorite__content'>

                        <div data-tab="1" className="myFavorite__content--wrapper">
                            
                            <div className='title'>
                                <h2 className='desktop'>Mine favoritter</h2>
                                <a className='mobile' href="#"><h2>Mine favoritter <span className='total'>(5)</span><span className="icon-ArrowRight"></span></h2></a>
                            </div>

                            <Slider {...settings}>
                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">
                                            <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject} alt="" />
                                            </figure>
                                        </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Erik Werenskiold</a>
                                        <div className='line'>Speiling</div>
                                        <div className='line'>200.000 - 250.000 NOK</div>
                                        <div className='line'>Klassisk auksjon: Kat.nr. 5</div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">
                                            <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject} alt="" />
                                            </figure>
                                        </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Lysestake</a>
                                        <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                        <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                       <a href="#">
                                        <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject3} alt="" />
                                            </figure>
                                       </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Chanel</a>
                                        <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                        <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">
                                            <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject4} alt="" />
                                            </figure>
                                        </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Hérmes</a>
                                        <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                        <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <span className='bookmark icon-Heart'></span>
                                        <a href="#">
                                            <figure className='img aspectRatio--200x200'>
                                                <img src={ImageObject2} alt="" />
                                            </figure>
                                        </a>
                                    </div>
                                    <div className='info'>
                                        <a href="#" className='name'>Lysestake</a>
                                        <div className='line'>20.000 - 30.000 NOK | 5d 6t 15min</div>
                                        <div className='line'>Neste bud: <strong>32 000 NOK</strong></div>
                                    </div>
                                </div>
                            </Slider>

                            <div className="mobileList">

                                <div className='item'> 
                                    <a href="#">
                                        <div className="content">
                                            <div className='image'>                                                                               
                                                <img src={ImageObject} alt="" />                                            
                                            </div>
                                            <div className='info'>
                                                <div>
                                                    <div className='name'>Erik Werenskiold <span className="icon-CaretRight"></span></div>
                                                    <div className='line'>8.000 - 9.000 NOK</div>
                                                    <div className='line'>Neste bud: <strong>75.000 NOK</strong></div>
                                                </div>
                                            </div>                                        
                                        </div>                                        
                                    </a>
                                </div>

                                <div className='item'> 
                                   <a href="#">
                                        <div className="content">
                                            <div className='image'>                                       
                                                <img src={ImageObject4} alt="" />
                                            </div>
                                            <div className='info'>
                                                <div>
                                                    <div className='name'>Erik Werenskiold <span className="icon-CaretRight"></span></div>
                                                    <div className='line'>8.000 - 9.000 NOK</div>
                                                    <div className='line'>Neste bud: <strong>75.000 NOK</strong></div>
                                                </div>
                                            </div>                                        
                                        </div>                                       
                                   </a>
                                </div>

                            </div>                              

                            <div className='seeAll'>
                                <a href="#">Se alle</a>
                            </div>

                        </div>

                    </div>


                </div>
            </div>
        
        </div>        
        </>
    )
}