import React from 'react'
import './style.scss'

export default function breadCrumbs(): JSX.Element{
    return(
        <div className='breadCrumbs'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <a href="#" className='breadCrumbs__item'>Breadcrumb</a>
                        <span className='icon-CaretRight'></span>
                        <a href="#" className='breadCrumbs__item last'>Breadcrumb</a>
                    </div>
                </div>
            </div>
        </div>
    )
}