import React from 'react'
import { Default } from '../../components/templates'
import ImageHero from '../../assets/images/hero-full.png'

export default function FrontPage(): JSX.Element{
    return(         
        <Default frontpage headerColor="light">
        <div className='hero hero-50-50 full-background'>
            <div className='imageMobile'>
                <figure className='img aspectRatio--375x297'>
                    <img src={ImageHero} alt="" />
                </figure>
            </div>            
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-sm-6'>
                        <div className='hero__content'>
                            <div className='hero__content--content'>
                                <h1>Vinterens høydepunkter</h1>
                                <p>Med et fantastisk utvalgt kunstverk fra norske og internasjonale mestre avrunder Blomqvist året 2021 med den klassiske auksjonen Høydepunkter.</p>
                                <a className='btn btn-secondary md' href="#" >Les mer</a>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
            <div className='image-background' style={{ backgroundImage: "url("+ImageHero+")" }}></div>
        </div>
        <></>
        </Default>        
    )
}