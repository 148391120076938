import React from 'react'
import { Default } from '../../../components/templates'
import Image from '../../../assets/images/classicImage.png'
import { BreadCrumbs } from '../../../components/primitives'
import { 
    ImageTextBlockAuction,
    ImageTextBlock
} from '../../../components/modules'
import '../classic-previous/style.scss'

export default function PageList(): JSX.Element{
    return(        
        <Default headerColor="background">
            <></>               
            <div className='page-list-heading background'>
                <BreadCrumbs/> 
                <div className='page-list-heading__infoCategory'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='wrapper'>
                                    <div className='info'>
                                        <h2>Klassiske auksjoner </h2>
                                        <figure className='img aspectRatio--655x441 imageMobile'>                                    
                                            <img src={Image} alt="" />
                                        </figure>
                                        <div className='description'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium. Amet nunc amet libero pretium.</p>
                                        </div>
                                    </div>
                                    <div className='image'>
                                        <figure className='img aspectRatio--655x441'>                                    
                                            <img src={Image} alt="" />
                                        </figure>
                                    </div>                                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  


            <div className='actionsClassicList'>

                <div className="tabWrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tabs">
                                    <button className='active'>Kommende auksjoner</button>
                                    <button>Tidligere auksjoner </button>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                </div>
          
                <div className="actionsClassicList__list">
                    <ImageTextBlockAuction light/>
                    <ImageTextBlockAuction left light/>  
                    <ImageTextBlockAuction light/>
                    <ImageTextBlock left/> 
                    <ImageTextBlockAuction light/>
                </div>

            </div>     


        </Default>        
    )
}