import React from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'


import '../style.scss'

export default function modalFilter(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalFilter active'>
                <div className='modalFilter__wrapper'>
                    <div className='modalFilter__wrapper--content'>

                      <div className='btnCloseModalWrapper'>
                          <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                      </div>
                        
                      <div className='content'>
                          <div className='wrapper'>
                            <h2>Velg område</h2>

                            <div className='listHeader'>
                                <div className='buttons'>
                                    <span className="icon-ArrowLeft"></span>
                                    <button> Land </button> / <button>  Frankrike </button>
                                </div>
                                <button className='reset'>Nullstill</button>
                            </div>

                            <div className='checkBoxWrapper'>
                              <div className='item'>
                                <input type="checkbox" id="item1" name="item1"  />
                                <label htmlFor="item1">Alle områder</label>
                              </div>
                              <div className='item'>
                              <input type="checkbox" id="item2" name="item2"/>
                                <label htmlFor="item2">Barsac</label>
                              </div>
                              <div className='item'>
                              <input type="checkbox" id="item3" name="item3"/>
                                <label htmlFor="item3">Bordeaux</label>
                              </div>
                              <div className='item'>
                              <input type="checkbox" id="item4" name="item4"/>
                                <label htmlFor="item4">Canon Fronsac</label>
                              </div>
                              <div className='item'>
                                <input type="checkbox" id="item5" name="item5"  />
                                <label htmlFor="item5">Alle områder</label>
                              </div>
                              <div className='item'>
                              <input type="checkbox" id="item6" name="item6"/>
                                <label htmlFor="item6">Barsac</label>
                              </div>
                              <div className='item'>
                              <input type="checkbox" id="item7" name="item7"/>
                                <label htmlFor="item7">Bordeaux</label>
                              </div>
                              <div className='item'>
                              <input type="checkbox" id="item8" name="item8"/>
                                <label htmlFor="item8">Canon Fronsac</label>
                              </div>
                            </div>                              
                          </div>                       
                      </div>

                      <div className='btnWrapper'>
                          <button className='btn btn-gray sm'>Avslutt</button>
                          <button className='btn btn-primary sm'>Vis 340 treff</button>
                      </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}