import React from 'react'
import { Default } from '../../../../components/templates'
import Icon from '../../../../assets/images/okIcon.svg'
import './style.scss'

export default function ModalForMultipleObjects(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalForMultipleObjects active'> */}
        <div className='modalForMultipleObjects active'>
            <div className='modalForMultipleObjects__wrapper'>
                <div className='modalForMultipleObjects__wrapper--content heightInitial'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content mbMobile0' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Betalingen <br /> er fullført</h2>
                            </div>

                            <div className="iconWrapper">
                                <img src={Icon} alt="" />
                            </div>
                            
                            <div className='buttonWrapper'>
                                <button className='btn btn-gray sm'>Lukk<span> vindu</span></button>
                            </div>
                        </div>
                    </div>                    

                </div>
            </div>              
        </div>     
        <></>
        
        </Default>        
    )
}