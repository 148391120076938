import React from 'react'
import { Default } from '../../components/templates'
import {SearchModal } from '../../components/modules'
import './style.scss'

export default function PageList(): JSX.Element{
    return(        
        <Default headerColor="light">
            <SearchModal/>
            <></>
        </Default>
    )
}