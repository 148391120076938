import React from 'react'
import { Default } from '../../../../components/templates'
import Image1 from '../../../../assets/images/excellentList1.png'
import {
    ObjectsInContract,
    Warning
} from '../../../../components/modules'
import './style.scss'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span>Min side</a>
                            <h1>Kontrakt: 2418685</h1>                         
                        </div>
                    </div>
                </div>    
            </div>

            {/* <Warning/> */}
            <div className='warning yellow'>
                <div className='container'>
                    <div className='row'>
                        <div className='offset-lg-1 col-lg-10'>
                            <div className='wrapper'>
                                <span className="icon-Info"></span>
                                <div className="content">
                                    <p>Denne kontakten trenger signering. <a href="#">Det gjør du her</a>.</p>                                      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>     

            <div className="contractObject">
                <div className="container">
                    <div className="row">

                        <div className="offset-lg-1 col-lg-4">
                            <div className="contractObject__info">

                                <div className="first">
                                    <div className="line">
                                        <div className="label">Registrert</div>
                                        <div className="value">15.10.21 - 14:35</div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Objekter</div>
                                        <div className="value">Antall 4 / 0 Solgt</div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Referanse</div>
                                        <div className="value">Navn Etternavn</div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Kontrakt type</div>
                                        <div className="value">Klassisk auksjon</div>
                                    </div>   
                                    
                                    <div className="line full-mobile">
                                        <div className="label">Kontrakt</div>
                                        <div className="value"><button className="btn btn-secondary sm">Signer kontrakt</button></div>
                                    </div>  

                                    <div className="line accountNumber">
                                        <div className="label">
                                            <p className='desk'>Kontonummer for utbetalig</p>
                                            <p className='mob'>Konto.nr. for utbetalig</p>
                                        </div>
                                        <div className="value"><a href="#">3418 22 08066</a></div>
                                    </div>  

                                    <div className="line">
                                        <a href="#" className='pdfDownload'>Last ned som PDF </a>
                                    </div>    

                                </div>                  

                           </div>
                        </div>

                        <div className="offset-lg-1 col-lg-5">
                           <div className="contractObject__image multiple">
                               
                                <div className="imageWrapper img aspectRatio--1x1">
                                    <div className="wrapper">
                                        <div className="image">
                                            <figure className="img aspectRatio--1x1">
                                                <img src={Image1} alt="" />
                                            </figure>
                                        </div>
                                        <div className="image">
                                            <figure className="img aspectRatio--1x1">
                                                <img src={Image1} alt="" />
                                            </figure>
                                        </div>
                                        <div className="image">
                                            <figure className="img aspectRatio--1x1">
                                                <img src={Image1} alt="" />
                                            </figure>
                                        </div>                                    
                                    </div>                              
                                </div>                                
                               
                            </div>
                           <div className="contractObject__options">
                                <button className='btn-link'>Gå til produktside</button>
                                <button className='btn-link'>Se budlisten</button>
                           </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <ObjectsInContract/>

            <></>

        </Default>        
    )
}