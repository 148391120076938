import React from 'react'
import ImageSell1 from '../../../assets/images/sell1.png'
import ImageSell2 from '../../../assets/images/sell2.png' 
import ImageSell3 from '../../../assets/images/sell3.png' 
import ImageSell4 from '../../../assets/images/sell4.png' 
import ImageSell5 from '../../../assets/images/sell5.png' 
import Slider from "react-slick"
import "../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../node_modules/slick-carousel/slick/slick-theme.css"
import './style.scss'

export default function WeSellIt(): JSX.Element{

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        centerMode: true,
        variableWidth: true,
        speed: 500,
        slidesToShow: 1,
        initialSlide: 1
    };

    return(
        <>
        <div className='weSellIt'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>

                        <h2>Dette har vi solgt</h2>

                        <div className='weSellIt__gallery'>
        
                            <Slider {...settings}>
                                <div className='item'>
                                    <a href="#">
                                        <div className='image'>                                        
                                            <img src={ImageSell1} alt="" />                                        
                                            <div className='info'>
                                                Ludvig Karsten | Tilslag: 2.900.000 NOK
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className='item'> 
                                    <a href="#">
                                        <div className='image'>                                        
                                            <img src={ImageSell2} alt="" />                                        
                                            <div className='info'>
                                                Ludvig Karsten | Tilslag: 2.900.000 NOK
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className='item'>
                                    <a href="#">
                                        <div className='image'>                                        
                                            <img src={ImageSell3} alt="" />                                        
                                            <div className='info'>
                                                Hans Fredrik Gude | Tilslag: 400.000 NOK 
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className='item'>
                                    <a href="#">
                                        <div className='image'>                                        
                                            <img src={ImageSell4} alt="" />                                        
                                            <div className='info'>
                                                Theodor Kittelsen | Tilslag: 14.000.000 NOK
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className='item'>
                                    <a href="#">
                                        <div className='image'>                                        
                                            <img src={ImageSell5} alt="" />                                        
                                            <div className='info'>
                                                Ludvig Karsten | Tilslag: 2.900.000 NOK
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Slider>

                        </div>

                        <div className='weSellIt__divider'></div>

                        <a className="btn btn-light lg" href="#">Se hele tilslagslisten</a> 
                        
                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}