import React from 'react'
import { Default } from '../../../../components/templates'
import './style.scss'

export default function ModalPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalPurchasesPayments active'> */}
        <div className='modalPurchasesPayments active'>
            <div className='modalPurchasesPayments__wrapper'>
                <div className='modalPurchasesPayments__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Bring servicepakke </h2>
                                <h3>Objekt.nr. xxxxxxx kan leveres på følgende måter:</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin venenatis nunc eu ultrices vestibulum.</p>
                            </div>

                            <div className="AdressBox">
                                <div className='AdressBox__header'>
                                    <span className='title'>Leveringsadresse</span>
                                    <button><span className='icon-Pencil'></span></button>
                                </div>
                                <div className='AdressBox__line'>Elisabeth Henriksen</div>
                                <div className='AdressBox__line'>E-post: henrelis@gmail.com</div>
                                <div className='AdressBox__line'>Mobil: 92081307</div>
                            </div>

                            <div className="totalBox">
                                <div className='totalBox__line'>
                                    <span>Bring servicepakke </span>
                                    <span>250 NOK</span>
                                </div>
                                <div className='totalBox__line'>
                                    <span>Påløpt lagerleie</span>
                                    <span>250 NOK</span>
                                </div>
                                <div className='totalBox__line total'>
                                    <span>Totalt</span>
                                    <span>450 NOK</span>
                                </div>
                            </div>

                            <div className="terms">
                                <input type="checkbox" id="terms" name="terms" />
                                <label htmlFor="terms">Jeg godtar Blomqvist sine <a href="#">vilkår og betingelser</a> for budgivning.</label>
                            </div>

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-gray sm'>Avslutt</button>
                        <button className='btn btn-primary sm'>Gå til betaling</button>
                    </div>

                </div>
            </div>              
        </div>    
        <></>
        
        </Default>        
    )
}