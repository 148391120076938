import { useState, useEffect, useRef } from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'
import '../style.scss'

export default function ModalObjectView(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalObjectView active'>
                <div className='modalObjectView__wrapper'>
                    <div className='modalObjectView__wrapper--content'>

                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                        
                        <div className='content'>
                            <div className='wrapper lg'>

                                <div className='title'>
                                    <h2>Fraktalternativer</h2>
                                    <h3>Objekt.nr. xxxxxxx kan leveres på følgende måter:</h3>
                                </div>

                                <div className='shippingOptions'>
                                    <div className="items">
                                        <div className='item'>
                                            <div className="wrapper">
                                                <div className='text'>Hente selv på Fornebu (ingen kostnad)</div>
                                                <button>Bestill</button>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <div className="wrapper">
                                                <div className='text'>Hentes på kontor i Bergen - 200 NOK</div>
                                                <button>Bestill</button>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <div className='wrapper'>
                                                <div className='text'>Sendes som Bring servicepakke - 250 NOK</div>
                                                <button>Bestill</button>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <div className="wrapper">
                                                <div className='text'>Bring ekspress hjemlevering - 550 NOK</div>
                                                <button>Bestill</button>
                                            </div>
                                            <div className="info">Ved levering til din leveringsadresse: Erlevein 14 0244 Asker</div>
                                        </div>
                                    </div>
                                    <div className="abroad">
                                        <div className="text">Frakt til utlandet </div>
                                        <button>Ta kontakt med oss her</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Lukk vindu</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}