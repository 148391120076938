import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import './style.scss'

export default function CommonQuestions(): JSX.Element{

    return(
        <>
        <div className='commonQuestions'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>

                        <h2 className='commonQuestions__title'>Ofte stilte spørsmål</h2>

                        <div className='commonQuestions__accordion'>
                            <Accordion allowMultipleExpanded allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit? <span className="icon-CaretDown"></span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className='content'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in lobortis urna, a blandit est. Pellentesque vitae odio velit. Integer rhoncus, nunc eu maximus facilisis, elit est dictum orci, quis mollis nunc turpis a tortor.</p>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit? <span className="icon-CaretDown"></span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className='content'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in lobortis urna, a blandit est. Pellentesque vitae odio velit. Integer rhoncus, nunc eu maximus facilisis, elit est dictum orci, quis mollis nunc turpis a tortor.</p>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit? <span className="icon-CaretDown"></span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className='content'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in lobortis urna, a blandit est. Pellentesque vitae odio velit. Integer rhoncus, nunc eu maximus facilisis, elit est dictum orci, quis mollis nunc turpis a tortor.</p>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit? <span className="icon-CaretDown"></span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className='content'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in lobortis urna, a blandit est. Pellentesque vitae odio velit. Integer rhoncus, nunc eu maximus facilisis, elit est dictum orci, quis mollis nunc turpis a tortor.</p>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>   

                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}