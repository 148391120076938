import React from 'react'
import './style.scss'

export default function InstagramGallery(): JSX.Element{

    return(
        <>
            <div className='importantNotification yellow'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='wrapper'>
                                <span className="icon-Info"></span>
                                <div className="content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id mauris dui enim hac facilisis amet maecenas. Pretium neque, tristique elit imperdiet. Lectus mauris sed tristique tortor tellus convallis morbi. Nulla.</p>
                                    <p>
                                        <a href="#">Se alle</a>
                                    </p>                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>     
        </>
    )
}