import React,{useState} from 'react'
import ImageObject from '../../../assets/images/sell1.png'
import ImageObject2 from '../../../assets/images/sell2.png'
import ImageObject3 from '../../../assets/images/sell3.png'
import ImageObject4 from '../../../assets/images/sell4.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import './style.scss'


export default function MyPageOptions(): JSX.Element{




    return(
        <>
        <div className='myPageOptions'>
            
            <div className='container'>
                <div className='row'>
                    <div className="col-lg-12">
                        <div className='myPageOptions__title'>
                            <h2>Gjør endringer på profilen din</h2>
                        </div>
         
                    </div>
                </div>
            </div>
    

            <div className="myPageOptions__items">
                <div className='container'>
                    <div className='row'>
                        
                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-User"></span>
                                       <h3>Min profil</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-CreditCard"></span>
                                       <h3>Mine kort</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        
                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-Bid"></span>
                                       <h3>Mine bud</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-Handbag"></span>
                                       <h3>Mine kjøp og betalinger</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-Heart"></span>
                                       <h3>Favoritter</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-FileText"></span>
                                       <h3>Mine salg og kontrakter</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-Bell"></span>
                                       <h3>Tilpass varslinger</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-MagnifyingGlass"></span>
                                       <h3>Lagrede søk</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-Coin"></span>
                                       <h3>Online vurdering</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-Gear"></span>
                                       <h3>Vilkår og personvern</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="item">
                                <a href="#">
                                    <div className="content">
                                        <span className="icon-CaretRight"></span>
                                       <span className="icon-EnvelopeSimple"></span>
                                       <h3>Nyhetsbrev</h3>
                                       <p>Oppdater eller endre personlige opplysinger og kontaktdetaljer</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>                                
            </div>



        </div>        
        </>
    )
}