import React from 'react'
import { Default } from '../../../components/templates'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import './style.scss'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span>Min side</a>
                            <h1>Tilpass varslinger</h1>                         
                        </div>
                    </div>
                </div>    
            </div>

            <div className='customizeAlerts'>
                
                {/* DESKTOP */}
                <div className="desktop">
                    <div className='container'>
                        <div className='row'>
                            <div className='offset-lg-1 col-lg-10'>

                                <div className="customizeAlerts__section">
                                    <h2 className='title'>Mine favoritter</h2>
                                    <div className="options">

                                        <div className='item'>
                                            <h3 className='title'>Varsling når en favoritt mottar sitt første bud</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Varsling når en favoritt har 24timer igjen av auksjonen </h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> 

                                <div className="customizeAlerts__section">
                                    <h2 className='title'>Lagrede søk</h2>
                                    <div className="options">

                                        <div className='item'>
                                            <h3 className='title'>Varsel ved nye treff i søket</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> 

                                <div className="customizeAlerts__section">
                                    <h2 className='title'>Mine bud</h2>
                                    <div className="options">

                                        <div className='item'>
                                            <h3 className='title'>Varsling når noen byr over meg</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Varsling når det er 4 timer igjen av auksjonen </h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Enda en varsling her om auksjon</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>                                    

                                    </div>
                                </div> 

                                <div className="customizeAlerts__section">
                                    <h2 className='title'>Mine salg</h2>
                                    <div className="options">

                                        <div className='item'>
                                            <h3 className='title'>Varsling når et objekter har vært forsøkt 4 gange eller mer</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Varsling når lageleie løper på et objekt</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Varsling når det har kommet inn et bud på et objekt</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Varsling når en auksjon starter</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Varsling om å sette ned prisen på et objekt</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> 

                                <div className="customizeAlerts__section">
                                    <h2 className='title'>Meldinger fra Blomqvist</h2>
                                    <div className="options">

                                        <div className='item'>
                                            <h3 className='title'>Varsling om betaling</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div>
                                                <div className="switch active">                                                       
                                                    <span className='label'>Alltid på E-post</span>                                  
                                                </div>
                                                <div className="switch active">       
                                                    <span className='label'>Alltid på Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Varsling om betalingskort</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div>
                                                <div className="switch active">       
                                                    <span className='label'>Alltid på E-post</span>                                  
                                                </div>
                                                <div className="switch active">       
                                                    <span className='label'>Alltid på Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Varsling om betalingskort</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">           
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div>                                             
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <h3 className='title'>Enda en varsling her</h3>
                                            <div className='switchWrapper'>
                                                <div className="switch">           
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>SMS</span>                                  
                                                </div> 
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>E-post</span>                                  
                                                </div>
                                                <div className="switch">       
                                                    <label className="switchButton" aria-label="Switch">
                                                        <input type="checkbox" className="switchButton-input"/>
                                                        <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                        <span className="switchButton-handle"></span>
                                                    </label>
                                                    <span className='label'>På Min side</span>                                  
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> 

                            </div>
                        </div>
                    </div>                    
                </div>
                {/* DESKTOP */}

                {/* MOBILE */}
                <div className="mobile">

                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-1 col-lg-10">

                                <div className="accordionSection">
                                    <Accordion preExpanded={['item1']} allowMultipleExpanded>
                                        
                                        <AccordionItem uuid="item1">
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Mine favoritter <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className="item">
                                                    <h3 className='title'>Varsling når en favoritt mottar sitt første bud</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                                <div className="item">
                                                    <h3 className='title'>Varsling når en favoritt har 24timer igjen av auksjonen </h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Lagrede søk <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='item'>
                                                    <h3 className='title'>Varsel ved nye treff i søket</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Mine bud <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='item'>
                                                    <h3 className='title'>Varsling når noen byr over meg</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Varsling når det er 4 timer igjen av auksjonen </h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Enda en varsling her om auksjon</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>       
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Mine salg <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='item'>
                                                    <h3 className='title'>Varsling når et objekter har vært forsøkt 4 gange eller mer</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Varsling når lageleie løper på et objekt</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Varsling når det har kommet inn et bud på et objekt</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Varsling når en auksjon starter</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Varsling om å sette ned prisen på et objekt</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Meldinger fra Blomqvist <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='item'>
                                                    <h3 className='title'>Varsling om betaling</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div>
                                                        <div className="switch active">                                                       
                                                            <span className='label'>Alltid på E-post</span>                                  
                                                        </div>
                                                        <div className="switch active">       
                                                            <span className='label'>Alltid på Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Varsling om betalingskort</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div>
                                                        <div className="switch active">       
                                                            <span className='label'>Alltid på E-post</span>                                  
                                                        </div>
                                                        <div className="switch active">       
                                                            <span className='label'>Alltid på Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Varsling om betalingskort</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">           
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div>                                             
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='item'>
                                                    <h3 className='title'>Enda en varsling her</h3>
                                                    <div className='switchWrapper'>
                                                        <div className="switch">           
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>SMS</span>                                  
                                                        </div> 
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>E-post</span>                                  
                                                        </div>
                                                        <div className="switch">       
                                                            <label className="switchButton" aria-label="Switch">
                                                                <input type="checkbox" className="switchButton-input"/>
                                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                                <span className="switchButton-handle"></span>
                                                            </label>
                                                            <span className='label'>På Min side</span>                                  
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>                                    

                                    </Accordion>
                                </div>

                            </div>
                        </div>
                    </div>
                            
                </div>
                {/* MOBILE */}

            </div>







            <></>

        </Default>        
    )
}