import React from 'react'
import { Default } from '../../../components/templates'
import ImageFeatured from '../../../assets/images/article-feature-image.png'
import { RelatedArticles } from '../../../components/modules'
import { ImageBlockLandscape1 } from '../../../components/modules'

export default function Article(): JSX.Element{
    return(        
        <Default headerColor="background">

            <div className='article'>

                <div className='article__header background'>

                    <div className='breadCrumbs article'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <a href="#" className='breadCrumbs__item'>Breadcrumb</a>
                                    <span className='icon-CaretRight'></span>
                                    <a href="#" className='breadCrumbs__item last'>Breadcrumb</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='title'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h1>Headline here</h1>                         
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="article__imageFeature background">
                    <div className='container'>
                        <div className='row'>
                            <div className='offset-lg-1 col-lg-10'>
                                <figure className='img aspectRatio--1062x698'>
                                    <img src={ImageFeatured} alt="" />                                
                                </figure>
                                <span className='figcaption'>Lorem ipsum dolor sit amet.</span>
                            </div>
                        </div>
                    </div>                                
                </div>

                <div className="article__content"> 

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                  
                                <div className="article__content--intro">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id sapien laoreet nunc viverra phasellus. Quis velit, habitasse odio eros. Odio vel, tellus aliquam diam interdum. Fames pellentesque eu eu facilisis massa purus turpis. Venenatis ultrices arcu enim quam malesuada. Adipiscing.</p>
                                </div>

                                <div className="article__content--wrapper">

                                    <h2>Headline here</h2>   

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est amet nunc feugiat felis sed at magna eu. At dui, lobortis elit consectetur. Scelerisque amet sit lectus mi. Vitae morbi varius orci a. Elementum ipsum duis augue egestas viverra maecenas vitae aliquam in. Vulputate amet mauris vitae massa habitasse vitae. Phasellus phasellus aliquam velit viverra. Sed aliquam augue venenatis quis. Libero quis imperdiet quis lorem dictum morbi phasellus. Orci, bibendum neque nunc quis blandit consectetur sagittis.</p>

                                    <p>In euismod eu nisl nulla faucibus tincidunt tincidunt. At imperdiet netus enim tortor. Vitae, duis nunc donec et consequat sed sollicitudin cursus in. At purus.</p>

                                    <h2>Headline here</h2>
                                    
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies. Tellus consectetur id sit sit leo aliquet sed vulputate. At ornare egestas pellentesque et. Enim ac penatibus quam fermentum aliquet. <a href="#">Arcu justo in egestas vitae</a> varius eget accumsan.</p>

                                    <p>Neque, adipiscing tortor ac id dolor felis. Dictumst etiam cras pellentesque scelerisque venenatis. Habitant diam porttitor penatibus integer. Quis duis facilisi erat ac lacus, quisque nibh malesuada tortor. Scelerisque scelerisque lobortis aenean mattis tellus varius ut. Vel eros egestas sit amet, dignissim arcu et elit pulvinar. In nibh sollicitudin fringilla libero gravida amet egestas. Pellentesque non pharetra turpis tristique. Amet senectus purus neque praesent blandit. Risus libero, malesuada vitae ut magna aliquam, quam vitae. Bibendum quam.</p>

                                </div>   

                            </div> 
                        </div> 
                    </div> 
                    
                    <ImageBlockLandscape1/>
                    
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="article__content--wrapper">

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies. Tellus consectetur id sit sit leo aliquet sed vulputate. At ornare egestas pellentesque et. Enim ac penatibus quam fermentum aliquet. Arcu justo in egestas vitae varius eget accumsan.</p>

                                    <h3>Hadeline 2 here</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies. Tellus consectetur id sit sit leo aliquet sed vulputate. At ornare egestas pellentesque et. Enim ac penatibus quam fermentum aliquet. Arcu justo in egestas vitae varius eget accumsan.</p> 

                                    <ul>
                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel cras eu cum.</li>
                                        <li><strong>Lorem ipsum dolor sit amet, consectetur.</strong></li>
                                        <li>Lorem ipsum dolor sit amet,<a href="#"> consectetur adipiscing elit</a>.</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel cras eu cum.</li>
                                    </ul>

                                </div>   

                            </div>
                        </div>
                    </div>  

                </div>            
            </div>   
            <RelatedArticles/>   
        </Default>        
    )
}