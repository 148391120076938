import React,{useState} from 'react'
import Image1 from '../../../assets/images/excellentList1.png'
import Image2 from '../../../assets/images/excellentList2.png'
import Image3 from '../../../assets/images/excellentList3.png'
import SelectSearch from 'react-select-search'
import './style.scss'

export default function ActiveBids(): JSX.Element{

    const [modalMakeAbid,setmodalMakeAbid] = useState(false)
    const toggleModalMakeAbid = ()=>{
        setmodalMakeAbid(!modalMakeAbid)
    }

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10">
                    <div className="activeBids">
                        <div className="activeBids__header">
                            <div className='info'>
                                <span className='title'>Aktive bud</span>
                            </div>
                            <div className='options'>
                                {/* <button><span className='icon-Filter'></span></button>
                                <button><span className='icon-MagnifyingGlass'></span></button> */}
                            </div>
                        </div>
                        <div className="activeBids__list">

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="line desktop">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line mobile">Neste bud: <strong> 9.000 NOK</strong></div>
                                        <div className="line mobile">Tid igjen: <strong> 15d 6t 15min</strong></div>
                                        <div className="line desktop">Vurdering : <strong>8.000 - 9.000 NOK</strong></div>

                                        <div className='tags showMobile'>
                                            <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                            <span className='tag green'>Du leder</span>
                                        </div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'>
                                            <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                            <span className='tag green'>Du leder</span>
                                        </div>
                                        <div className="line">Tid igjen: <strong> 15d 6t 15min</strong></div>
                                        <div className="line">Neste bud: <strong> 9.000 NOK</strong></div>
                                    </div>
                                    <span className='icon-CaretRight'></span>
                                </div>
                            </a>
                         
                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image2} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="line desktop">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line mobile">Neste bud: <strong> 9.000 NOK</strong></div>
                                        <div className="line mobile">Tid igjen: <strong> 15d 6t 15min</strong></div> 
                                        <div className="line desktop">Vurdering : <strong>8.000 - 9.000 NOK</strong></div>

                                        <div className='tags showMobile'>
                                            <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                            <span className='tag red'>Du er overbydd</span>
                                        </div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'>
                                            <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                            <span className='tag red'>Du er overbydd</span>
                                        </div>
                                        <div className="line">Tid igjen: <strong> 15d 6t 15min</strong></div>
                                        <div className="line">Neste bud: <strong> 9.000 NOK</strong></div>
                                    </div>
                                    <span className='icon-CaretRight'></span>
                                </div>
                            </a>
    
                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image3} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="line desktop">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line mobile">Neste bud: <strong> 9.000 NOK</strong></div>
                                        <div className="line mobile">Tid igjen: <strong> 15d 6t 15min</strong></div> 
                                        <div className="line desktop">Vurdering : <strong>8.000 - 9.000 NOK</strong></div>

                                        <div className='tags showMobile'>
                                            <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                            <span className='tag green'>Du leder</span>
                                        </div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'>
                                            <span className="tag gray btn" onClick={toggleModalMakeAbid}>Gi bud</span>
                                            <span className='tag green'>Du leder</span>
                                        </div>
                                        <div className="line">Tid igjen: <strong> 15d 6t 15min</strong></div>
                                        <div className="line">Neste bud: <strong> 9.000 NOK</strong></div>
                                    </div>
                                    <span className='icon-CaretRight'></span>
                                </div>
                            </a>

                        </div>

                        {/* Modal: Make an offer (Gi bud) MOBILE*/}
                        <div className={"makeAbidMobile " + `${modalMakeAbid?'active':''}`}>
                            <div className='makeAbidMobile__wrapper'>
                                <div className='makeAbidMobile__wrapper--content'>

                                    <div className='btnCloseModalWrapper'>
                                        <button className='btnCloseModal' aria-label='Close' onClick={toggleModalMakeAbid}><span className='icon-X'></span></button>
                                    </div>
                                    
                                    <div className='content' id='contentH'>
                                        <div className='wrapper' id='wrapperH'>

                                            <div className="title">
                                                <h2>Gi bud</h2>
                                                <h3>Velg ditt nye maksbuds</h3>
                                            </div>

                                            <div className="list">
                                                <div className="item active"><span>1 500 NOK</span></div>
                                                <div className="item"><span>2 000 NOK</span></div>
                                                <div className="item"><span>2 500 NOK</span></div>
                                                <div className="item"><span>3 000 NOK</span></div>
                                                <div className="item"><span>3 500 NOK</span></div>
                                                <div className="item"><span>4 500 NOK</span></div>
                                                <div className="item"><span>5 000 NOK</span></div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="btnWrapper">
                                        <button className='btn btn-gray sm' onClick={toggleModalMakeAbid}>Avslutt</button>
                                        <button className='btn btn-primary sm'>Gi bud</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* Modal: Make an offer (Gi bud) MOBILE*/}

                        {/* Modal: Make an offer (Gi bud) DESKTOP*/}
                        <div className='makeAbidDesktop active'>
                            <div className='makeAbidDesktop__wrapper'>
                                <div className='makeAbidDesktop__wrapper--content'>

                                    <div className='btnCloseModalWrapper'>
                                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                                    </div>
                                    
                                    <div className='content'>
                                        <div className='wrapper'>
                                            <h2>Gi nytt bud</h2>
                                            <p className="ingress">2 x riesling wiebelsberg grand cru 2012, kreydenweiss objektnr. 141335-6</p>
                                            
                                            <div className="select">
                                                <SelectSearch
                                                    options={[
                                                        { value: '1 500 NOK', name: '1 500 NOK'},
                                                        { value: '2 000 NOK', name: '2 000 NOK'},
                                                        { value: '2 500 NOK', name: '2 500 NOK'},
                                                        { value: '3 000 NOK', name: '3 000 NOK'},
                                                        { value: '3 500 NOK', name: '3 500 NOK'},
                                                        { value: '4 500 NOK', name: '4 500 NOK'},
                                                        { value: '5 000 NOK', name: '5 000 NOK'}
                                                    ]}
                                                    placeholder="Velg ditt nye maksbud"                                                    
                                                />
                                            </div>
                                                    
                                            <div className="infoSelect"><p>Det vil påløpe noen <a href="#">omkostninger</a> i tilleg til budet</p></div>

                                        </div>
                                    </div>

                                    <div className='btnWrapper'>
                                        <button className='btn btn-gray sm'>Avslutt</button>
                                        <button className='btn btn-primary sm'>Gi bud</button>
                                    </div>

                                </div>
                            </div>              
                        </div>           
                        {/* Modal: Make an offer (Gi bud) DESKTOP*/}

                    </div>
                </div>
            </div>
        </div>   
        </>
    )
}