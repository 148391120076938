import React from 'react'
import { Default } from '../../components/templates'

import {
    ContactCards,
    TableContactUs
} from '../../components/modules'
import './style.scss'

export default function ContactUs(): JSX.Element{
    return(        
        <Default headerColor="background">

            <div className='contactUs'>

                <div className='contactUs__header background'>

                    <div className='breadCrumbs article'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <a href="#" className='breadCrumbs__item'>Hjem</a>
                                    <span className='icon-CaretRight'></span>
                                    <a href="#" className='breadCrumbs__item last'>Kontakt oss</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='intro'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='wrapper'>
                                        <h1>Kontakt oss</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat dolor nisi molestie non risus volutpat. Netus dapibus nisi dolor aliquet lobortis vitae nullam. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            
                <ContactCards/>

                <div className="article__content"> 

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>                                  

                                <div className="article__content--wrapper">

                                    <h2>Åpningstider</h2>   

                                    <p>Vi tar i mot gjester hver mandag - søndag på Fornebu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat dolor nisi molestie non risus volutpat. Netus dapibus nisi dolor aliquet lobortis vitae nullam.</p>

                                    {/* Add module TableContactUs for the tables*/}
                                    {/* <TableContactUs/> */}

                                    <table className='tableContactUs'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    ÅPNINGSTIDER FORNEBU
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Mandag</td>
                                                <td>11.00 - 18.00</td>
                                            </tr>
                                            <tr>
                                                <td>Tirsdag</td>
                                                <td>11.00 - 18.00</td>
                                            </tr>
                                            <tr>
                                                <td>Onsdag</td>
                                                <td>11.00 - 18.00</td>
                                            </tr>
                                            <tr>
                                                <td>Torsdag</td>
                                                <td>11.00 - 18.00</td>
                                            </tr>
                                            <tr>
                                                <td>Fredag</td>
                                                <td>10.00 - 15.00</td>
                                            </tr>
                                            <tr>
                                                <td>Lørdag</td>
                                                <td>11.00 - 15.00</td>
                                            </tr>
                                            <tr>
                                                <td>Søndag</td>
                                                <td>Stengt</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p>Avdeling Bergen har åpen hver onsdag fra 11.00 - 18.00.</p>

                                    <h2>For verdivurdering av vin og brennevin</h2>

                                    <p>Utlevering av vin og brennevin kan gjøres i vår åpningstid eller etter avtale. Innlevering av vin og brennevin gjøres kun etter forhåndsvurdering av Vinmonopolet. Vennligst kontakt <a href="mailto:auksjoner@vinmonopolet.no">auksjoner@vinmonopolet.no</a> </p>

                                    <h2>Teknisk svikt</h2>

                                    <p>Blomqvist har lagt ned store ressurser med det formål å få et driftssikkert og pålitelig auksjonssystem. Vi utelukker likevel ikke at teknisk svikt kan skje og hindre auksjonen å forløpe som forventet. I et slikt tilfelle forbeholder vi oss retten til å stanse en pågående auksjon, midlertidig eller permanent. Videre forbeholder vi oss retten til å annullere en avsluttet auksjon selv om en kjøper har fått melding om tilslag på et objekt, uten at dette medfører ansvar for oss.</p>

                                    <p>Vår tekniske gjennomføring avhenger av forhold lokalt hos den enkelte kunde ,  som eget datautstyr, internettlinje o g lignende. Blomqvist kan ikke stå ansvarlig for at dette fungerer. Vi gjør oppmerksom på at klokken som teller ned til auksjons -slutt kjører på kundens egen maskinenhet. Den vil derfor fortsette å telle ned selv om kontakten med Blomqvists server skulle være brutt. I slike tilfeller vil budgiver for eksempel kunne gå glipp av oppdatering om høyere bud. Du er selv ansvarlig for at kontakten mot internett er intakt. Vi anbefaler deg å oppdatere nettsiden med jevne mellomrom gjennom å trykke «refresh» det vil si laste inn siden på nytt.</p>

                                    <h2>Andre viktige opplysninger</h2>

                                    {/* Add module TableContactUs for the tables*/}
                                    {/* <TableContactUs/> */}

                                    <table className='tableContactUs'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    BLOMQVIST NETTAUKSJON AS
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>SWIFT</td>
                                                <td>DNBANOKKXXX</td>
                                            </tr>
                                            <tr>
                                                <td>IBAN</td>
                                                <td>NO56 1506 3155 222</td>
                                            </tr>
                                            <tr>
                                                <td>Bank konto</td>
                                                <td>1506.31.55222</td>
                                            </tr>
                                            <tr>
                                                <td>Org. nr.</td>
                                                <td>NO - 910686720MVA</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className='tableContactUs'>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    BLOMQVIST NETTAUKSJON AS
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>SWIFT</td>
                                                <td>DNBANOKKXXX</td>
                                            </tr>
                                            <tr>
                                                <td>IBAN</td>
                                                <td>NO56 1506 3155 222</td>
                                            </tr>
                                            <tr>
                                                <td>Bank konto</td>
                                                <td>1506.31.55222</td>
                                            </tr>
                                            <tr>
                                                <td>Org. nr.</td>
                                                <td>NO - 910686720MVA</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p>DNB ASA, Postboks 1600 Sentrum, 0021 Oslo.</p>

                                </div>   

                            </div> 
                        </div> 
                    </div> 
                    
                </div>  

            </div>   
            <></> 
        </Default>        
    )
}