import React from 'react'
import { Default } from '../../../components/templates'
import './style.scss'

export default function Newsletter(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                            <h1>Nyhetsbrev</h1>
                        </div>
                    </div>
                </div>    
            </div>

            <div className="newsletterConfig">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10">

                            <div className="newsletterConfig__header">
                                <h2 className='title'>Innstillinger for nyhetsbrev</h2>
                            </div>

                            <div className="newsletterConfig__list">

                                <div className="item">
                                    <div className="content">
                                        <h3>Blomqvist nettauksjon</h3>
                                        <p>Blomqvist Nettauksjon selger kunst, møbler, interiør og andre verdifulle gjenstander som moderne design, bondeantikviteter, smykker og fashion. Vi har nye varer hver dag og auksjonsavslutning 5 dager i uken.</p>  
                                        <div className='showMore'>
                                            <button><span className='text'>Les mer </span><span className='icon-CaretDown'></span></button>
                                        </div>                          
                                    </div>
                                    <div className="switch">                                                    
                                        <label className="switchButton" aria-label="Switch">
                                            <input type="checkbox" className="switchButton-input" defaultChecked/>
                                            <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                            <span className="switchButton-handle"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="content">
                                        <h3>Betingelser for nettauksjon</h3>
                                        <p>Blomqvist Kunsthandel avholder auksjoner over kunst i høyere prisklasser innenfor alle tidsepoker fra eldre til samtidskunst, og med spesiell vekt på Edvard Munch. Vi arrangerer salgsutstillinger og formidler salg direkte mellom selger og kjøper.</p>  
                                        <div className='showMore'>
                                            <button><span className='text'>Les mer </span><span className='icon-CaretDown'></span></button>
                                        </div>                          
                                    </div>
                                    <div className="switch">                                                    
                                        <label className="switchButton" aria-label="Switch">
                                            <input type="checkbox" className="switchButton-input" defaultChecked/>
                                            <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                            <span className="switchButton-handle"></span>
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <></>

        </Default>        
    )
}