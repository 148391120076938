import React from 'react'
import { Default } from '../../../../components/templates'
import {
    ObjectsForPayment,
    ChoosePaymentMethod
} from '../../../../components/modules'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span>Mine kjøp og betalinger</a>
                            <h1>Objekter til betaling</h1>                         
                        </div>
                    </div>
                </div>    
            </div>

            <ObjectsForPayment/>

            <ChoosePaymentMethod/>

            <></>

        </Default>        
    )
}