import React from 'react'
import { Default } from '../../../components/templates'
import Image1 from '../../../assets/images/card-icon.svg'
import Image2 from '../../../assets/images/house-icon.svg'
import Image3 from '../../../assets/images/profile-icon.svg'
import Image4 from '../../../assets/images/shield-icon.svg'
import Image5 from '../../../assets/images/mini-image.png'
import SelectSearch from 'react-select-search'
import './style.scss'

export default function Alerts(): JSX.Element{
    return(        
        <Default headerColor="light">
            <></>               
            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                            <h1>Varslinger</h1>                         
                        </div>
                    </div>
                </div>    
            </div>
            
            <div className="alertsWrapper">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10">

                            <div className="alertsWrapper__options">

                                <div className="alertsWrapper__options--desktop">
                                    <button className='item active'>Alle varslinger</button>
                                    <button className='item'>Mine bud</button>
                                    <button className='item'>Mine favoritter</button>
                                    <button className='item'>Kjøp og betalinger</button>
                                    <button className='item'>Mine salg</button>                                    
                                </div>

                                <div className="alertsWrapper__options--mobile">
                                    <div className='select'>
                                        <SelectSearch
                                            options={[
                                                { value: 'option1', name: 'Alle varslinger'},
                                                { value: 'Fornebu', name: 'Mine bud'},
                                                { value: 'option2', name: 'Mine favoritter'},
                                                { value: 'option3', name: 'Kjøp og betalinger'},
                                                { value: 'option4', name: 'Mine salg'}
                                            ]}
                                            placeholder="Alle varslinger"
                                            value={'Alle varslinger'}
                                        />
                                    </div>                                    
                                </div>

                            </div>

                            <div className="alertsWrapper__items">
                                    
                                <div className="item red">
                                    <div className="image">
                                        <figure>
                                            <img src={Image3} alt="" />
                                        </figure>
                                    </div>
                                    <div className="content">
                                        <h3 className='title'>Oppdater profil</h3>
                                        <p className='text'>Vi trenger litt mer informasjon om deg. Klikk her for å ferdigstille profilen din.</p>
                                        <div className='date'>I dag - 10:45</div>
                                    </div>                                    
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <figure>
                                            <img src={Image5} alt="" />
                                        </figure>
                                    </div>
                                    <div className="content">
                                        <h3 className='title'>Objekt du følger</h3>
                                        <p className='text'>Favoritten din “Chanel 525 bag” har fått inn sitt første bud på 15.000 NOK</p>
                                        <div className='date'>I dag - 10:45</div>
                                    </div>                                    
                                </div>

                                <div className="item yellow">
                                    <div className="image">
                                        <figure>
                                            <img src={Image4} alt="" />
                                        </figure>
                                    </div>
                                    <div className="content">
                                        <h3 className='title'>Sikkerhet</h3>
                                        <p className='text'>På tide å bytte passord på Min side. <a href="#" target="_blank">Det gjør du her</a>.</p>
                                        <div className='date'>I går - 10:45</div>
                                    </div>                                    
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <figure>
                                            <img src={Image1} alt="" />
                                        </figure>
                                    </div>
                                    <div className="content">
                                        <h3 className='title'>Betaling</h3>
                                        <p className='text'>Ditt kjøp “Ring” på 7.400 NOK venter på betaling. <a href="#" target="_blank"> Gå til betaling</a>.</p>
                                        <div className='date'>I går - 10:45</div>
                                    </div>                                    
                                </div>

                                <div className="item red">
                                    <div className="image">
                                        <figure>
                                            <img src={Image2} alt="" />
                                        </figure>
                                    </div>
                                    <div className="content">
                                        <h3 className='title'>Lagerleie</h3>
                                        <p className='text'>Vi trenger litt mer informasjon om deg. Klikk her for å ferdigstille profilen din.</p>
                                        <div className='date'>I går - 10:45</div>
                                    </div>                                    
                                </div>

                                <div className="item">
                                    <div className="image">
                                        <figure>
                                            <img src={Image5} alt="" />
                                        </figure>
                                    </div>
                                    <div className="content">
                                        <h3 className='title'>Objekt du følger</h3>
                                        <p className='text'>Favoritten din “Chanel 525 bag” har fått inn sitt første bud på 15.000 NOK</p>
                                        <div className='date'>I dag - 10:45</div>
                                    </div>                                    
                                </div>

                            </div>

                            <div className='alertsWrapper__showMore'>
                                <button className='btn btn-primary lg'>Vis flere treff</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </Default>        
    )
}