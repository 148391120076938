import React from 'react'
import { Default } from '../../components/templates'
import Emptyframe from '../../assets/images/emtyframe2.png'
import './style.scss'
import { 
    CookieBanner
} from '../../components/modules'

export default function Error404(): JSX.Element{
    return(        
        <Default headerColor="green-15">
            <></>        
            <div className="error404-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="error404-2__wrapper">
                                
                                <a href="#" className='navigationLink left'><span className='icon-ArrowLeft'></span> Gå forige side</a>

                                <div className="frameWrapper">
                                    <div className="frame">
                                        <div className="content">
                                            <h1 className='title'>
                                                Obs <br />
                                                Dessverre ikke <br />
                                                noe å se her
                                            </h1>
                                            <div className="linksWrapper ">
                                                <a href="#">  <span className='icon-ArrowLeft'></span> Gå forige side</a>
                                                <a href="#">Gå til forsiden <span className='icon-ArrowRight'></span></a>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div> 

                                <a href="#" className='navigationLink right'>Gå til forsiden <span className='icon-ArrowRight'></span></a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Default>        
    )
}