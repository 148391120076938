import React from 'react'
import './style.scss'

export default function Header(): JSX.Element{ 
      
    return(
        <>
        <div className='headerCategoryAuctions'> 
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1 className='headerCategoryAuctions__title'>Auksjoner</h1>
                    </div>
                </div>
                <div className="row">
                    <div className='headerCategoryAuctions__list'>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Kunst</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Sølv, glass og porselen</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Klokker og ur</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Antikviteter</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Asiatiske antikviteter og kunst</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Vintage og fashion</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Moderne møbler og interiør</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Våpen</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Vin og brennevin</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Bondeantikviteter</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Smykker</a>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='content'>
                                <a href="#">Klassisk auksjon</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}