import React from 'react'
import Image1 from '../../../assets/images/excellentList1.png'
import './style.scss'

export default function SavedSearchResult(): JSX.Element{

    return(
        <>

        <div className="savedSearchResult">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10">

                        <div className="item">
                            <div className="savedSearchResult__header">
                                <div className='search'>
                                    <button><span className='icon-MagnifyingGlass'></span></button>
                                    <p>Nye treff for søket:</p>
                                    <div className="tags">
                                        <span className="tag gray">Vin</span>
                                        <span className="tag gray">Brunello</span>
                                    </div>
                                </div>
                                <div className="remove">
                                    <button><span className='icon-Trash'></span></button>
                                </div>
                            </div>                        

                            <div className="savedSearchResult__list">

                                <a className="item" href='#'>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Navn på objekt</h3>
                                            <div className="line">8.000 - 9.000 NOK <span className='hideMobile'> | 5d 6t 15min</span></div>
                                            <div className="line">Neste bud: <strong>8.500 NOK</strong></div>
                                        </div>
                                    </div>
                                </a>    

                                <a className="item" href='#'>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="info">
                                    <div className="data">
                                            <h3 className='name'>Navn på objekt</h3>
                                            <div className="line">8.000 - 9.000 NOK <span className='hideMobile'> | 5d 6t 15min</span></div>
                                            <div className="line">Neste bud: <strong>8.500 NOK</strong></div>
                                        </div>
                                    </div>
                                </a>
                        
                            </div>

                            <div className="savedSearchResult__showMore">
                                <button className='btn-link'>Se flere treff</button>
                            </div>                       

                        </div>

                        <div className="item">
                            <div className="savedSearchResult__header">
                                <div className='search'>
                                    <button><span className='icon-MagnifyingGlass'></span></button>
                                    <p>Nye treff for søket:</p>
                                    <div className="tags">
                                        <span className="tag gray">Glass og porselen / Glass</span>
                                        <span className="tag gray">Vase</span>
                                    </div>
                                </div>
                                <div className="remove">
                                    <button><span className='icon-Trash'></span></button>
                                </div>
                            </div>                        

                            <div className="savedSearchResult__list">

                                <a className="item" href='#'>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Navn på objekt</h3>
                                            <div className="line">8.000 - 9.000 NOK <span className='hideMobile'> | 5d 6t 15min</span></div>
                                            <div className="line">Neste bud: <strong>8.500 NOK</strong></div>
                                        </div>
                                    </div>
                                </a>    

                                <a className="item" href='#'>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="info">
                                    <div className="data">
                                            <h3 className='name'>Navn på objekt</h3>
                                            <div className="line">8.000 - 9.000 NOK <span className='hideMobile'> | 5d 6t 15min</span></div>
                                            <div className="line">Neste bud: <strong>8.500 NOK</strong></div>
                                        </div>
                                    </div>
                                </a>
                        
                            </div>

                            <div className="savedSearchResult__showMore">
                                <button className='btn-link'>Se flere treff</button>
                            </div>                       

                        </div>       

                        <div className="item">
                            <div className="savedSearchResult__header">
                                <div className='search'>
                                    <button><span className='icon-MagnifyingGlass'></span></button>
                                    <p>Nye treff for søket:</p>
                                    <div className="tags">
                                        <span className="tag gray">Kunst / Grafikk</span>
                                        <span className="tag gray">Pushwagner</span>
                                    </div>
                                </div>
                                <div className="remove">
                                    <button><span className='icon-Trash'></span></button>
                                </div>
                            </div>                        

                            <div className="savedSearchResult__list">

                                <a className="item" href='#'>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Navn på objekt</h3>
                                            <div className="line">8.000 - 9.000 NOK <span className='hideMobile'> | 5d 6t 15min</span></div>
                                            <div className="line">Neste bud: <strong>8.500 NOK</strong></div>
                                        </div>
                                    </div>
                                </a>    

                                <a className="item" href='#'>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="info">
                                    <div className="data">
                                            <h3 className='name'>Navn på objekt</h3>
                                            <div className="line">8.000 - 9.000 NOK <span className='hideMobile'> | 5d 6t 15min</span></div>
                                            <div className="line">Neste bud: <strong>8.500 NOK</strong></div>
                                        </div>
                                    </div>
                                </a>
                        
                            </div>

                            <div className="savedSearchResult__showMore">
                                <button className='btn-link'>Se flere treff</button>
                            </div>                       

                        </div>                                           
                       
                        <div className="multipleSavedSearches">
                            
                            <div className="multipleSavedSearches__header">
                                <button><span className='icon-MagnifyingGlass'></span></button>
                                <p>Flere lagrede søk</p>
                            </div>  

                            <div className="multipleSavedSearches__item">
                                <div className="tags">
                                    <span className="tag gray">Hariton</span>
                                    <span className="tag gray">Endaetsøkeord</span>
                                </div>
                                <button><span className='icon-Trash'></span></button>
                            </div>

                            <div className="multipleSavedSearches__item">
                                <div className="tags">
                                    <span className="tag gray">Hariton</span>
                                    <span className="tag gray">Endaetsøkeord</span>
                                </div>
                                <button><span className='icon-Trash'></span></button>
                            </div>

                            <div className="multipleSavedSearches__item">
                                <div className="tags">
                                    <span className="tag gray">Hariton</span>
                                    <span className="tag gray">Endaetsøkeord</span>
                                </div>
                                <button><span className='icon-Trash'></span></button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>





        </>
    )
}