import React from 'react';
import {BtnProfile} from '../../primitives/'
import './style.scss'

interface HiddenMenuProps{
    active: boolean|undefined
    toggle: ()=>void
}

export default function HiddenMenu({active, toggle}:HiddenMenuProps) {
    return (
        <div className={'mainMenu ' + `${active ? 'active' : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="mainMenu__content">
                            <div className="wrapper">
                                <button className="closeMenu" onClick={toggle}>
                                    <span className="icon-X"></span> Lukk meny
                                </button>
                                <div className="mainMenu__header">
                                    <div className="mainMenu__header--left">
                                        <button onClick={toggle}>
                                            <span className="icon-X"></span>
                                        </button>
                                    </div>
                                    <div className="mainMenu__header--right">
                                        <button className="searchBtn">
                                            <span className="icon-MagnifyingGlass"></span>
                                        </button>
                                        <button className="infoBtn">
                                            <span className="icon-Heart"></span>
                                            <span className="notification">
                                                1
                                            </span>
                                        </button>
                                        <BtnProfile/>
                                    </div>
                                </div>
                                <div className="mainMenu__content--nav">
                                    <span>
                                        <a href="#" className="item">
                                            Auksjoner
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#" className="item">
                                            Hvordan kjøpe
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#" className="item">
                                            Hvordan selge
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#" className="item">
                                            Om Blomqvist
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#" className="item">
                                            Artikler
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#" className="item">
                                            Kontakt oss
                                        </a>
                                    </span>
                                </div>
                                <div className="mainMenu__content--user">
                                    <span>
                                        <a href="#" className="item">
                                            Logg inn
                                        </a>
                                    </span>
                                    <span>
                                        <a href="#" className="item">
                                            Registrer deg
                                        </a>
                                    </span>
                                </div>
                                <div className="mainMenu__content--lang">
                                    <a href="#" className="item active">
                                        NO
                                    </a>
                                    <span>|</span>
                                    <a href="#" className="item">
                                        EN
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mainMenu__overlay" onClick={toggle}></div>
        </div>
    );
}
