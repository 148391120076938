import React from 'react'
import VippsLogo from '../../../assets/images/vipps-logo.svg'
import VippsLogoColor from '../../../assets/images/vipps-logo-color.svg'
import VippsIcon from '../../../assets/images/vipps-icon.svg'
import OkIcon from '../../../assets/images/okIcon.svg'
import './style.scss'

export default function ModalLogin(): JSX.Element{
    return(
        <>
        {/* Add .active class to enable login modal: <div className='modalLogin active'> */}
        <div className='modalLogin'>

            <div className='modalLogin__wrapper'>

                {/* Logg inn  */}
                    <div className='modalLogin__wrapper--content logIn-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Logg inn </h2>      

                            <div className='btnVippsWrapper'>
                                <p>Logg inn raskt og enkelt med Vipps</p>
                                <button>Logg inn med <img src={VippsLogo} alt="Vipps" /></button>
                            </div>

                            <div className='or'><span>eller </span></div>

                            <form className='loginEmail'>
                                <div className='input'>
                                    <label htmlFor="Epost">E-post*</label>
                                    {/* Add the ".error" class to the <input> to apply the style. Such that: <input className='error' type="text"/>  */}
                                    <input id='Epost' type="text"/>
                                    <span className='errorMenssage'>* sjekk at dette feltet er rett fylt ut</span> 
                                </div>
                                <div className='input'>
                                    <label htmlFor="Passord">Passord*</label>
                                    {/* Add the ".error" class to the <input> to apply the style. Such that: <input className='error' type="text"/>  */}
                                    <input id='Passord' type="password"/>
                                    <span className='errorMenssage'>* sjekk at dette feltet er rett fylt ut</span>
                                </div>
                                <div className='btnLoginWrapper'>
                                    <button type='submit' className='btn btn-primary lg'>Logg inn</button>
                                    <a href="#">Glemt passord?</a>
                                </div>
                            </form>
                        </div>
                        <div className='become-customer'>
                            <span>Ny her? </span>
                            <a href="#">Bli kunde hos blomqvist</a>
                        </div>
                    </div>
                {/* Logg inn  */}

                {/* Logg inn med Vipps */}
                    {/* <div className='modalLogin__wrapper--content logIn-vipps-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Logg inn med Vipps </h2>

                            <img className='vippsLogoColor' src={VippsLogoColor} alt="Vipps" />

                            <div className='loginMobile'>
                                <div className='input'>
                                    <label>Mobil nummer</label>
                                    <input type="tel"/>
                                </div>

                                <div className='btnLoginWrapper'>
                                    <button  className='btn btn-primary lg'>Fortsett</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* Logg inn med Vipps */}

                {/* Bekreft i Vipps */}
                    {/* <div className='modalLogin__wrapper--content confirm-vipps-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Bekreft i Vipps </h2>

                            <img className='vippsLogoColor' src={VippsLogoColor} alt="Vipps" />

                            <img className='vippsIcon' src={VippsIcon} alt="Vipps" />

                            <div className='inputMobileNumber'>
                                <input type="tel"/>
                            </div>

                            <div className='notificationVippsText'>
                                <p>Du har fått et varsel på telefonen din. <br /> Åpne Vipps og følg instruksjonene.</p>
                            </div>
                        </div>
                    </div> */}
                {/* Bekreft i Vipps */}

                {/* Så kommer det flere... */}
                    {/* <div className='modalLogin__wrapper--content info-vipps-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Så kommer det flere <br /> steg - resten av logg inn<br /> prosedyren hentes fra vipps </h2>
                            <img className='vippsLogoColor' src={VippsLogoColor} alt="Vipps" />
                        </div>
                    </div> */}
                {/* Så kommer det flere... */}

                {/* Glemt passord */}
                    {/* <div className='modalLogin__wrapper--content forgot-password-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Glemt passord </h2>

                            <p className='subtitle'>Her kommer det noe tekst om det med <br /> glemt passord</p>

                            <div className='loginMobile'>
                                <div className='input'>
                                    <label>Epost</label>
                                    <input type="name"/>
                                </div>

                                <div className='btnContinueWrapper'>
                                    <button  className='btn btn-primary lg'>Fortsett</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* Glemt passord */}

                {/* Glemt passord */}
                    {/* <div className='modalLogin__wrapper--content forgot-password-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Glemt passord </h2>

                            <p className='subtitle'>Hvis du ikke mottar passord innen få minutter, verifiser om du har tastet inn riktig e-post eller mobilnummer.</p>
                            <p className='subtitle'>E-postadressen/mobilnummeret må samsvare med det som er lagret på kontoen din.</p>

                            <div className='btnCloseWrapper'>
                                <button  className='btn btn-secondary lg'>Lukk vindu</button>
                            </div>
                     
                        </div>
                    </div> */}
                {/* Glemt passord */}
                
                {/* Bli kunde hos blomqvist */}
                    {/* <div className='modalLogin__wrapper--content register-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <button className='btnBackModal'><span className='icon-ArrowLeft'></span></button>

                        <div className='content'>
                            <h2>Bli kunde hos blomqvist </h2>      

                            <div className='btnVippsWrapper'>
                                <p>Registrer deg rask og enkelt med Vipps</p>
                                <button>Registrer deg med <img src={VippsLogo} alt="Vipps" /></button>
                            </div>

                            <div className='or'><span>eller </span></div>

                            <div className='btnRegisterWrapper'>
                                <button  className='btn btn-secondary lg'>Registrer deg med e-post</button>          
                            </div>
                            
                            <div className='linkLoginWrapper'>
                                <p>Har du allerede en konto?  <a href="$">Logg inn</a></p>
                            </div>
                        </div>
                    </div> */}
                {/* Bli kunde hos blomqvist */}

                {/* Registrer deg som kunde */}
                    {/* <div className='modalLogin__wrapper--content register-inputs--content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <button className='btnBackModal'><span className='icon-ArrowLeft'></span></button>

                        <div className='content'>
                            <h2>Registrer deg som kunde</h2>      

                            <div className='registerWrapper'>
                                <div className='input'>
                                    <label>Fornavn*</label>
                                    <input type="text"/>
                                </div>
                                <div className='input'>
                                    <label>Etternavn*</label>
                                    <input type="name"/>
                                </div>
                                <div className='input'>
                                    <label>E-post*</label>
                                    <input type="name"/>
                                </div>
                                <div className='input'>
                                    <label>Passord*</label>
                                    <input type="password"/>
                                </div>
                                <div className='input'>
                                    <label>Gjenta passord*</label>
                                    <input type="password"/>
                                </div>
                                <div className='termsWrapper'>
                                    <input type="checkbox" id="terms" name="terms" />
                                    <label htmlFor="terms" >Ved å opprette konto godtar du Blomqvist <br /> sine <a href="#">vilkår og betingelser</a>. </label>
                                </div>
                                <div className='btnRegisterWrapper'>
                                    <button  className='btn btn-primary lg'>Opprett konto</button>
                                </div>
                            </div>
                            <div className='linkLoginWrapper'>
                                <p>Har du allerede en konto?  <a href="$">Logg inn</a></p>
                            </div>
                        </div>   
                    </div> */}
                {/* Registrer deg som kunde */}

                {/* Gratulerer! */}                
                    {/* <div className='modalLogin__wrapper--content congratulations-register-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Gratulerer! <br />Du er nå registret som <br />bruker hos oss </h2>

                            <img className='okIcon' src={OkIcon} alt="" />

                            <div className='congratulationsText'>
                                <p>For å kunne by og kjøpe hos oss, må du fullføre registreringen på min side</p>
                            </div>

                            <div className='btnCongratulationsWrapper'>
                                <button  className='btn btn-secondary lg'>Gå til min side </button>
                                <a href="#">Lukk vindu</a>
                            </div>

                        </div>
                    </div> */}
                {/* Gratulerer! */}

                {/* Alternative text */}                
                    {/* <div className='modalLogin__wrapper--content logIn-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Logg inn for å gi bud </h2>      

                            <div className='btnVippsWrapper'>
                                <p>Logg inn raskt og enkelt med Vipps</p>
                                <button>Logg inn med <img src={VippsLogo} alt="Vipps" /></button>
                            </div>

                            <div className='or'><span>eller </span></div>

                            <form className='loginEmail'>
                                <div className='input'>
                                    <label>E-post*</label>
                                    
                                    <input type="text"/>
                                    <span className='errorMenssage'>* sjekk at dette feltet er rett fylt ut</span> 
                                </div>
                                <div className='input'>
                                    <label>Passord*</label>
                                   
                                    <input type="password"/>
                                    <span className='errorMenssage'>* sjekk at dette feltet er rett fylt ut</span>
                                </div>
                                <div className='btnLoginWrapper'>
                                    <button type='submit' className='btn btn-primary lg'>Logg inn</button>
                                    <a href="#">Glemt passord?</a>
                                </div>
                            </form>
                        </div>
                        <div className='become-customer'>
                            <span>Ny her? </span>
                            <a href="#">Bli kunde hos blomqvist</a>
                        </div>
                    </div> */}
                {/* Alternative text */}

                {/* Alternative text */}                
                    {/* <div className='modalLogin__wrapper--content alternative-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Gratulerer! <br />Du er nå registret som <br />bruker hos oss </h2>

                            <div className='alternativeText'>
                                <p>For å kunne by og kjøpe hos oss, må du fullføre registreringen på min side</p>
                            </div>

                            <div className='btnCongratulationsWrapper'>
                                <button  className='btn btn-secondary lg'>Gå til min side </button>
                                <a href="#">Lukk vindu</a>
                            </div>

                        </div>
                    </div>                 */}
                {/* Alternative text */}

            </div>

        </div>        
        </>
    )
}