import React from 'react';
import './App.scss';
import Routes from './pages/routes';

function App(): JSX.Element {
    return (
        <Routes/>
    );
}

export default App;
