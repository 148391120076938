import React from 'react'
import { Default } from '../../../../components/templates'
import './style.scss'

export default function ModalPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalPurchasesPayments active'> */}
        <div className='modalPurchasesPayments active'>
            <div className='modalPurchasesPayments__wrapper'>
                <div className='modalPurchasesPayments__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Fraktalternativer</h2>
                                <h3>Objekt.nr. xxxxxxx kan leveres på følgende måter:</h3>
                            </div>

                            <div className="optionsList">

                                <div className="group">

                                    <div className="option">
                                        <p>Hente selv på Fornebu (ingen kostnad)</p>
                                        <button>Bestill</button>
                                    </div>

                                    <div className="option">
                                        <p>Hentes på kontor i Bergen - 200 NOK</p>
                                        <button>Bestill</button>
                                    </div>
                                    
                                    <div className="option">
                                        <p>Sendes som Bring servicepakke - 250 NOK</p>
                                        <button>Bestill</button>
                                    </div>

                                    <div className="option">
                                        <p>
                                            Bring ekspress hjemlevering - 550 NOK
                                            <span>Ved levering til din leveringsadresse: Erlevein 14 0244 Asker</span>
                                        </p>
                                        <button>Bestill</button>
                                    </div>

                                </div>

                                <div className="group">
                                    <div className="option">
                                        <p>Frakt til utlandet </p>
                                        <button>Ta kontakt med oss her</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-gray sm'>Lukk vindu</button>
                    </div>

                </div>
            </div>              
        </div>    
        <></>
        
        </Default>        
    )
}