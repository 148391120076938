import React from 'react'
import ImageInstagram from '../../../assets/images/instagramImage.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import './style.scss'

export default function InstagramGallery(): JSX.Element{

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1420,
            settings: {         
                centerMode: true,       
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
            }
          },
          {
            breakpoint: 1200,
            settings: {
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
            }
          },
          {
            breakpoint: 992,
            settings: {
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
            }
          },
          {
            breakpoint: 575,
            settings: {
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
            }
          }
          
        ]
    };

    return(
        <>
        <div className='instagramGallery'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='instagramGallery__gallery'>
        
                            <Slider {...settings}>
                                <div className='item'>
                                    <div className='image'>
                                        <figure className='img aspectRatio--295x295'>
                                            <img src={ImageInstagram} alt="" />
                                        </figure>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <figure className='img aspectRatio--295x295'>
                                            <img src={ImageInstagram} alt="" />
                                        </figure>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <figure className='img aspectRatio--295x295'>
                                            <img src={ImageInstagram} alt="" />
                                        </figure>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <figure className='img aspectRatio--295x295'>
                                            <img src={ImageInstagram} alt="" />
                                        </figure>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='image'>
                                        <figure className='img aspectRatio--295x295'>
                                            <img src={ImageInstagram} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </Slider>

                        </div>

                        <a className="btn btn-secondary lg" href="#">Følg oss på instagram</a> 
                        
                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}