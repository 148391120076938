import React from 'react'
import { Default } from '../../../components/templates'
import {
    SavedSearchResult
} from '../../../components/modules'

export default function MyBids(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                            <h1>Lagrede søk</h1>
                            <p className='intro'>Søk på noe du har lyst på og trykk «Lagre søk». Da varsler Blomqvist deg når det dukker opp nye annonser.</p>                         
                        </div>
                    </div>
                </div>    
            </div>

            <SavedSearchResult/>

            <></>

        </Default>        
    )
}