import React from 'react'
import { Default } from '../../../../components/templates'
import Image from '../../../../assets/images/object333.png'
import SelectSearch from 'react-select-search'
import '../object-for-sale-1/style.scss'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span>Min side</a>
                            <h1>Navn på objekt</h1>                         
                        </div>
                    </div>
                </div>    
            </div>
           
            <div className="objectForSale">
                <div className="container">
                    <div className="row">

                        <div className="offset-lg-1 col-lg-4">
                            <div className="objectForSale__info">

                                <div className="first">
                                    <div className="line">
                                        <div className="label">Objektnummer</div>
                                        <div className="value">141151-1</div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Antall</div>
                                        <div className="value">1</div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Registrert</div>
                                        <div className="value">15.10.21 - 14:35</div>
                                    </div>

                                    <div className="line ">
                                        <div className="label">Kontrakt.nr.</div>
                                        <div className="value"><span className='undeline'>2418685</span></div>
                                    </div> 
                                    
                                    <div className="line">
                                        <div className="label">Kontrakt</div>
                                        <div className="value">Signert</div>
                                    </div>                                       

                                    <div className="line">
                                        <div className="label">Status</div>
                                        <div className="value"><span className='tag green'>solgt</span></div>
                                    </div>   

                                    <div className="line">
                                        <div className="label">Vurdering</div>
                                        <div className="value">500.000 - 600.000 NOK</div>
                                    </div>   

                                    <div className="line">
                                        <div className="label">Minstepris</div>
                                        <div className="value">500.000 NOK</div>
                                    </div>   

                                    <div className="line">
                                        <div className="label">Tilslag</div>
                                        <div className="value tilslag">700.000 NOK</div>
                                    </div>   

                                    <div className="line">
                                        <div className="label">Oppgjørsdato</div>
                                        <div className="value ">12.12.2021</div>
                                    </div> 

                                    <div className="line">
                                        <div className="label">Oppgjør</div>
                                        <div className="value "> 
                                            <div className='select'>
                                                <SelectSearch
                                                    options={[
                                                        { value: '659 300 NOK', name: '659 300 NOK'},
                                                        { value: '759 300 NOK', name: '759 300 NOK'} 
                                                    ]}
                                                />
                                            </div>                                        
                                        </div>
                                    </div> 

                                </div>                  

                           </div>
                        </div>

                        <div className="offset-lg-1 col-lg-5">
                           <div className="objectForSale__image">                               
                                <figure className="img aspectRatio--1x1">
                                    <img src={Image} alt="" />
                                </figure>                                                              
                            </div>
                           <div className="objectForSale__options">
                                <button className='btn-link'>Gå til produktside</button>
                           </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <></>

        </Default>        
    )
}