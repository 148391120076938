import React from 'react'
import { Default } from '../../../../components/templates'
import './style.scss'

export default function ModalChangeMinimumPrice(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalChangeMinimumPrice active'> */}
        <div className='modalChangeMinimumPrice active'>
            <div className='modalChangeMinimumPrice__wrapper'>
                <form className='modalChangeMinimumPrice__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Endre minstepris</h2>
                                <h3>Hjelpetekst her - Hvilke objekt gjelder dette for</h3>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="old">Gammel pris</label>
                                <input className='price' type="text" defaultValue="2 500 NOK" id="old"/>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="new">Ny pris</label>
                                <input className='price' type="text" defaultValue="1 500 NOK " id="new"/>
                            </div>                   

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-gray sm'>Avslutt</button>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>    
        <></>
        
        </Default>        
    )
}