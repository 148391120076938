import React from 'react'
import { Default } from '../../../../components/templates'
import {
    ExcellentList,
    PaymentsCompletedList
} from '../../../../components/modules'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                            <h1>Mine kjøp og betalinger</h1>                         
                        </div>
                    </div>
                </div>    
            </div>

            <ExcellentList/>
            <PaymentsCompletedList/>
             
            <></>

        </Default>        
    )
}