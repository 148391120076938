
import React from 'react'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';
import './style.scss'

export default function BtnProfile(): JSX.Element{

    function Template() {
        return (
            <div className='profile'>
                <a href="#">Logg inn på Min side</a>
            </div>
        );
      }

    return(
        <>
        <Tippy content={<Template />} allowHTML={true} animation='scale-subtle' interactive={true} interactiveBorder={20} delay={20}>                        
            <button className='UserBtn' aria-label="User">
                <span className="icon-User"></span>
                <span className='notification'>3</span>
            </button>
        </Tippy>        
        </>
    )
}