import React from 'react'
import { Default } from '../../../components/templates'
import { HeaderCategoryAuctions,
    PopularAuctions,
    ImageTextBlockFull,
    ImageTextBlock,
    ObjectsTabs,
    SubscribeNewsletter,
    InstagramGallery,
    WeSellIt,
    SpecialAuctionCarousel
} from '../../../components/modules'

export default function Auction(): JSX.Element{
    return(        
        <Default headerColor="background">
            <HeaderCategoryAuctions/>
            <ObjectsTabs onTabSelect={(tab:string)=>{console.log(tab)}}/>            
            <ImageTextBlock light/>
            <ImageTextBlock left light/>            
            <SpecialAuctionCarousel/>
            <WeSellIt/>
            <ImageTextBlock left/>
            <ImageTextBlock/>
            {/* <ImageTextBlockFull/> */}
            <SubscribeNewsletter/>
            <InstagramGallery/>
        </Default>        
    )
}