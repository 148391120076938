import { useState, useEffect } from 'react';
import Image1 from '../../../assets/images/excellentList1.png'
import SelectSearch from 'react-select-search'
import './style.scss'



export default function ExcellentList(): JSX.Element{

    const [options,setOptions] = useState(false)
    const toggleOptions = ()=>{
        setOptions(!options)
    }

    return(
        <>
        <div className="salesAndContractsList">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10">

                        <div className="salesAndContractsList__header">
                            <div className='tabs'>

                                <div className="desktop">
                                    <button className='item active'>Mine salgsobjekter (136)</button>
                                    <button className='item'>Mine kontrakter (20)</button>
                                </div>

                                <div className="mobile">
                                    <div className='select'>
                                        <SelectSearch
                                            options={[
                                                { value: 'Mine salgsobjekter (136)', name: 'Mine salgsobjekter (136)'},
                                                { value: 'Mine kontrakter (20)', name: 'Mine kontrakter (20)'} 
                                            ]}
                                            placeholder="Salgsobjekter"
                                            value={'Fornebu'}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className='options'>
                                <button><span className='icon-MagnifyingGlass'></span></button>                                    
                                <button><span className='icon-Filter'></span></button>
                            </div>
                        </div>


                        <div className="salesAndContractsList__list">

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>
                                        
                                        <div className="line showMobile">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag orange'>Signer</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag orange'>Signer kontrakt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag red'>Ikke solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag red'>Ikke solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags'><span className='tag orange'>Har vært ute 2 ganger. Ønsker du å sette ned minsteprisen?</span></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag red'>Ikke solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag red'>Ikke solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags'><span className='tag gray'>Kommer på auksjon 10/12-21</span></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag red'>Ikke solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag red'>Ikke solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Tilslag: <strong>1.500 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag green'>Solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag green'>Solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className="line">Tilslag: <strong>1.500 NOK</strong></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Tilslag: <strong>1.500 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag green'>Solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag green'>Solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className="line">Tilslag: <strong>1.500 NOK</strong></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Tilslag: <strong>1.500 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag green'>Solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag green'>Solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className="line">Tilslag: <strong>1.500 NOK</strong></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Fredrik A. Kayser</h3>
                                        <div className="line hideMobile">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line hideMobile">Kontrakt: <strong>2418685</strong></div>

                                        <div className="line showMobile">Tilslag: <strong>1.500 NOK</strong></div>
                                        <div className='tags showMobile'><span className='tag green'>Solgt</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag green'>Solgt</span></div>
                                        <div className="line">Vurdering: <strong>1.500 - 2.000 NOK</strong></div>
                                        <div className="line">Minstepris: <strong>1.000 NOK</strong></div>
                                        <div className="line">Tilslag: <strong>1.500 NOK</strong></div>
                                    </div>
                                </div>
                            </a>

                        </div>




                        {/* <div className="salesAndContractsList__list">

                            <a className="item" href='#'>
                                <div className="imageWrapper">
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>                                    
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Kontrakt: 2418685</h3>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line">Antall objekter: <strong>8</strong></div>
                                        
                                        <div className='tags showMobile'><span className='tag orange'>Ikke signert</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag orange'>Ikke signert kontrakt</span></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="imageWrapper">
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>                                  
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Kontrakt: 2418685</h3>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line">Antall objekter: <strong>3</strong></div>
                                        
                                        <div className='tags showMobile'><span className='tag orange'>Ikke signert</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag orange'>Ikke signert kontrakt</span></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>                             
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                             
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Kontrakt: 2418685</h3>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line">Antall objekter: <strong>1</strong></div>
                                        
                                        <div className='tags showMobile'><span className='tag orange'>Ikke signert</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag orange'>Ikke signert kontrakt</span></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="imageWrapper">
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>                             
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Kontrakt: 2418685</h3>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line">Antall objekter: <strong>2</strong></div>
                                        
                                        <div className='tags showMobile'><span className='tag green'>Signert</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag green'>Signert</span></div>
                                    </div>
                                </div>
                            </a>
                            
                            <a className="item" href='#'>
                                <div className="imageWrapper">
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>                             
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Kontrakt: 2418685</h3>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line">Antall objekter: <strong>2</strong></div>
                                        
                                        <div className='tags showMobile'><span className='tag green'>Signert</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag green'>Signert</span></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="imageWrapper">
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>                             
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Kontrakt: 2418685</h3>
                                        <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                        <div className="line">Antall objekter: <strong>2</strong></div>
                                        
                                        <div className='tags showMobile'><span className='tag green'>Signert</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag green'>Signert</span></div>
                                    </div>
                                </div>
                            </a>

                        </div> */}



                        <div className="salesAndContractsList__showMore">
                            <button className='btn btn-primary'>Vis flere treff</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>





















        </>
    )
}