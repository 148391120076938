import React from 'react'
import Visa from '../../../assets/images/visa.svg'
import Master from '../../../assets/images/mastercard.svg'
import './style.scss'

export default function ModalEditCard(): JSX.Element{
    return(
        <>
        {/* Add .active class to enable login modal: <div className='modalEditCard active'> */}
        <div className='modalEditCard active'>
            <div className='modalEditCard__wrapper'>
                <form className='modalEditCard__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Endre betalingskort</h2>
                            </div>

                            <div className='form-group'>
                                <label>Kort type</label>
                                <div className="flagOption">
                                    <input type="radio" id="html" name="fav_language" value="HTML"/>
                                    <label htmlFor="html"><img src={Visa} alt="" /></label>
                                    <input type="radio" id="css" name="fav_language" value="CSS"/>
                                    <label htmlFor="css"><img src={Master} alt="" /></label>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label className='iconCard' htmlFor="Kortnummer">Kortnummer</label>
                                <div className="iconInput">
                                    <span className='icon-CreditCard'></span>
                                    <input type="text" defaultValue="Kontonummer" id="Kortnummer"/>
                                </div>
                            </div>
                            
                            <div className='form-group'>
                                <label htmlFor="Utløpsdato">Utløpsdato MM / ÅÅ</label>
                                <div className="iconInput">
                                    <span className='icon-CalendarBlank'></span>
                                    <input type="tel" defaultValue="01.05.1996" id="Utløpsdato"/>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="cvc">Utløpsdato MM / ÅÅ</label>
                                <div className="iconInput">
                                    <span className='icon-Padlock'></span>
                                    <input type="tel" defaultValue="CVC" id="cvc"/>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>    
        

        {/* -------- Remove card -------- */}
        {/* <div className='modalEditCard active'>
            <div className='modalEditCard__wrapper'>
                <form className='modalEditCard__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Slette betalingskort</h2>
                                <p className="ingress">Er du sikker på at du vil slette dette kortet?</p>
                            </div>

                            <div className='form-group'>
                                <div className="iconInput">
                                    <span className='icon-CreditCard'></span>
                                    <input type="text" defaultValue="**** 3456" id="Kortnummer"/>
                                </div>
                            </div>
                        
                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-gray sm'>Avslutt</button>
                        <button className='btn btn-red sm'>Slett kort</button>
                    </div>

                </form>
            </div>              
        </div>    */}
        {/* -------- Remove card -------- */}

        </>
    )
}