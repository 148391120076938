import React from 'react'
import { EmptyPage } from '../../components/templates'
import './style.scss'

export default function Prototype(): JSX.Element{
    return(        
        <EmptyPage>
            <div className="container prototype">
                <div className="row">
                    <div className="col-12">
                        <header className="index-header mt-5">
                            <h3>Typograph</h3>
                        </header>
                    </div>

                    {/* Primary */}
                    <div className="col-md-12">
                        <small>Reckless Neue Book</small>
                        <br /><br />
                    </div>

                    <div className="col-md-12 titles">
                        <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h1><br />
                        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h2><br />
                        <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h3><br />
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h4><br />
                        <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h5><br />
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h6><br />
                    </div>

                    {/* Primary */}
                    <div className="col-md-12">
                        <br /><br />
                        <small>Inter</small>
                        <br /><br />
                    </div>

                    <div className="col-md-12 heading">
                        <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h1><br />
                        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h2><br />
                        <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h3><br />
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h4><br />
                        <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h5><br />
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h6><br />
                        <br /><br /><br /><br />
                    </div>


                </div>
            </div>

        </EmptyPage> 
    )
}