import React from 'react'
import { Default } from '../../components/templates'
import ImageObject from '../../assets/images/object-view-image.jpg'
import ImageObject2 from '../../assets/images/object-view-image2.jpg'
import ImageObject3 from '../../assets/images/object-view-image3.png'
import ImageObject4 from '../../assets/images/object-view-image4.png'
import Specialist from '../../assets/images/specialist.jpg'
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import './style.scss'

export default function ObjectView(): JSX.Element{

    const settingsCarousel = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1420,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 4,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 1200,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 3,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 890,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 2,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 575,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 1,
                slidesToScroll: 1
            }
          }
          
        ]
    };


    return(
        <Default headerColor="light"> 

            <div className='breadCrumbs'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='breadCrumbs__item'>Auksjoner</a>
                            <span className='icon-CaretRight'></span>
                            <a href="#" className='breadCrumbs__item'>Klassiske auksjoner</a>
                            <span className='icon-CaretRight'></span>
                            <a href="#" className='breadCrumbs__item'>Høydepunkter klassiskklassisk</a>
                            <span className='icon-CaretRight'></span>
                            <a href="#" className='breadCrumbs__item last'>Frans Widerberg</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='object'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='object__titleMobile'>
                                <span className='catalogNumber'>Katalognummer: 1</span>
                                <span className='preTitle'>Objektnr: 44374-1</span>
                                <h1 className='title'>Frans Widerberg</h1>
                                <h2 className='subtitle'>Rytter</h2>
                            </div>
                            <div className='object__image'>
                                <div className="featured">
                                    <span className="bookmark icon-Heart"></span>
                                    <figure className="img aspectRatio--1x1">
                                        <img src={ImageObject} alt="" />                                        
                                    </figure>
                                </div>
                                <div className='moreIMages'>
                                    <div className="image">
                                        <figure className="img aspectRatio--1x1">
                                            <img src={ImageObject2} alt="" />                                        
                                        </figure>
                                    </div>
                                    <div className="image">
                                        <figure className="img aspectRatio--1x1">
                                            <img src={ImageObject} alt="" />                                        
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='object__details'>

                                <div className='object__details--heading'>
                                    <span className='catalogNumber'>Katalognummer: 1</span>
                                    <span className='preTitle'>Objektnr: 44374-1</span>
                                    <h1 className='title'>Frans Widerberg</h1>
                                    <h2 className='subtitle'>Rytter</h2>
                                </div>

                                <div className='object__details--auction'>

                                    <div className='info'>
                                        <div className='item'>
                                            <div className='item-row'>
                                                <span>Auksjon<b>: </b></span>
                                                <strong>Desember auksjon 2021</strong>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <div className='item-row'>
                                                <span>Auksjonsdato<b>: </b></span>
                                                <strong className='date'>07.11.2021 Kl 19:00</strong>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <div className='item-row'>
                                                <span>Vurdering<b>: </b></span>
                                                <strong>10.000 - 12.000 NOK</strong>
                                            </div>
                                            <div className='item-row'>
                                                <small>100 - 125 EUR</small>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div className="callToAction">
                                        <button className='btn btn-primary md'>Registrer deg for live-bidding </button>
                                        <button className='btn btn-secondary md'>Registrer forhåndsbud</button>
                                        <button className='btn btn-secondary md'>Registrer telefonbud</button>
                                    </div>

                                    <div className='description'> 
                                        <div className="accordionDefault">
                                            <Accordion preExpanded={['item1']} allowMultipleExpanded>
                                                <AccordionItem uuid="item1">
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Beskrivelse <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p> Rytter av <a href="#">Frans Widerberg</a> (Oslo 1934 - 2007)</p>

                                                        <p>Olje på lerret, 76 x 59 cm <br />
                                                        Signert nede til høyre: Frans W</p>

                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                        <a href="#" className='showMore'>Les mer</a>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Ekspertens merknad <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore </p>

                                                        <a href="#" className='reportLink'>Bestill besiktigelsesrapport</a>

                                                        <div className="specialistBlock">
                                                            <div className='image'>
                                                                <figure>
                                                                    <img src={Specialist} alt="Specialist" />
                                                                </figure>
                                                            </div>
                                                            <div className='info'>
                                                                <div className="name">Lise Sofie Arntzen</div>
                                                                <div className="position">Professor. UiO</div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Lokasjon <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        content
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Om budgivning  <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>Her kan du lese mer om <a href="#">Live-bidding</a>, <a href="#">telefonbud</a>, <a href="#">forhåndsbude</a> og det å by <a href="#">fra salen</a></p>
                                                        <p>Her kan kommer det også en liten tekst om <a href="#">frakt og fraktalternativer</a> </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='carouselObjects'>                
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>

                            <div className='carouselObjects__carousel'>                               

                                <div className='carousel'>
                                    <h2>Andre objekter i denne auksjonen</h2>
                                    <Slider {...settingsCarousel}>
                              
                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>                                                                                                                                                                                                        
                                    
                                    </Slider>
                                </div>

                                <div className='carousel'>
                                    <h2>Sist sett på</h2>
                                    <Slider {...settingsCarousel}>
                                       
                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>                                                                                                                                                                                                        
                                     
                                    </Slider>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>                
            </div>

        </Default>       
    )
}