import React from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'
import {ModalLesMer
} from '../../../components/modules'

import '../style.scss'

export default function modalLesMer(): JSX.Element{
    return(        
        <EmptyPage>

            <ModalLesMer/>
  
        </EmptyPage> 
    )
}