import React from 'react'
import { EmptyPage } from '../../components/templates'
import VippsLogo from '../../assets/images/vipps-logo.svg'
import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>
                <div className='modalLogin__wrapper--content logIn-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Logg inn </h2>      

                            <div className='btnVippsWrapper'>
                                <p>Logg inn raskt og enkelt med Vipps</p>
                                <button>Logg inn med <img src={VippsLogo} alt="Vipps" /></button>
                            </div>

                            <div className='or'><span>eller </span></div>

                            <form className='loginEmail'>
                                <div className='input'>
                                    <label>E-post*</label>
                                    {/* Add the ".error" class to the <input> to apply the style. Such that: <input className='error' type="text"/>  */}
                                    <input type="text"/>
                                    <span className='errorMenssage'>* sjekk at dette feltet er rett fylt ut</span> 
                                </div>
                                <div className='input'>
                                    <label>Passord*</label>
                                    {/* Add the ".error" class to the <input> to apply the style. Such that: <input className='error' type="text"/>  */}
                                    <input type="password"/>
                                    <span className='errorMenssage'>* sjekk at dette feltet er rett fylt ut</span>
                                </div>
                                <div className='btnLoginWrapper'>
                                    <button type='submit' className='btn btn-primary lg'>Logg inn</button>
                                    <a href="#">Glemt passord?</a>
                                </div>
                            </form>
                        </div>
                        <div className='become-customer'>
                            <span>Ny her? </span>
                            <a href="#">Bli kunde hos blomqvist</a>
                        </div>
                    </div>
                </div>              
            </div>
  
        </EmptyPage> 
    )
}