import React from 'react'
import { Default } from '../../components/templates'
import './style.scss'

export default function PageList(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className="searchResult">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8">

                            <div className="searchResult__title">
                                <h1>Søkeresultat</h1>               
                            </div>

                            <div className="searchResult__input">
                                <form className='input'>
                                    <span className="icon-MagnifyingGlass"></span>
                                    <input type="text" placeholder='Ditt søk her'/>
                                    <button type="submit" value="Søk">Søk</button>
                                </form>     
                            </div>

                            <div className="searchResult__result">

                                <div className="lists">

                                    <div className="list">
                                        <div className="title"><h2>Artikler / sider</h2></div>
                                        <div className="items">
                                            <div className="item">
                                                <span className="text">Hvordan selge</span>
                                                <span className="total"><a href="#">Slik selger du på auksjon</a></span>
                                            </div>
                                            <div className="item">
                                                <span className="text">Hvordan selge</span>
                                                <span className="total"><a href="#">Hvorfor velge Blomqvist</a></span>
                                            </div>
                                            <div className="item">
                                                <span className="text">Hvordan selge</span>
                                                <span className="total"><a href="#">Dette kan du selge hos oss</a></span>
                                            </div>
                                            <div className="item">
                                                <span className="text">Tema</span>
                                                <span className="total"><a href="#">Våre beste salg i 2020</a></span>
                                            </div>
                                        </div>
                                    </div>
        
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <></>
        </Default>
    )
}