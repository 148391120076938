import react from 'react';
import Image1 from '../../../assets/images/excellentList1.png'
import Image2 from '../../../assets/images/excellentList2.png'
import Image3 from '../../../assets/images/excellentList3.png'
import './style.scss'



export default function ObjectForPayment(): JSX.Element{

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10"> 
                    <div className="objectForPayment">
                        <div className="objectForPayment__header">
                            <div className='info'>
                                <span className='title'>Til betaling <span>(2.500 NOK)</span></span>                                
                            </div>
                            <div className='options'>
                                <button className='btn-link'>Avslutt</button>
                            </div>
                        </div>
                        <div className="objectForPayment__list">

                            <div className="item">
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className="name">Navn på objekt</h3>
                                        <div className="delivery desktop">Utlevering: <strong>Hentes på Fornebu</strong></div>
                                    </div>
                                    <div className="payment">
                                        <div className="value">Totalt 2.500 NOK</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>   
        </>
    )
}