import React from 'react'
import { Default } from '../../../components/templates'

export default function ModalEditProfile(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalEditProfile active'> */}
        <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Endre personalia</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="name">Navn</label>
                                {/* Add className='error' to the <input> to apply the style */}
                                <input type="text" defaultValue="Elisabeth" id="name"/>
                                <span className='errorMessage'>* sjekk at dette feltet er rett fylt ut</span> 
                            </div>

                            <div className='form-group'>
                                <label htmlFor="last-name">Etterna vn</label>
                                <input type="text" defaultValue="Henriksen" id="last-name"/>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="birth">Født</label>
                                <input type="text" defaultValue="01.05.1996" id="birth"/>
                            </div>                            

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>    
        <></>
        
        </Default>        
    )
}