import { useState, useEffect, useRef } from 'react'
import { EmptyPage } from '../../../components/templates'
import Avatar from '../../../assets/images/avatar-image.jpg'
import '../../../components/modules/modal-filter/style.scss'
import '../style.scss'

export default function ModalObjectView(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalObjectView active'>
                <div className='modalObjectView__wrapper'>
                    <div className='modalObjectView__wrapper--content'>

                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                        
                        <div className='content mbMobile0'>
                            <div className='wrapper lg'>

                                <div className='title'>
                                    <h2>Lise Sofie Arntzen</h2>
                                </div>

                                <div className="contactBox">
                                    <div className="image">
                                        <figure className='img aspectRatio--1x1'>
                                            <img src={Avatar} alt="" />
                                        </figure>
                                    </div>
                                    <div className="content">
                                        <div className="text">
                                            <h3 className='title'>Professor i kunsthistorie UiO</h3>
                                            <p className='ingress'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum dictum maecenas ligula lectus sit. Turpis tristique dui vitae integer. Leo.</p>
                                            <div className="list">
                                                <h3>Dette kan du spørre lise om</h3>
                                                <ul>
                                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='ctaWrapper'>
                                            <button className='btn btn-primary sm'>Kontakt Lise</button>
                                        </div>
                                        <a href="#" className='seeMore'>Se flere av våre eksperter</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}