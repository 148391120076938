import React from 'react'
import { Default } from '../../../../components/templates'
import Image11 from '../../../../assets/images/excellentList11.png'

export default function MyPurchasesPaymentsObject(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions objectPage'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                            <h1>Fredrik A. Kayser</h1>                         
                        </div>
                    </div>
                </div>    
            </div>

            <div className="myPurchasesPaymentsObject">
                <div className="container">
                    <div className="row">

                        <div className="offset-lg-1 col-lg-4">
                            <div className="myPurchasesPaymentsObject__info">

                                <div className="first">
                                    <div className="line">
                                        <div className="label">Fredrik A. Kayser</div>
                                        <div className="value">2.300 NOK </div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Omkostninger<span className='icon-Info'></span></div>
                                        <div className="value">200 NOK</div>
                                    </div>

                                    <div className="line paidOut">
                                        <div className="label">Totalt utestående</div>
                                        <div className="value red">2.500 NOK</div>
                                    </div>   

                                    <div className="infoBox">
                                        <div className="icon">
                                            <span className='icon-CreditCard'></span>
                                            <span className='text'>Betaling</span>
                                        </div>
                                        <div className="text">
                                            <p>Autotrekk fra Visa **** 4385.</p>
                                            <p><a href="#">Endre betalingsmetode her</a></p>
                                        </div>
                                    </div>

                                </div>

                                <div className="last">
                                    <div className="line">
                                        <div className="label">Utlevering<span className='icon-Info'></span></div>
                                        <div className="value">Varen kan hentes på Fornebu</div>
                                    </div>     

                                    <div className="infoBox">
                                        <div className="icon">
                                            <span className='icon-Truck'></span>
                                            <span className='text'>Frakt</span>
                                        </div>
                                        <div className="text">
                                            <p>Ønsker du må bestille frakt til dette produktet? <a href="#"> Se fraktmuligheter her</a></p>
                                        </div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Referanse.nr.:</div>
                                        <div className="value">2252369854744</div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Dato for kjøp</div>
                                        <div className="value">10.05.21</div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Betalt dato</div>
                                        <div className="value">--.--.---</div>
                                    </div>

                                    <div className="line">
                                        <div className="label">Betalingsmetode</div>
                                        <div className="value">----</div>
                                    </div>

                                    <div className="line">
                                        <a href="#" className='pdfDownload'>Last ned som PDF </a>
                                    </div>                                    
                                </div>

                           </div>
                        </div>

                        <div className="offset-lg-1 col-lg-5">
                           <div className="myPurchasesPaymentsObject__image">
                                <figure className="img aspectRatio--1x1">
                                    <img src={Image11} alt="" />
                                </figure>
                           </div>
                           <div className="myPurchasesPaymentsObject__options">
                                <button className='btn-link'>Gå til produktside</button>
                                <button className='btn-link'>Se budlisten</button>
                           </div>
                        </div>

                    </div>
                </div>
            </div>


             
            <></>

        </Default>        
    )
}