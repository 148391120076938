import React from 'react'
import { Default } from '../../../components/templates'
import {
    ImportantNotification,
    MyBinds,
    MyFavorite,
    MyPageOptions
} from '../../../components/modules'
import './style.scss'

export default function MainPage(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='myPage'>

                <div className='myPage__header'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h1>Min side</h1>                         
                            </div>
                        </div>
                    </div>    
                </div>

                <div className='myPage__info'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='myPage__info--wrapper'>
                                    <div className='columnLeft'>
                                        <div className='name'>Hei, Elisabeth!</div>
                                        <div className='email'>henrelis@gmail.com</div>
                                    </div>
                                    <div className='columnRight'>
                                        <button type="button"><span className='notification'>3</span><span className="icon-Bell"></span>Varslinger</button>
                                        <button type="button"><span className="icon-SignOut"></span>Logg ut</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>


                <MyBinds/>
                
                <MyFavorite/>

                <MyPageOptions/>

            </div>   
            <></>

        </Default>        
    )
}