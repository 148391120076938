import React from 'react'
import { EmptyPage } from '../../components/templates'
import VippsLogo from '../../assets/images/vipps-logo.svg'
import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>
                
                    <div className='modalLogin__wrapper--content register-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <button className='btnBackModal'><span className='icon-ArrowLeft'></span></button>

                        <div className='content'>
                            <h2>Bli kunde hos blomqvist </h2>      

                            <div className='btnVippsWrapper'>
                                <p>Registrer deg rask og enkelt med Vipps</p>
                                <button>Registrer deg med <img src={VippsLogo} alt="Vipps" /></button>
                            </div>

                            <div className='or'><span>eller </span></div>

                            <div className='btnRegisterWrapper'>
                                <button  className='btn btn-secondary lg'>Registrer deg med e-post</button>          
                            </div>
                            
                            <div className='linkLoginWrapper'>
                                <p>Har du allerede en konto?  <a href="$">Logg inn</a></p>
                            </div>
                        </div>
                    </div>

                </div>              
            </div>
  
        </EmptyPage> 
    )
}