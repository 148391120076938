import React from 'react'
import { Default } from '../../../components/templates'
import Visa from '../../../assets/images/visa.svg'
import Master from '../../../assets/images/mastercard.svg'
import './style.scss'

export default function MyCards(): JSX.Element{
    return(        
        <Default headerColor="light">
           
            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                            <h1>Mine betalingskort</h1>                         
                        </div>
                    </div>
                </div>    
            </div>

            <div className='cardWrapper'>

                <div className="container">

                    <div className="row">
                        <div className="offset-lg-1 col-lg-5">                            
                            <div className='card'>                                
                                <div className="content">
                                    <div className="line header">
                                        <img className="flag" src={Visa} alt="" />
                                        <div className="switch">       
                                            <span className='label'>Aktivt</span>                                  
                                            <label className="switchButton" aria-label="Switch">
                                                <input type="checkbox" className="switchButton-input" defaultChecked/>
                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                <span className="switchButton-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="line">
                                        <span className='icon-CreditCard'></span>**** 3456
                                    </div>
                                    <div className="line">
                                        <span className='icon-CalendarBlank'></span>12 / 22
                                    </div>
                                    <div className="line">
                                        <span className='icon-Padlock'></span>***
                                    </div>                                    
                                </div>
                                <div className="option">
                                    <button>Endre</button>
                                    <button>Slette</button>
                                </div>  
                            </div>                                                                            
                        </div>

                        <div className="col-lg-5">                            
                            <div className='card'>
                                <div className="content">
                                    <div className="line header">
                                        <img className="flag" src={Master} alt="" />
                                        <div className="switch">       
                                            <span className='label'>Inaktivt</span>                                  
                                            <label className="switchButton" aria-label="Switch">
                                                <input type="checkbox" className="switchButton-input"/>
                                                <span className="switchButton-label" data-on="On" data-off="Off"></span>
                                                <span className="switchButton-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="line">
                                        <span className='icon-CreditCard'></span>**** 3456
                                    </div>
                                    <div className="line">
                                        <span className='icon-CalendarBlank'></span>12 / 22
                                    </div>
                                    <div className="line">
                                        <span className='icon-Padlock'></span>***
                                    </div>                                    
                                </div>
                                <div className="option">
                                    <button>Endre</button>
                                    <button>Slette</button>
                                </div>   
                            </div>                                                                          
                        </div>
                    </div>

                    <div className="row">
                        <div className="offset-lg-1 col-lg-5">
                            <div className="addCard">
                                <button>
                                    <span className='icon-CreditCard'></span>
                                    <span className="text">Legg til nytt betalingskort</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <></>

        </Default>        
    )
}