import React from 'react'
import { Default } from '../../components/templates'
import {SearchModal } from '../../components/modules'
import './style.scss'

export default function PageList(): JSX.Element{
    return(        
        <Default headerColor="light">
{/* Add .active class to enable login modal: <div className='searchModal active'> */}
<div className='searchModal active'>

<div className='searchModal__wrapper'>

    <div className='searchModal__wrapper--content'>
        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
        <div className='content'>
            <h2>Søk</h2>      

            <div className="input">
                <form className='input'>
                    <span className="icon-MagnifyingGlass"></span>
                    <input type="text" placeholder='Søk etter...'/>
                    <button type="submit" value="Søk">Søk</button>
                </form>     
            </div>   

            <div className="suggestedResearch">

                <div className="title">
                    <h3>Forslag til søkefraser</h3>
                </div>
                <div className="list">
                    <div className="item">
                        <a href="#">Klein</a>
                    </div>
                    <div className="item">
                        <a href="#">Klimt</a>
                    </div>
                    <div className="item">
                        <a href="#">Klokke</a>
                    </div>
                </div>

            </div>

          

        </div>
    </div>

</div>

</div>              
            <></>
        </Default>
    )
}