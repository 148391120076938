import React from 'react'
import { Default } from '../../../../components/templates'
import Visa from '../../../../assets/images/visa.svg'
import Master from '../../../../assets/images/mastercard.svg'
import './style.scss'

export default function ModalForMultipleObjects(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalForMultipleObjects active'> */}
        <div className='modalForMultipleObjects active'>
            <div className='modalForMultipleObjects__wrapper'>
                <form className='modalForMultipleObjects__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Fullfør betaling</h2>
                            </div>

                            <div className='form-group'>
                                <label>Kort type</label>
                                <div className="flagOption">
                                    <input type="radio" id="html" name="fav_language" value="HTML"/>
                                    <label htmlFor="html"><img src={Visa} alt="" /></label>
                                    <input type="radio" id="css" name="fav_language" value="CSS"/>
                                    <label htmlFor="css"><img src={Master} alt="" /></label>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label className='iconCard' htmlFor="Kortnummer">Kortnummer</label>
                                <div className="iconInput">
                                    <span className='icon-CreditCard'></span>
                                    <input type="text" defaultValue="Kontonummer" id="Kortnummer"/>
                                </div>
                            </div>
                            
                            <div className='form-group'>
                                <label htmlFor="Utløpsdato">Utløpsdato MM / ÅÅÅÅ</label>
                                <div className="iconInput">
                                    <span className='icon-CalendarBlank'></span>
                                    <input type="tel" defaultValue="-- / ----" id="Utløpsdato"/>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="cvc">UVertefiserings </label>
                                <div className="iconInput">
                                    <span className='icon-Padlock'></span>
                                    <input type="tel" defaultValue="CVC" id="cvc"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <div className="priceWrapper">
                            <div className='value'>110.000 NOK</div>
                        </div>
                        <div className='btns'>
                            <button className='btn btn-gray sm'>Avslutt</button>
                            <button className='btn btn-primary sm'>Fullfør betaling</button>
                        </div>
                    </div>

                </form>
            </div>              
        </div>     
        <></>
        
        </Default>        
    )
}