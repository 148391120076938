import React from 'react'
import ImageimageTextBlockAuction from '../../../assets/images/classicImage.png'
import './style.scss'

type ImageTextBlockAuctionProps = {
    left?: boolean,
    light?: boolean
}

export default function ImageTextBlockAuction({left=false,light=false}:ImageTextBlockAuctionProps): JSX.Element{

    return(
        <>
        <div className={`imageTextBlockAuction ${left ? 'imageTextBlockAuction-left' : 'imageTextBlockAuction-right'} ${light ? 'light' : ''}`}>
            <div className='container'>
                <div className='row'>                    
                    <div className='col-lg-12'>

                        <div className='imageTextBlockAuction__content'>

                            <div className='content'>
                                <h2>Høydepunkter klassisk auksjon</h2>
                                <div className='mobileImage'>
                                    <figure className='img aspectRatio--657x454'>
                                        <img src={ImageimageTextBlockAuction} alt="" />
                                    </figure>
                                </div>
                                <p>Tirsdag 14. desember 2021 kl. 19.00</p>
                                <a className="btn btn-secondary lg" href="#">Les mer og se katalog</a>   
                            </div>

                            <div className='image'>
                                <figure className='img aspectRatio--657x454'>
                                    <img src={ImageimageTextBlockAuction} alt="" />
                                </figure>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}