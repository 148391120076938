import React,{useState} from 'react'
import './style.scss'

export default function SubscribeNewsletter(): JSX.Element{

    return(
        <>
        <div className='subscribeNewsletter'>
            
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='subscribeNewsletter__content'>
                            <h2>Holde deg oppdater på alt som skjer hos Blomqvist!</h2>
                            <a href='#' className='btn btn-secondary'>Meld deg på nyhetsbrev</a>                            
                        </div>                        
                    </div>
                </div>
            </div>
        
        </div>        
        </>
    )
}
