import { useState, useEffect, useRef } from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'
import '../style.scss'

export default function ModalObjectView(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalObjectView active'>
                <div className='modalObjectView__wrapper'>
                    <div className='modalObjectView__wrapper--content'>

                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                        
                        <div className='content'>
                            <div className='wrapper lg'>

                                <div className='title'>
                                    <h2>Bring servicepakke </h2>
                                    <h3>For objekt.nr. xxxxx - navn på objekt</h3>
                                    <p className='ingress'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin venenatis nunc eu ultrices vestibulum.</p>
                                </div>

                                <div className="deliveryAddress">
                                    <button className='editBtn'><span className='icon-Pencil'></span></button>
                                    <h3 className='title'>Leveringsadresse</h3>
                                    <div className="item">Elisabeth Henriksen</div>
                                    <div className="item">E-post: henrelis@gmail.com</div>
                                    <div className="item">Mobil: 92081307</div>
                                </div>
                                
                                <div className="totalBox">
                                    <div className="list">
                                        <div className="item">
                                            <div className="text">Bring servicepakke </div>
                                            <div className="value">250 NOK</div>
                                        </div>
                                        <div className="item">
                                            <div className="text">Påløpt lagerleie</div>
                                            <div className="value">250 NOK</div>
                                        </div>
                                    </div>
                                    <div className="totalValue">
                                        <div className="text">Totalt</div>
                                        <div className="value">450 NOK</div>
                                    </div>                                    
                                </div>

                                <div className="terms">
                                    <input type="checkbox" id="terms" name="terms" />
                                    <label htmlFor="terms">Jeg godtar Blomqvist sine <a href="#">vilkår og betingelser</a> for budgivning.</label>
                                </div>

                            </div>
                        </div>

                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Avslutt</button>
                            <button className='btn btn-primary sm'>Gå til betaling</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}