import React from 'react'
import { Default } from '../../../components/templates'
import Image from '../../../assets/images/sell.png'
import { BreadCrumbs } from '../../../components/primitives'

import './style.scss'

export default function TermsPrivacy(): JSX.Element{
    return(        
        <Default headerColor="light">
            <></>               
            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                            <h1>Vilkår og personvern</h1>                         
                        </div>
                    </div>
                </div>    
            </div>
            
            <div className="termsPrivace">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10">

                            <div className="termsPrivace__section">
                                <h2 className='title'>Kjøpsbetingelser</h2>

                                <div className="items">
                                    
                                    <div className="item">
                                        <div className="content">
                                            <h3 className='title'>Betingelser for vinauksjon</h3>
                                            <div className='text'><p>Et objekt kan hentes og betales i Blomqvists ordinære åpningstider i Tordenskiolds gate 5 i Oslo. Legitimasjon må fremvises. Objektene skal hentes innen 14 dager etter auksjon. Du kan betale med kredittkort (Visa og Mastercard) eller via bankoverføring...</p></div>
                                            <button className='btn btn-primary sm'>Se betingelser og godkjenn</button>
                                        </div>
                                        <button className='buttonCheck'><span className='icon-Check'></span></button>
                                    </div>

                                    <div className="item">
                                        <div className="content">
                                            <h3 className='title'>Betingelser for vinauksjon</h3>
                                            <div className='text'><p>Et objekt kan hentes og betales i Blomqvists ordinære åpningstider i Tordenskiolds gate 5 i Oslo. Legitimasjon må fremvises. Objektene skal hentes innen 14 dager etter auksjon. Du kan betale med kredittkort (Visa og Mastercard) eller via bankoverføring...</p></div>
                                            <button className='btn btn-secondary sm'>Se betingelser</button>
                                        </div>
                                        <button className='buttonCheck active'><span className='icon-Check'></span></button>
                                    </div>

                                    <div className="item">
                                        <div className="content">
                                            <h3 className='title'>Betingelser for klassisk auksjon</h3>
                                            <div className='text'><p>Et objekt kan hentes og betales i Blomqvists ordinære åpningstider i Tordenskiolds gate 5 i Oslo. Legitimasjon må fremvises. Objektene skal hentes innen 14 dager etter auksjon. Du kan betale med kredittkort (Visa og Mastercard) eller via bankoverføring...</p></div>
                                            <button className='btn btn-secondary sm'>Se betingelser</button>
                                        </div>
                                        <button className='buttonCheck active'><span className='icon-Check'></span></button>
                                    </div>

                                </div>                                
                            </div>

                            <div className="termsPrivace__section">
                                <h2 className='title'>Personvern</h2>

                                <div className="items">
                                    
                                    <div className="item">
                                        <div className="content">
                                            <h3 className='title'>Overskrift</h3>
                                            <div className='text'><p>Et objekt kan hentes og betales i Blomqvists ordinære åpningstider i Tordenskiolds gate 5 i Oslo. Legitimasjon må fremvises. Objektene skal hentes innen 14 dager etter auksjon. Du kan betale med kredittkort (Visa og Mastercard) eller via bankoverføring...</p></div>
                                            <button className='btn btn-primary sm'>Se betingelser og godkjenn</button>
                                        </div>
                                        <button className='buttonCheck'><span className='icon-Check'></span></button>
                                    </div>

                                    <div className="item">
                                        <div className="content">
                                            <h3 className='title'>Overskrift</h3>
                                            <div className='text'><p>Et objekt kan hentes og betales i Blomqvists ordinære åpningstider i Tordenskiolds gate 5 i Oslo. Legitimasjon må fremvises. Objektene skal hentes innen 14 dager etter auksjon. Du kan betale med kredittkort (Visa og Mastercard) eller via bankoverføring...</p></div>
                                            <button className='btn btn-secondary sm'>Se betingelser</button>
                                        </div>
                                        <button className='buttonCheck active'><span className='icon-Check'></span></button>
                                    </div>

                                </div>                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </Default>        
    )
}