import React from 'react'
import { EmptyPage } from '../../components/templates'
import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>
                
                    <div className='modalLogin__wrapper--content forgot-password-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Glemt passord </h2>

                            <p className='subtitle'>Hvis du ikke mottar passord innen få minutter, verifiser om du har tastet inn riktig e-post eller mobilnummer.</p>
                            <p className='subtitle'>E-postadressen/mobilnummeret må samsvare med det som er lagret på kontoen din.</p>

                            <div className='btnCloseWrapper'>
                                <button  className='btn btn-secondary lg'>Lukk vindu</button>
                            </div>
                     
                        </div>
                    </div>                

                </div>              
            </div>
  
        </EmptyPage> 
    )
}