import React,{useState} from 'react'
import { Default } from '../../../components/templates'
import CategoryImage from '../../../assets/images/category-image.png'
import {PageListFilter} from '../../../components/modules'
import { BreadCrumbs } from '../../../components/primitives'
import './style.scss'

export default function PageList(): JSX.Element{

    const [mobileContentFeatured,setmobileContentFeatured] = useState(false)
    const [hideBtn,sethideBtn] = useState(false)
    
    const toggle = ()=>{
        setmobileContentFeatured(!mobileContentFeatured)
        sethideBtn(!hideBtn)      
    }

    return(        
        <Default headerColor="background">
            <div className='page-list-heading background'>
                <BreadCrumbs/>  
                <div className='page-list-heading__infoCategory'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                
                                <div className='info featured'>
                                    <h2>Høydepunkter - klassisk auksjon</h2>

                                    <div className='date'>
                                        <span>Tirsdag 14. desember kl. 19.00</span>
                                    </div>

                                    <figure className='img aspectRatio--624x441 imageMobile'>                                    
                                        <img src={CategoryImage} alt="" />
                                    </figure>     

                                    <button className="btn btn-secondary md btnCatalog">Se katalog</button>

                                    <div className='catalogLink'>
                                        <a href="#">Få print versjon av katalogen tilsendt</a>
                                    </div>                                    

                                    <div className='description'>
                                        <h3>Velkommen til årets siste klassiske auksjon!</h3>
                                        
                                        <p>Med et fantastisk utvalgt kunstverk fra norske og internasjonale mestre avrunder Blomqvist året 2021 med den klassiske auksjonen Høydepunkter. Etterspørselen og budgleden har økt, dette gjør også at kunstverk av høyeste kvalitet blir tilgjengelig for markedet, som vi kan se av denne katalogen.</p>
                                        
                                        <div className={"hideShowMore " + `${mobileContentFeatured?'active':''}`}>
                                            <p>Vi ønsker velkommen til førvisning av et utvalg kunstverk fra auksjon i Bergen 24. - 27. november, kl. 11 - 17 hver dag i Neumanns gate 30. <br />
                                            Fornebu, og er åpen til og med 13. desember</p>

                                            <p><strong>Åpningstider</strong></p>

                                            <p>
                                                <strong>Man</strong> - tors: 11-18<br />
                                                <strong>Fre:</strong> 11-15<br />
                                                <strong>Lør:</strong> 10 - 15<br />
                                                <strong>Søn:</strong> 12 - 16<br />
                                            </p>

                                            <p>Privat visning etter avtale er også mulig. </p>
                                        </div>                                        
                                    </div>

                                </div>
                                <div className={"btnShowMoreFeatured " + `${hideBtn?'hide':''}`} >
                                    <button onClick={toggle}>Les mer</button>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='image featured'>
                                    <figure className='img aspectRatio--655x441'>                                    
                                        <img src={CategoryImage} alt="" />
                                        <div className='caption'>Innlevering pågår</div>
                                    </figure>
                                </div>
                                <div className='description hideShowMore'>
                                    <p>For spørsmål om auksjonen eller kunstverkene, ta gjerne kontakt med en av våre eksperter:</p>
                                    <p>
                                        Elisabeth Vik Forsberg: <strong>92 88 43 62</strong> <br />
                                        Birgitte Christin Schiøth: <strong>91 33 51 55</strong> <br />
                                        Gunnar Krogh-Hansen: <strong>40 87 99 59</strong> <br />
                                        Anders Elvestad: <strong>45 93 92 17</strong> <br />
                                        Knut Forsberg: <strong>95 16 06 60</strong>
                                    </p>
                                </div>         
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
            <PageListFilter/>
        </Default>        
    )
}