import React from 'react'
import { Default } from '../../components/templates'
import Image from '../../assets/images/sell.png'
import { BreadCrumbs } from '../../components/primitives'
import { 
    ImageTextBlock,
    ImageTextBlockFull,
    SubscribeNewsletter,
    InstagramGallery,
    WeSellIt,
    CommonQuestions,
    InfoCard
} from '../../components/modules'
import './style.scss'

export default function PageList(): JSX.Element{
    return(        
        <Default headerColor="background">
            <></>               
            <div className='page-list-heading background'>
                <BreadCrumbs/> 
                <div className='page-list-heading__infoCategory'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='wrapper'>
                                    <div className='info'>
                                        <h2>Om Blomqvist</h2>
                                        <figure className='img aspectRatio--655x441 imageMobile'>                                    
                                            <img src={Image} alt="" />
                                        </figure>
                                        <div className='description'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium. Amet nunc amet libero pretium.</p>
                                        </div>
                                    </div>
                                    <div className='image'>
                                        <figure className='img aspectRatio--655x441'>                                    
                                            <img src={Image} alt="" />
                                        </figure>
                                    </div>                                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            
            <div className="about-blomqvist">
                <ImageTextBlock left/> 
                <ImageTextBlock/>
                <ImageTextBlock left/> 
                <ImageTextBlock left/> 
                <ImageTextBlock/>
                <ImageTextBlock left/> 
                <SubscribeNewsletter/>
                <InstagramGallery/>
            </div>


        </Default>        
    )
}