import React from 'react'
import { EmptyPage } from '../../../components/templates'
import VippsLogo from '../../../assets/images/vipps-logo.svg'
import '../../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='newsletterSubscribe active'>
                <div className='newsletterSubscribe__wrapper'>
                    
                    <div className='newsletterSubscribe__wrapper--content logIn-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Meld deg på vårt nyhetsbrev</h2>    
                            <p className='subtitle'>Ved å abonnere på nyhetsbrevet får du jevnlig aktuelle nyheter fra oss.</p>  
                            <p className='intro'>Du kan når som helst melde deg av. Mer informasjon om hvordan vi behandler dine opplysninger finner du i vår <a href="#" target="_blank">personvernerklæring</a>.</p>

                            <form className='loginEmail'>

                                <div className='input'>
                                    <label>Fornavn</label>
                                    {/* Add the ".error" class to the <input> to apply the style. Such that: <input className='error' type="text"/>  */}
                                    <input type="text" placeholder='Fornavn'/>
                                    <span className='errorMenssage'>sjekk at dette feltet er rett fylt ut</span>
                                </div>

                                <div className='input'>
                                    <label>Etternavn</label>
                                    {/* Add the ".error" class to the <input> to apply the style. Such that: <input className='error' type="text"/>  */}
                                    <input type="text" placeholder='Etternavn'/>
                                    <span className='errorMenssage'>sjekk at dette feltet er rett fylt ut</span>
                                </div>

                                <div className='input'>
                                    <label>E-post</label>
                                    {/* Add the ".error" class to the <input> to apply the style. Such that: <input className='error' type="text"/>  */}
                                    <input type="text" placeholder='E-post'/>
                                    <span className='errorMenssage'>sjekk at dette feltet er rett fylt ut</span> 
                                </div>

                                <div className='btnSubscribleWrapper'>
                                    <button type='submit' className='btn btn-primary sm'>Lagre endringer</button>
                                </div>

                            </form>

                        </div>
                        <div className='become-customer'>
                            <span>Ny her? </span>
                            <a href="#">Bli kunde hos blomqvist</a>
                        </div>
                    </div>
                    
                </div>              
            </div>
  
        </EmptyPage> 
    )
}