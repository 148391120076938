import React from 'react'
import { Default } from '../../../components/templates'

export default function ModalEditCard(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalEditCard active'> */}
        <div className='modalEditCard active'>
            <div className='modalEditCard__wrapper'>
                <form className='modalEditCard__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Slette betalingskort</h2>
                                <p className="ingress">Er du sikker på at du vil slette dette kortet?</p>
                            </div>

                            <div className='form-group'>
                                <div className="iconInput">
                                    <span className='icon-CreditCard'></span>
                                    <input type="text" defaultValue="**** 3456" id="Kortnummer"/>
                                </div>
                            </div>
                        
                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-gray sm'>Avslutt</button>
                        <button className='btn btn-red sm'>Slett kort</button>
                    </div>

                </form>
            </div>              
        </div>    
        <></>
        
        </Default>        
    )
}