import React from 'react'
import './style.scss'

type CookieBannerProps = {
    color:"blue-20"|"green-30"|"blue-40"|"dark-blue"|"olive-40"|"copper-40"|"background"
}

export default function CookieBanner(props:CookieBannerProps): JSX.Element{

    return(
        <>
        <div className={`cookieBanner active ${props.color}`}>
            <div className='container'>
                <div className='row'>                    
                    <div className='col-lg-12'>

                        <div className='cookieBanner__content'>

                            <div className='info'>
                                <h2>Vi bruker informasjonskapsler på våre nettsider</h2>
                                 <p>Vi lagrer data om ditt besøk for å forbedre og tilpasse våre tjenester, samt gi deg relevant informasjon. Du bestemmer selv om du ønsker å tillate at vi lagrer disse dataene, <a href="#" target="_blank">les mer om hvordan vi behandler dine data</a></p>
                            </div>

                            <div className='ctaWrapper'>
                                <button className='btn btn-primary md'>Jeg godtar</button>
                                <button className='btnLink'>Flere innstillinger</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}