import React from 'react'
import './style.scss'

export default function InstagramGallery(): JSX.Element{

    return(
        <>
            <div className='warning yellow'>
                <div className='container'>
                    <div className='row'>
                        <div className='offset-lg-1 col-lg-10'>
                            <div className='wrapper'>
                                <span className="icon-Info"></span>
                                <div className="content">
                                    <p>Denne kontakten trenger signering. <a href="#">Det gjør du her</a>.</p>                                      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>     
        </>
    )
}