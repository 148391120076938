import React from 'react'
import { EmptyPage } from '../../components/templates'
import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>
                
                    <div className='modalLogin__wrapper--content alternative-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Gratulerer! <br />Du er nå registret som <br />bruker hos oss </h2>

                            <div className='alternativeText'>
                                <p>For å kunne by og kjøpe hos oss, må du fullføre registreringen på min side</p>
                            </div>

                            <div className='btnCongratulationsWrapper'>
                                <button  className='btn btn-secondary lg'>Gå til min side </button>
                                <a href="#">Lukk vindu</a>
                            </div>

                        </div>
                    </div>

                </div>              
            </div>
  
        </EmptyPage> 
    )
}