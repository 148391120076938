import React from 'react'
import { EmptyPage } from '../../components/templates'
import Logo from '../../assets/images/logo.svg'
import './style.scss'

export default function Prototype(): JSX.Element{
    return(        
        <EmptyPage>
            <div className="container prototype">
                <div className="row">
                    <div className="col-12">
                        <header className="index-header mt-5">
                            <img src={Logo} alt="" />
                        </header>
                    </div>

                    {/* Pages */}
                    <div className="col-md-4">

                        <h3 className="mb-5">Pages</h3>
                    
                        {/* Front Page */}
                        <div className="link-group">
                            <h4>Front Page</h4>
                            <a target="_blank" href="/frontpage">Front Page</a>
                        </div>                        
                
                        <div className="link-group">
                            <h4>Category list</h4>
                            <small>Category</small> <br /><br />
                            <a target="_blank" href="/page-list/Kunst">Kunst</a>
                            <a target="_blank" href="/page-list/tilslagsliste">Tilslagsliste</a>    
                            <a target="_blank" href="/page-list/vintage-og-fashion">Vintage og Fashion</a>
                            <a target="_blank" href="/page-list/høydepunkter-klassisk-auksjon">Klassisk auksjon</a> 
                            <br />
                            <small>Modal vindu - Kategorier og sortering</small> <br /><br />
                            <a target="_blank" href="/page-list/modal-filter/kategorier-og-sortering">Kategorier og sortering</a>
                            <br />
                            <small>Modal vindu - Les mer</small> <br /><br />
                            <a target="_blank" href="/page-list/modal-les-mer">Les mer</a>
                            <br />
                            <small>Modal vindu - Kategorier Vin og Brennevin</small> <br /><br />
                            <a target="_blank" href="/page-list/modal-filter/velg-kategori">Velg kategori</a>
                            <a target="_blank" href="/page-list/modal-filter/velg-land">Velg land / Velg distrikt</a>
                            <a target="_blank" href="/page-list/modal-filter/velg-omrade">Velg område</a>
                            <br />
                            <small>Modal vindu - Sortering Vin og/eller Brennevin</small> <br /><br />
                            <a target="_blank" href="/page-list/modal-filter/Sortering">Sortering</a>
                            <br />
                            <small>Modal - Kategorier for tilslagsliste </small> <br /><br />
                            <a target="_blank" href="/page-list/modal-filter/velg-kategori-2">Velg Kategori</a>
                            <a target="_blank" href="/page-list/modal-filter/velg-kategori-3">Velg Kategori</a>
                        </div>

                        <div className="link-group">
                            <h4>Object view</h4>
                            <small>Objekt / produkt side</small> <br /><br /> 
                            <a target="_blank" href="/object-view/classic-auction">Klassisk auksjon</a>
                            <a target="_blank" href="/object-view/online-auction">Nettauksjonw</a>
                            <a target="_blank" href="/object-view/wine-auction">Vinauksjon</a>
                            <br />
                            <small>"Modal vindu"</small> <br /><br />                                             
                            <a target="_blank" href="/object-view/modal/objekt-zoom">Objekt / produkt side - zoom</a>
                            <a target="_blank" href="/object-view/modal/les-mer-beskrivelse">Les mer Beskrivelse</a>
                            <a target="_blank" href="/object-view/modal/les-mer-omkostninger">Les mer Omkostninger</a>
                            <a target="_blank" href="/object-view/modal/se-hele-budhistorikken">Se hele budhistorikken</a>
                            <a target="_blank" href="/object-view/modal/se-fraktalternativer">Se fraktalternativer</a>
                            <a target="_blank" href="/object-view/modal/bring-servicepakke">Bring servicepakke</a>
                            <a target="_blank" href="/object-view/modal/registrer-forhandsbud">Registrer forhåndsbud</a>
                            <a target="_blank" href="/object-view/modal/registrer-telefonbud">Registrer telefonbud</a>
                            <a target="_blank" href="/object-view/modal/kontakt-ekspert">Kontakt ekspert</a>
                            <a target="_blank" href="/object-view/modal/vikar-og-betingelser"> Vikår og betingelser</a>
                        </div>

                        <div className="link-group">
                            <h4>Search</h4>
                            <a target="_blank" href="/search/søkeresultat">Søkeresultat</a>
                            <a target="_blank" href="/search/nytt-søk">Nytt Søk</a>
                            <a target="_blank" href="/search/søkeresultat-lagre-søk">Søkeresultat - lagre søk</a>
                            <a target="_blank" href="/search/search-modal">Søk</a> 
                            <a target="_blank" href="/search/suggested-search-modal">Forslag til søkefraser</a>
                        </div>

                        <div className="link-group">
                            <h4>"Article"</h4>
                            <small>"Artikler oversikt"</small> <br /><br />
                            <a target="_blank" href="/article/articles">Artikler</a>
                            <br />
                            <small>"Artikler side"</small> <br /><br />
                            <a target="_blank" href="/article/how-to-sell">Hvordan selge?</a>
                            <a target="_blank" href="/article/article-page">Article Page</a>
                            <a target="_blank" href="/article/article-page-v2">Article Page V2</a>
                            <a target="_blank" href="/article/article-page-v3">Article Page V3</a>
                            <br />
                            <small>Components</small> <br /><br />
                            <a target="_blank" href="/article/article-page-image-block">Article Page - Image block</a>
                            <a target="_blank" href="/article/article-page-video">Article Page - Video</a>
                            <a target="_blank" href="/article/article-page-text-components">Article Page - Text Components</a>
                            <a target="_blank" href="/article/article-page-gallery">Article Page - Gallery</a>                            
                        </div>

                        <div className="link-group">
                            <h4>"My Page"</h4>
                            <small>"Forside - Min side"</small> <br /><br />
                            <a target="_blank" href="/my-page/main-page">Min side</a>
                            <a target="_blank" href="/my-page/main-page-2">Min side 2</a>
                            <a target="_blank" href="/my-page/main-page-3">Min side 3</a>
                            <a target="_blank" href="/my-page/main-page-4">Min side 4</a>
                            <br />

                            <small>"Min profil"</small> <br /><br />
                            <a target="_blank" href="/my-page/profile">Min profil</a>
                            <br />

                            <small>"Edit profil"</small> <br /><br />
                            <a target="_blank" href="/my-page/edit-personalia">Edit personalia</a>
                            <a target="_blank" href="/my-page/edit-passord">Edit passord</a>
                            <a target="_blank" href="/my-page/edit-kontakt">Edit kontakt</a>
                            <a target="_blank" href="/my-page/edit-adresse">Edit adresse</a>
                            <a target="_blank" href="/my-page/edit-levering">Edit levering</a>
                            <a target="_blank" href="/my-page/edit-annet">Edit annet</a>
                            <br />

                            <small>"Mine kort"</small> <br /><br />
                            <a target="_blank" href="/my-page/my-credit-cards">Mine betalingskort</a>
                            <a target="_blank" href="/my-page/edit-card">Modal - Endre betalingskort</a>
                            <a target="_blank" href="/my-page/remove-card">Modal - Slette betalingskort</a>
                            <br />

                            <small>"Mine kjøp og betalinger"</small> <br /><br />
                            <a target="_blank" href="/my-page/my-purchases-payments">Mine kjøp og betalinger</a>
                            <a target="_blank" href="/my-page/my-purchases-payments-object">Objekt</a>
                            <a target="_blank" href="/my-page/my-purchases-payments-object-2">Objekt med frakt bokst</a>
                            <a target="_blank" href="/my-page/my-purchases-payments-object-3">Objekt - Endre betaling</a>
                            <br />

                            <small>"Mine kjøp og betalinger - Modal"</small> <br /><br />
                            <a target="_blank" href="/my-page/modal-omkostninger">Modal - Omkostninger</a>
                            <a target="_blank" href="/my-page/modal-fraktalternativer">Modal - Fraktalternativer</a>
                            <a target="_blank" href="/my-page/modal-bring-servicepakke">Modal - Fraktalternativer - Bestill frakt</a>
                            <br />

                            <small>"Betaling for flere objekter"</small> <br /><br />
                            <a target="_blank" href="/my-page/payment-for-multiple-objects">Mine kjøp og betalinger</a>
                            <a target="_blank" href="/my-page/select-items-for-payment">Velg objekter for betaling</a>
                            <br />

                            <small>"Velg betalingsmetode for flere objekter"</small> <br /><br />
                            <a target="_blank" href="/my-page/objects-for-payment">Objekter til betaling</a>
                            <a target="_blank" href="/my-page/modal-full-payment">Modal - Fullfør betaling</a>
                            <a target="_blank" href="/my-page/payment-completed">Modal - Betalingen er fullført</a>
                            <a target="_blank" href="/my-page/full-payment-new-card">Modla - Fullfør betaling med nytt kort</a>
                            <a target="_blank" href="/my-page/bekreft-med-bankID">Bekreft med BankID</a>
                            <a target="_blank" href="/my-page/partial-payment-Walley">Delbetaling med Walley</a>
                            <br />

                            <small>"Velg betalingsmetode for ett objekt"</small> <br /> <br />
                            <a target="_blank" href="/my-page/my-purchases-payments">Mine kjøp og betalinger</a>
                            <a target="_blank" href="/my-page/my-purchases-payments-object-3">Endre betaling</a>                            
                            <a target="_blank" href="/my-page/betaling">Betaling</a>
                            <a target="_blank" href="/my-page/modal-full-payment">Modal - Fullfør betaling</a>
                            <a target="_blank" href="/my-page/payment-completed">Modal - Betalingen er fullført</a>                            
                            <br />

                            <small>"Mine salg og kontrakter"</small> <br /><br />
                            <a target="_blank" href="/my-page/sales-and-contracts-1">Mine salg og kontrakter-1</a>                    
                            <a target="_blank" href="/my-page/sales-and-contracts-2">Mine salg og kontrakter-2</a>
                            <a target="_blank" href="/my-page/sales-and-contracts-contract">Kontrakt</a>
                            <a target="_blank" href="/my-page/sales-and-contracts-object-1">Objekt til salgs</a>
                            <a target="_blank" href="/my-page/sales-and-contracts-object-2">Objekt til salgs - med varsling</a>
                            <a target="_blank" href="/my-page/sales-and-contracts-object-3">Objekt solgt - med oppgjør</a>
                            <a target="_blank" href="/my-page/modal-change-minimum-price">Modal - Endre minstepris</a>
                            <br />

                            <small>"Mine favoritter"</small> <br /><br />
                            <a target="_blank" href="/my-page/my-favorites">Mine favoritter</a>    
                            <a target="_blank" href="/my-page/my-favorites-modal-sortering">Mine favoritter - sorter </a>    
                            <a target="_blank" href="/my-page/my-favorites-modal-search">Mine favoritter - søk i favoritter</a>                             
                            <br />

                            <small>"Tilpass Varslinger"</small> <br /><br />
                            <a target="_blank" href="/my-page/customize-alerts">Tilpass varslinger</a>    
                            <br />

                            <small>"Mine bud"</small> <br /><br />
                            <a target="_blank" href="/my-page/my-bids">Mine bud</a>   
                            <br />

                            <small>"Lagrede søk"</small> <br /><br />
                            <a target="_blank" href="/my-page/saved-searches">Lagrede søk</a>   
                            <br />

                            <small>"Newsletter"</small> <br /><br />
                            <a target="_blank" href="/my-page/newsletter">Newsletter</a>   
                            <a target="_blank" href="/my-page/newsletter-subscribe">newsletter-subscriber</a>   
                            <br />

                            <small>"Vilkår og personvern"</small> <br /><br />
                            <a target="_blank" href="/my-page/terms-privacy">Vilkår og personvern</a>
                            <br />

                            <small>"Varslinger"</small> <br /><br />
                            <a target="_blank" href="/my-page/alerts">Varslinger</a>
                            <br />

                            <small>"Online vurdering"</small> <br /><br />
                            <a target="_blank" href="/login-modal-13">Logg inn for å gi bud </a>
                            <a target="_blank" href="/my-page/online-assessment">Online vurdering</a>
                            <br />

                        </div>

                        <div className="link-group">
                            <h4>404 / cookie</h4>
                            <small>"Cookie"</small> <br /><br />
                            <a target="_blank" href="/cookie-banner-1">Cookie banner 1</a>
                            <a target="_blank" href="/cookie-banner-2">Cookie banner 2</a>
                            <a target="_blank" href="/cookie-banner-3">Cookie banner 3</a>
                            <br />
                            <small>"404 / 405"</small> <br /><br />
                            <a target="_blank" href="/error-404-1">404 1</a>
                            <a target="_blank" href="/error-404-2">404 2</a>
                            <a target="_blank" href="/error-404-3">404 3</a>
                            <a target="_blank" href="/error-405">405</a>
                            <br />


                        </div>

                        <div className="link-group">
                            <h4>About Blomqvist</h4>
                            <a target="_blank" href="/about-employees">Our employees</a>
                            <a target="_blank" href="/about-events">Arrangementer</a>
                            <a target="_blank" href="/about-sell-blomqvist">Selge hos Blomqvist</a>
                            <a target="_blank" href="/about-shop-at-blomqvist">Kjøpe hos Blomqvist</a>
                            <a target="_blank" href="/about-blomqvist">Om Blomqvist</a>                            
                            <br />
                        </div>

                        <div className="link-group">
                            <h4>Contact Us</h4>
                            <a target="_blank" href="/contact-us">Kontakt oss</a>
                            <br />
                        </div>

                        <div className="link-group">
                            <h4>"Auksjoner"</h4>
                            <small>"Auksjoner"</small> <br /><br />
                            <a target="_blank" href="/auctions/home">Auksjoner</a>
                            <a target="_blank" href="/auctions/classic-previous">Klassisk auksjon (previous)</a>
                            <a target="_blank" href="/auctions/classic-next">Klassisk auksjon (next)</a>
                            <br />
                        </div>

                    </div>

                    {/* Page components */}
                    <div className="col-md-4">

                        <h3 className="mb-5">Page Components</h3>

                        <div className="link-group">
                            <h4>Grid</h4>
                            <a target="_blank" href="/grid">Grid</a>
                        </div>

                        <div className="link-group">
                            <h4>Typograph</h4>
                            <a target="_blank" href="/typograph">Typograph</a>
                        </div>

                        <div className="link-group">
                            <h4>Icons</h4>
                            <a target="_blank" href="/icons">Icons</a>
                        </div>

                        <div className="link-group">
                            <h4>Buttons</h4>
                            <a target="_blank" href="/buttons">Buttons</a>
                        </div>
                   
                    </div>

                    {/* Parts and Macros */}
                    <div className="col-md-4">

                        <h3 className="mb-5">Page Modules</h3>

                        <div className="link-group">
                            <h4>Hero</h4>
                            <a target="_blank" href="hero-1">50-50 | image</a>
                            <a target="_blank" href="hero-2">50-50 | video</a>
                            <a target="_blank" href="hero-3">40-60 | image</a>
                            <a target="_blank" href="hero-4">40-60 | video</a>
                            <a target="_blank" href="hero-5">50-50 | full image</a>
                            <a target="_blank" href="hero-6">50-50 | full video</a>
                            <a target="_blank" href="hero-7">50-50 | full image | light text</a>
                            <a target="_blank" href="hero-8">50-50 | image | background color | light tex</a>
                            <a target="_blank" href="hero-9">40-60 | image | background color|</a>
                        </div>                        

                        <div className="link-group">
                            <h4>Header</h4>
                            <a target="_blank" href="frontpage">Header</a>
                        </div>
                        
                        <div className="link-group">
                            <h4>Footer</h4>
                            <a target="_blank" href="frontpage">Footer</a>
                        </div>

                        <div className="link-group">
                            <h4>LogIn</h4>
                            <small>Logg inn med vipps eller passord</small>
                            <br /><br />
                            <a target="_blank" href="/login-modal-1">Logg inn </a>
                            <a target="_blank" href="login-modal-2">Logg inn med Vipps</a>
                            <a target="_blank" href="login-modal-3">Bekreft i Vipps</a>
                            <a target="_blank" href="login-modal-4">Så kommer det flere steg ... </a>
                            <br />
                            <small>"Glemt passord"</small>
                            <br /><br />
                            <a target="_blank" href="login-modal-5">Glemt passord</a>
                            <a target="_blank" href="login-modal-6">Glemt passord</a>
                            <br />
                            <small>"Bli ny kunde"</small>
                            <br /><br />
                            <a target="_blank" href="login-modal-7">Logg inn </a>
                            <a target="_blank" href="login-modal-8">Bli kunde hos blomqvist</a>
                            <a target="_blank" href="login-modal-9">Registrer deg som kunde</a>
                            <a target="_blank" href="login-modal-10">Gratulerer! Du er nå registret som ...</a>
                            <br />
                            <small>"Alternative overskrifterfor logg inn  / registrer"</small>
                            <br /><br />
                            <a target="_blank" href="login-modal-11">Logg inn for å gi bud </a>
                            <a target="_blank" href="login-modal-12">Obs! Du har ikke status som budgiver ...</a>
                        </div>

                    </div>
                </div>
            </div>

        </EmptyPage> 
    )
}