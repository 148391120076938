import React from 'react'
import { Default } from '../../../components/templates'
import Image from '../../../assets/images/classicImage.png'
import { BreadCrumbs } from '../../../components/primitives'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import './style.scss'

export default function PageList(): JSX.Element{
    return(        
        <Default headerColor="background">
            <></>               
            <div className='page-list-heading background'>
                <BreadCrumbs/> 
                <div className='page-list-heading__infoCategory'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='wrapper'>
                                    <div className='info'>
                                        <h2>Klassiske auksjoner </h2>
                                        <figure className='img aspectRatio--655x441 imageMobile'>                                    
                                            <img src={Image} alt="" />
                                        </figure>
                                        <div className='description'>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Imperdiet fames mattis fermentum massa sit. Lobortis porta sed viverra massa pretium. Amet nunc amet libero pretium.</p>
                                        </div>
                                    </div>
                                    <div className='image'>
                                        <figure className='img aspectRatio--655x441'>                                    
                                            <img src={Image} alt="" />
                                        </figure>
                                    </div>                                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  


            <div className='actionsClassicList'>

                <div className="tabWrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tabs">
                                    <button>Kommende auksjoner</button>
                                    <button className='active'>Tidligere auksjoner </button>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='offset-lg-1 col-lg-10'>   

                            <div className="actionsClassicList__list">

                                <div className="section">
                                    <h3>Auksjoner 2021</h3>

                                    <div className="item">
                                        <div className="image">
                                            <figure className='img aspectRatio--242x144'>
                                                <img src={Image} alt="" />
                                            </figure>
                                        </div>
                                        <div className="info">
                                            <span className='name'>Høstauksjonen</span>
                                            <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="image">
                                            <figure className='img aspectRatio--242x144'>
                                                <img src={Image} alt="" />
                                            </figure>
                                        </div>
                                        <div className="info">
                                            <span className='name'>Blomqvist + Bergen</span>
                                            <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="image">
                                            <figure className='img aspectRatio--242x144'>
                                                <img src={Image} alt="" />
                                            </figure>
                                        </div>
                                        <div className="info">
                                            <span className='name'>Sommerens høydepunkter</span>
                                            <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="image">
                                            <figure className='img aspectRatio--242x144'>
                                                <img src={Image} alt="" />
                                            </figure>
                                        </div>
                                        <div className="info">
                                            <span className='name'>Vinterens høydepunkter</span>
                                            <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                        </div>
                                    </div>

                                </div>

                                <div className="section">
                                    <h3>Auksjoner 2020</h3>

                                    <div className="item">
                                        <div className="image">
                                            <figure className='img aspectRatio--242x144'>
                                                <img src={Image} alt="" />
                                            </figure>
                                        </div>
                                        <div className="info">
                                            <span className='name'>Høstauksjonen</span>
                                            <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="image">
                                            <figure className='img aspectRatio--242x144'>
                                                <img src={Image} alt="" />
                                            </figure>
                                        </div>
                                        <div className="info">
                                            <span className='name'>Blomqvist + Bergen</span>
                                            <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="image">
                                            <figure className='img aspectRatio--242x144'>
                                                <img src={Image} alt="" />
                                            </figure>
                                        </div>
                                        <div className="info">
                                            <span className='name'>Sommerens høydepunkter</span>
                                            <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="image">
                                            <figure className='img aspectRatio--242x144'>
                                                <img src={Image} alt="" />
                                            </figure>
                                        </div>
                                        <div className="info">
                                            <span className='name'>Vinterens høydepunkter</span>
                                            <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                        </div>
                                    </div>

                                </div>




                                <div className='accordionList'>
                                    <Accordion allowMultipleExpanded allowZeroExpanded>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Auksjoner 2019 <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='content'>
                                                    <div className="section">
                                                        <div className="item">
                                                            <div className="image">
                                                                <figure className='img aspectRatio--242x144'>
                                                                    <img src={Image} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="info">
                                                                <span className='name'>Høstauksjonen</span>
                                                                <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="image">
                                                                <figure className='img aspectRatio--242x144'>
                                                                    <img src={Image} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="info">
                                                                <span className='name'>Høstauksjonen</span>
                                                                <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Auksjoner 2018 <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='content'>
                                                    <div className="section">
                                                        <div className="item">
                                                            <div className="image">
                                                                <figure className='img aspectRatio--242x144'>
                                                                    <img src={Image} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="info">
                                                                <span className='name'>Høstauksjonen</span>
                                                                <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="image">
                                                                <figure className='img aspectRatio--242x144'>
                                                                    <img src={Image} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="info">
                                                                <span className='name'>Høstauksjonen</span>
                                                                <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Auksjoner 2017 <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='content'>
                                                    <div className="section">
                                                        <div className="item">
                                                            <div className="image">
                                                                <figure className='img aspectRatio--242x144'>
                                                                    <img src={Image} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="info">
                                                                <span className='name'>Høstauksjonen</span>
                                                                <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="image">
                                                                <figure className='img aspectRatio--242x144'>
                                                                    <img src={Image} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="info">
                                                                <span className='name'>Høstauksjonen</span>
                                                                <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Auksjoner 2016 <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='content'>
                                                    <div className="section">
                                                        <div className="item">
                                                            <div className="image">
                                                                <figure className='img aspectRatio--242x144'>
                                                                    <img src={Image} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="info">
                                                                <span className='name'>Høstauksjonen</span>
                                                                <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="image">
                                                                <figure className='img aspectRatio--242x144'>
                                                                    <img src={Image} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="info">
                                                                <span className='name'>Høstauksjonen</span>
                                                                <span className="line">Dato for auksjon: 15.10.21 kl 14:35</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>




                            </div>

                        </div>
                    </div>
                </div>                
            </div>     


        </Default>        
    )
}