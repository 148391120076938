import react from 'react';
import Image1 from '../../../assets/images/excellentList1.png'
import Image2 from '../../../assets/images/excellentList2.png'
import Image3 from '../../../assets/images/excellentList3.png'
import './style.scss'



export default function BidHistory(): JSX.Element{

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10">
                    <div className="bid-history">
                        <div className="bid-history__header">
                            <div className='info'>
                                <span className='title'>Budhistorikk</span>
                            </div>
                        </div>
                        <div className="bid-history__list">

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="line desktop">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line mobile">Solgt fo: <strong> 9.000 NOK</strong></div>
                                        <div className="line mobile">Tid igjen: <strong> 15d 6t 15min</strong></div> 
                                        <div className="line desktop">Vurdering : <strong>8.000 - 9.000 NOK</strong></div>

                                        <div className='tags showMobile'><span className='tag gray'>Du leder</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag gray'>Du leder</span></div>
                                        <div className="line">Tid igjen: <strong> 15d 6t 15min</strong></div> 
                                        <div className="line">Solgt fo: <strong> 9.000 NOK</strong></div>
                                    </div>
                                    <span className='icon-CaretRight'></span>
                                </div>
                            </a>
                         
                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image2} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="line desktop">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line mobile">Neste bud: <strong> 19.000 NOK</strong></div>
                                        <div className="line mobile">Tid igjen: <strong> 15d 6t 15min</strong></div> 
                                        <div className="line desktop">Vurdering : <strong>8.000 - 9.000 NOK</strong></div>

                                        <div className='tags showMobile'><span className='tag gray'>Du er overbydd</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag gray'>Du er overbydd</span></div>
                                        <div className="line">Tid igjen: <strong> 15d 6t 15min</strong></div> 
                                        <div className="line">Ditt siste bud: <strong> 17.000 NOK</strong></div>
                                        <div className="line">Neste bud: <strong> 19.000 NOK</strong></div>
                                    </div>
                                    <span className='icon-CaretRight'></span>
                                </div>
                            </a>
    
                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image3} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="line desktop">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="line mobile">Neste bud: <strong> 9.000 NOK</strong></div>
                                        <div className="line mobile">Tid igjen: <strong> 15d 6t 15min</strong></div> 
                                        <div className="line desktop">Vurdering : <strong>8.000 - 9.000 NOK</strong></div>

                                        <div className='tags showMobile'><span className='tag gray'>Du er overbydd</span></div>
                                    </div>
                                    <div className="payment">
                                        <div className='tags'><span className='tag gray'>Du er overbydd</span></div>
                                        <div className="line">Tid igjen: <strong> 15d 6t 15min</strong></div> 
                                        <div className="line">Ditt siste bud: <strong> 7.000 NOK</strong></div>
                                        <div className="line">Neste bud: <strong> 9.000 NOK</strong></div>
                                    </div>
                                    <span className='icon-CaretRight'></span>
                                </div>
                            </a>

                        </div>

                        <div className="bid-history__showMore">
                            <button className='btn btn-primary nd'>Vis flere treff</button>
                        </div>

                    </div>                        
                </div>
            </div>
        </div>   
        </>
    )
}