import React from 'react'
import { Default } from '../../../../components/templates'
import BankID from '../../../../assets/images/embed-BankID.png'
import './style.scss'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span>Mine kjøp og betalinger</a>
                            <h1 >Bekreft med BankID</h1>                         
                        </div>
                    </div>
                </div>
            </div>

            <div className='optionBankID'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrapper">
                                <button className='active'>BankID på mobil</button>
                                <button>BankID</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='embedBankID'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="embed">
                                <img src={BankID} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <></>

        </Default>
    )
}