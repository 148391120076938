import { useState, useEffect, useRef } from 'react'
import { EmptyPage } from '../../../components/templates'
import { ModalObjectImage } from '../../../components/modules'
import '../../../components/modules/modal-filter/style.scss'
import '../style.scss'

export default function ModalObjectView(): JSX.Element{
    return(        
        <EmptyPage>

            <ModalObjectImage />
  
        </EmptyPage> 
    )
}