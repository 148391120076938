import React from 'react'
import { Default } from '../../../components/templates'
import ImageFeatured from '../../../assets/images/article-feature-image.png'
import { 
    RelatedArticles
} from '../../../components/modules'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export default function Article(): JSX.Element{
    return(        
        <Default headerColor="background">

            <div className='article'>

                <div className='article__header background'>

                    <div className='breadCrumbs article'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <a href="#" className='breadCrumbs__item'>Breadcrumb</a>
                                    <span className='icon-CaretRight'></span>
                                    <a href="#" className='breadCrumbs__item last'>Breadcrumb</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='title'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h1>Headline here</h1>                         
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="article__imageFeature background">
                    <div className='container'>
                        <div className='row'>
                            <div className='offset-lg-1 col-lg-10'>
                                <figure className='img aspectRatio--1062x698'>
                                    <img src={ImageFeatured} alt="" />                                
                                </figure>
                                <span className='figcaption'>Lorem ipsum dolor sit amet.</span>
                            </div>
                        </div>
                    </div>                                
                </div>

                <div className="article__content"> 

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                  
                                <div className="article__content--intro">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id sapien laoreet nunc viverra phasellus. Quis velit, habitasse odio eros. Odio vel, tellus aliquam diam interdum. Fames pellentesque eu eu facilisis massa purus turpis. Venenatis ultrices arcu enim quam malesuada. Adipiscing.</p>
                                </div>

                                <div className="article__content--wrapper">

                                    <h2>Headline here</h2>   

                                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat lectus nec lectus vestibulum, ornare quam a at sed. Quam sit blandit in nec tristique amet. Cursus ut dictum tempus mi augue leo dui sed gravida. Eget ridiculus mattis adipiscing consectetur scelerisque laoreet ullamcorper amet. Non, diam ac ut risus vulputate vehicula pretium. Massa commodo vel sit tellus sed lacinia. Feugiat urna rhoncus malesuada massa. Eu aliquam integer porttitor sit elit morbi aliquam quam. Aenean ultricies sit lectus maecenas. Viverra bibendum nascetur maecenas nunc urna, facilisi.</p>

                                    <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>

                                    <div className="textBox">
                                        <h3>Headline here</h3>
                                        <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>
                                    </div>

                                    <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>

                                    <div className="textBox">
                                        <h3>Headline here</h3>
                                        <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>
                                        <ul>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </li>
                                            <li>Lorem ipsum dolor sit amet, consectetur a</li>
                                        </ul>
                                    </div>

                                    <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>

                                    <div className="textBox">
                                        <h3>Headline here</h3>
                                        <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>
                                        <a href="#" className='btn btn-secondary md'>Les mer</a>
                                    </div>

                                    <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>

                                    <ul>
                                        <li>Lorem ipsum dolor sit amet, <a href="#">consectetur adipiscing elit</a>. Vel cras eu cum.</li>
                                        <li><strong>Lorem ipsum dolor sit amet, consectetur.</strong></li>
                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </li>
                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel cras eu cum.</li>
                                    </ul>

                                    <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>

                                    <div className="quote">
                                        <p>Jeg hadde utstilling om høsten hos Blomqvist. Striden gikk voldsomt om billedene.</p>
                                    </div>
                                
                                    <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>

                                    <a href="#" className='btn btn-secondary md'>Les mer</a>

                                    <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>

                                    <div className="accordionArticle">
                                        <div className="accordionDefault">
                                            <Accordion preExpanded={['item1']} allowMultipleExpanded>
                                                <AccordionItem uuid="item1">
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Read more (expandable field) <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies. Tellus consectetur id sit sit leo aliquet sed vulputate. At ornare egestas pellentesque et. Enim ac penatibus quam fermentum aliquet. Arcu justo in egestas vitae varius eget accumsan.</p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Read more (expandable field) <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies. Tellus consectetur id sit sit leo aliquet sed vulputate. At ornare egestas pellentesque et. Enim ac penatibus quam fermentum aliquet. Arcu justo in egestas vitae varius eget accumsan.</p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Read more (expandable field) <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies. Tellus consectetur id sit sit leo aliquet sed vulputate. At ornare egestas pellentesque et. Enim ac penatibus quam fermentum aliquet. Arcu justo in egestas vitae varius eget accumsan.</p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Read more (expandable field)  <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies.</p>
                                                        <ul>
                                                            <li>Lorem ipsum dolor sit amet, <a href="#">consectetur adipiscing elit</a>. Vel cras eu cum.</li>
                                                            <li><strong>Lorem ipsum dolor sit amet, consectetur.</strong></li>
                                                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </li>
                                                        </ul>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent lobortis lorem in massa. Leo libero scelerisque cursus sed lacus id. Fermentum senectus magna diam pulvinar. Interdum vivamus dignissim massa pulvinar vitae in ultricies.</p>
                                                        <a href="#" className='btn btn-secondary md'>Les mer</a>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <p>Aliquet dignissim mauris convallis in aliquet augue congue. Arcu tincidunt non sem non suspendisse semper in accumsan. Sed odio cras fermentum in. Posuere ultrices aliquet vestibulum facilisi non enim. Nullam mauris fermentum, cursus sit auctor nullam fusce.</p>

                                </div>

                            </div> 
                        </div> 
                    </div> 
                    


                </div>            
            </div>   
            <RelatedArticles/>   
        </Default>        
    )
}