import React from 'react'
import { EmptyPage } from '../../components/templates'
import './style.scss'

export default function Prototype(): JSX.Element{
    return(        
        <EmptyPage>
            <div className="container prototype">
                <div className="row">
                    <div className="col-12">
                        <header className="index-header mt-5">
                            <h3>Botton</h3>
                        </header>
                    </div>

                    {/* Primary */}
                    <div className="col-md-12">
                        <small>Primary</small>
                        <br /><br />
                    </div>

                    <div className="col-md-2">
                        <button className='btn btn-primary lg'>Button</button>
                        <br /><br />
                    </div>
                    
                    <div className="col-md-2">
                        <button className='btn btn-primary md'>Button</button>
                        <br /><br />
                    </div>


                    <div className="col-md-2">
                        <button className='btn btn-primary sm'>Button</button>
                        <br /><br />
                    </div>
                   
                    <div className="col-md-2">
                        <button className='btn btn-primary sm' disabled>Button</button>
                        <br /><br />
                    </div>


                    {/* Secondary */}
                    <div className="col-md-12">
                        <br /><br />
                        <small>Secondary</small>
                        <br /><br />
                    </div>

                    <div className="col-md-2">
                        <button className='btn btn-secondary lg'>Button</button>
                        <br /><br />
                    </div>
                    

                    <div className="col-md-2">
                        <button className='btn btn-secondary md'>Button</button>
                        <br /><br />
                    </div>


                    <div className="col-md-2">
                        <button className='btn btn-secondary sm'>Button</button>
                        <br /><br />
                    </div>


                    <div className="col-md-2">
                        <button className='btn btn-secondary sm' disabled>Button</button>
                        <br /><br />
                    </div>
                   

                    {/* Red */}
                    <div className="col-md-12">
                        <br /><br />
                        <small>Red</small>
                        <br /><br />
                    </div>

                    <div className="col-md-2">
                        <button className='btn btn-red lg'>Button</button>
                        <br /><br />
                    </div>
                

                    <div className="col-md-2">
                        <button className='btn btn-red md'>Button</button>
                        <br /><br />
                    </div>


                    <div className="col-md-2">
                        <button className='btn btn-red sm'>Button</button>
                        <br /><br />
                    </div>
                   

                    <div className="col-md-2">
                        <button className='btn btn-red sm' disabled>Button</button>
                        <br /><br />
                    </div>


                    {/* Gray */}
                    <div className="col-md-12">
                        <br /><br />
                        <small>Gray</small>
                        <br /><br />
                    </div>

                    <div className="col-md-2">
                        <button className='btn btn-gray lg'>Button</button>
                        <br /><br />
                    </div>
                    

                    <div className="col-md-2">
                        <button className='btn btn-gray md'>Button</button>
                        <br /><br />
                    </div>


                    <div className="col-md-2">
                        <button className='btn btn-gray sm'>Button</button>
                        <br /><br />
                    </div>

                    <div className="col-md-2">
                        <button className='btn btn-gray sm' disabled>Button</button>
                        <br /><br />
                    </div>
                                       

                </div>
            </div>

        </EmptyPage> 
    )
}