import React from 'react'
import ImageimageTextBlock from '../../../assets/images/image-text-block-left.jpg'
import './style.scss'

type ImageTextBlockProps = {
    left?: boolean,
    light?: boolean
}

export default function ImageTextBlock({left=false,light=false}:ImageTextBlockProps): JSX.Element{

    return(
        <>
        <div className={`imageTextBlock ${left ? 'imageTextBlock-left' : 'imageTextBlock-right'} ${light ? 'light' : ''}`}>
            <div className='container'>
                <div className='row'>                    
                    <div className='col-lg-12'>

                        <div className='imageTextBlock__content'>

                            <div className='content'>
                                <h2>Hvordan gi bud og kjøpe hos oss</h2>
                                <div className='mobileImage'>
                                    <figure className='img aspectRatio--318x232'>
                                        <img src={ImageimageTextBlock} alt="" />
                                    </figure>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non sem ultrices magna rutrum purus, et velit.</p>
                                <a className="btn btn-secondary lg" href="#">Les mer</a>   
                            </div>

                            <div className='image'>
                                <figure className='img aspectRatio--656x554'>
                                    <img src={ImageimageTextBlock} alt="" />
                                </figure>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}