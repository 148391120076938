import React from 'react'
import { Default } from '../../components/templates'
import ImageObject3 from '../../assets/images/object-view-image3.png'
import ImageObject4 from '../../assets/images/object-view-image4.png'
import ImageObject11 from '../../assets/images/object-view-image11.png'
import ImageObject22 from '../../assets/images/object-view-image22.png'
import ImageObject33 from '../../assets/images/object-view-image33.png'
import ImageObject44 from '../../assets/images/object-view-image44.png'
import ImageObject55 from '../../assets/images/object-view-image55.png'
import SelectSearch from 'react-select-search';
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import './style.scss'

export default function ObjectView(): JSX.Element{

    const settingsCarousel = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1420,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 4,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 1200,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 3,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 890,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 2,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 575,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 1,
                slidesToScroll: 1
            }
          }
          
        ]
    };


    return(
        <Default headerColor="light"> 

            <div className='breadCrumbs'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='breadCrumbs__item'>Auksjoner</a>
                            <span className='icon-CaretRight'></span>
                            <a href="#" className='breadCrumbs__item'>Antikviteter</a>
                            <span className='icon-CaretRight'></span>
                            <a href="#" className='breadCrumbs__item last'>Henrik Bertram Møller</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='object'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='object__titleMobile'>
                                <span className='preTitle'>Objektnr: 44374-1</span>
                                <h1 className='title'>Henrik Bertram Møller</h1>
                                <h2 className='subtitle'>Sausenebb</h2>
                            </div>
                            <div className='object__image'>
                                <div className="featured">
                                    <span className="bookmark icon-Heart"></span>
                                    <figure className="img aspectRatio--1x1">
                                        <img src={ImageObject11} alt="" />                                        
                                    </figure>
                                </div>
                                <div className='moreIMages'>
                                    <div className="image">
                                        <figure className="img aspectRatio--1x1">
                                            <img src={ImageObject22} alt="" />                                        
                                        </figure>
                                    </div>
                                    <div className="image">
                                        <figure className="img aspectRatio--1x1">
                                            <img src={ImageObject33} alt="" />                                        
                                        </figure>
                                    </div>
                                    <div className="image">
                                        <figure className="img aspectRatio--1x1">
                                            <img src={ImageObject44} alt="" />                                        
                                        </figure>
                                    </div>
                                    <div className="image">
                                        <figure className="img aspectRatio--1x1">
                                            <img src={ImageObject55} alt="" />                                        
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='object__details'>

                                <div className='object__details--heading'>
                                    <span className='preTitle'>Objektnr: 44374-1</span>
                                    <h1 className='title'>Henrik Bertram Møller</h1>
                                    <h2 className='subtitle'>Sausenebb</h2>
                                </div>

                                <div className='object__details--auction'>

                                    <div className='info'>
                                        <div className='item'>
                                            <div className='item-row'>
                                                <span>Vurdering<b>: </b></span>
                                                <strong>10.000 - 12.000 NOK</strong>
                                            </div>
                                            <div className='item-row'>
                                                <small>100 - 125 EUR</small>
                                            </div> 
                                        </div>
                                        <div className='item'>
                                            <div className='item-row'>
                                                <span>Neste mulige bud<b>: </b></span>
                                                <strong>8.000 NOK</strong>
                                            </div>
                                            <div className='item-row'>
                                                <small>822 EUR</small>
                                            </div> 
                                        </div>
                                        <div className='item'>
                                            <div className='item-row'>
                                                <span>Tid igjen.<b>: </b></span>
                                                <strong>5d 6t 15min</strong>
                                            </div>
                                            <div className='item-row'>
                                                <small>Auksjonen avsluttes: 07.11.2021 - 16:20</small>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div className='offerBox'>
                                  
                                        <div className="wrapper">
                                            <div className='select'>
                                                <SelectSearch
                                                    options={[
                                                        { value: '1000NOK', name: '1 000 NOK'},
                                                        { value: '1500NOK', name: '1 500 NOK'},
                                                        { value: '2000NOK', name: '2 000 NOK'},
                                                        { value: '2500NOK', name: '2 500 NOK'},
                                                        { value: '3000NOK', name: '3 000 NOK'},
                                                        { value: '3500NOK', name: '3 500 NOK'},
                                                        { value: '4500NOK', name: '4 500 NOK'},
                                                        { value: '5000NOK', name: '5 000 NOK'},
                                                        { value: '5500NOK', name: '5 500 NOK'}
                                                    ]}
                                                    placeholder="Velg ditt maksbud"
                                                />
                                            </div>
                                            <button className='ctaBtn'>Gi bud</button>
                                        </div>
                                        <div className='info'>
                                            Det vil påløpe noen <a href="#">omkostninger</a> i tilleg til budet
                                        </div>
                                    </div>

                                    <div className="sendMailBox">
                                        <h2>Frakt <span className='icon-Truck'></span></h2>
                                        <p>Objektet kan sendes med post i Norge for <strong>250 NOK</strong>. <br />
                                        <a href="#">Les mer om frakt her</a></p>
                                    </div>

                                    <div className='description'> 
                                        <div className="accordionDefault">
                                            <Accordion preExpanded={['item1']} allowMultipleExpanded>
                                                <AccordionItem uuid="item1"> 
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Beskrivelse <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>Sausenebb av <a href="#">Sølv. H. Møller</a>, Trondheim. Ca. år 1900. <br />
                                                            Olje på lerret, 76 x 59 cm</p>

                                                        <p>Dragestil. Støpt, punslet, drevet og siselert dekor. Formet som drage. Rik relieffdekor med slyngede bånd.</p>

                                                        <p>Høyde 7,0 cmLengde 16,5 cm</p>

                                                        <a href="#" className='showMore'>Les mer</a>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Ekspertens merknad <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore  </p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Lokasjon <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        content
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Budhistorikk  <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <div className='bidHistory'>

                                                            <div className='item'>
                                                                <div className="number">
                                                                    <span className='color-1'>12</span>
                                                                    </div>
                                                                <div className="dateHour">
                                                                    <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                                                </div>
                                                                <div className="value">4.400 NOK</div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="number">
                                                                    <span className='color-2'>11</span>
                                                                    </div>
                                                                <div className="dateHour">
                                                                    <span className="text">07.Okt - 16:11:36</span>
                                                                </div>
                                                                <div className="value">4.300 NOK</div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="number">
                                                                    <span className='color-1'>12</span>
                                                                    </div>
                                                                <div className="dateHour">
                                                                    <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                                                </div>
                                                                <div className="value">4.200 NOK</div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="number">
                                                                    <span className='color-3'>9</span>
                                                                    </div>
                                                                <div className="dateHour">
                                                                    <span className="text">07.Okt - 16:11:36</span>
                                                                </div>
                                                                <div className="value">4.100 NOK</div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="number">
                                                                    <span className='color-1'>12</span>
                                                                    </div>
                                                                <div className="dateHour">
                                                                    <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                                                </div>
                                                                <div className="value">4.000 NOK</div>
                                                            </div>
                                                            <div className='item'>
                                                                <div className="number">
                                                                    <span className='color-4'>12</span>
                                                                    </div>
                                                                <div className="dateHour">
                                                                    <span className="text">07.Okt - 16:11:36</span>
                                                                </div>
                                                                <div className="value">3.900 NOK</div>
                                                            </div>
                                                            <button className='showBidHistory'>Se hele budhistorikken</button>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='carouselObjects'>                
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>

                            <div className='carouselObjects__carousel'>                               

                                <div className='carousel'>
                                    <h2>Relaterte objekter</h2>
                                    <Slider {...settingsCarousel}>
                                    
                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--1x1'>
                                                        <img src={ImageObject3} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>Navn på kunstverk</div>
                                                <div className='line'>8.000 - 9.000 NOK </div>
                                                <div className='line'>Klassisk Auksjon: <strong>Kat.nr.7</strong></div>
                                            </div>
                                        </div>
                                    
                                    </Slider>
                                </div>

                                <div className='carousel'>
                                    <h2>Sist sett på</h2>
                                    <Slider {...settingsCarousel}>
                               
                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>

                                        <div className='item'>
                                            <div className='image'>
                                                <span className='bookmark icon-Heart'></span>
                                                <a href="#">
                                                    <figure className='img aspectRatio--200x200'>
                                                        <img src={ImageObject4} alt="" />
                                                    </figure>
                                                </a>
                                            </div>
                                            
                                            <div className='info'>
                                                <a href="#" className='name'>Navn på kunstner</a>
                                                <div className='line'>8.000 - 9.000 NOK  |  5d 6t 15min</div>
                                                <div className='line'>Solgt for: <strong>9.500 NOK</strong></div>
                                            </div>
                                        </div>
                                  
                                    </Slider>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>                
            </div>

        </Default>       
    )
}