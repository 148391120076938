import React from 'react'
import { Default } from '../../components/templates'
import {Hero,
    PopularAuctions,
    ImageTextBlockFull,
    ImageTextBlock,
    ObjectsTabs,
    SubscribeNewsletter,
    InstagramGallery,
    WeSellIt
} from '../../components/modules'
import './style.scss'

export default function FrontPage(): JSX.Element{
    return(         
        <Default frontpage headerColor="light">
            <Hero/>
            <ObjectsTabs onTabSelect={(tab:string)=>{console.log(tab)}}/>
            <PopularAuctions/>
            <ImageTextBlock left/>
            <ImageTextBlockFull/>
            <ImageTextBlock/>
            <WeSellIt/>
            <SubscribeNewsletter/>
            <InstagramGallery/>
        </Default>        
    )
}