import React from 'react'
import ImageRelated1 from '../../../assets/images/relatedArticle4.png'
import ImageRelated2 from '../../../assets/images/relatedArticle3.png'
import ImageRelated3 from '../../../assets/images/relatedArticle2.png'
import ImageRelated4 from '../../../assets/images/relatedArticle1.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import './style.scss'

export default function SearchModal(): JSX.Element{

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 890,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 575,
            settings: {
                arrows: false,
                variableWidth: true,
                slidesToShow: 1,
                slidesToScroll: 1
            }
          }
          
        ]
    };


    return(
        <div className="relatedArticles">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wrapper">

                            <h2 className='title'>Relaterte artikler</h2>
                            
                            <div className="relatedArticles__items">
                                <Slider {...settings}>
                                    <div className='item'>
                                        <div className='image'>                                            
                                            <a href="#">
                                                <figure className='img aspectRatio--296x250'>
                                                    <img src={ImageRelated1} alt="" />
                                                </figure>
                                            </a>
                                        </div>
                                        <div className='info'>
                                            <div className='line'>Selge</div>
                                            <a href="#" className='titleItem'>Hvordan selge hos oss</a>
                                        </div>
                                    </div>

                                    <div className='item'>
                                        <div className='image'>                                            
                                        <a href="#">
                                            <figure className='img aspectRatio--296x250'>
                                                <img src={ImageRelated2} alt="" />
                                            </figure>
                                        </a>
                                        </div>
                                        <div className='info'>
                                            <div className='line'>Selge</div>
                                            <a href="#" className='titleItem'>Møt ekspertene</a>                                            
                                        </div>
                                    </div>

                                    <div className='item'>
                                        <div className='image'>                                            
                                            <a href="#">
                                                <figure className='img aspectRatio--296x250'>
                                                    <img src={ImageRelated3} alt="" />
                                                </figure>
                                            </a>
                                        </div>
                                        <div className='info'>
                                            <div className='line'>Selge</div>
                                            <a href="#" className='titleItem'>Hva er online vurdering?</a>
                                        </div>
                                    </div>

                                    <div className='item'>
                                        <div className='image'>                                            
                                            <a href="#">
                                                <figure className='img aspectRatio--296x250'>
                                                    <img src={ImageRelated4} alt="" />
                                                </figure>
                                            </a>
                                        </div>
                                        <div className='info'>
                                            <div className='line'>Selge</div>
                                            <a href="#" className='titleItem'>Levering og henting</a>
                                        </div>
                                    </div>
                                </Slider>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}