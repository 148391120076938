import React,{useState} from 'react'
import './style.scss'

export default function GridView(): JSX.Element{

    const [grid,setGrid] = useState(false)

    const toggle = ()=>{
        setGrid(!grid)
    }

    return(
        <>
            <button className="btnGridView" onClick={toggle} >GRID</button>

            <div className={"gridView desktop " + `${grid?'active':''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>1</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>2</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>3</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>4</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>5</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>6</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>7</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>8</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>9</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>10</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>11</span></div>
                        <div className="col-md-1 col-lg-1 col-xl-1"><span>12</span></div>
                    </div>
                </div>
            </div>

            <div className={"gridView mobile " + `${grid?'active':''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><span>1</span></div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><span>2</span></div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><span>3</span></div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><span>4</span></div>
                    </div>
                </div>
            </div>
        </>
    )
}