import react from 'react';
import Image1 from '../../../assets/images/walley.png'
import './style.scss'



export default function ChoosePaymentMethod(): JSX.Element{

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10"> 
                    <div className="ChoosePaymentMethod">
                        <div className="ChoosePaymentMethod__header">
                            <h3 className='title'>Velg betalingsmetode </h3>
                        </div>
                        <div className="ChoosePaymentMethod__list">

                            <div className="item">
                                <span className="icon-CaretRight arrowIcon"></span>
                                <div className="data">
                                    <div className='name'>Betale nå med mitt lagrede kort</div>
                                    <div className="line">Visa **** 3488</div>                                 
                                </div>
                                <div className="icon">
                                    <span className='icon-CreditCard'></span>
                                </div>
                            </div>

                            <div className="item">
                                <span className="icon-CaretRight arrowIcon"></span>
                                <div className="data">
                                    <div className='name'>Betale med annet kort</div>
                                    <div className="line">Legg inn nytt Visa eller Mastercard</div>                                 
                                </div>
                                <div className="icon">
                                    <span className='icon-CreditCard'></span>
                                </div>
                            </div>

                            <div className="item">
                                <span className="icon-CaretRight arrowIcon"></span>
                                <div className="data">
                                    <div className='name'>Delbetaling med Walley</div>
                                    <div className="line">Betal fra 7.500 NOK pr mnd</div>                                 
                                </div>
                                <div className="icon">
                                    <img src={Image1} alt="" />
                                </div>
                            </div>

                            <div className="item">
                                <span className="icon-CaretRight arrowIcon"></span>
                                <div className="data">
                                    <div className='name'>Faktura med Walley</div>
                                    <div className="line">Betal om 30 dager</div>                                 
                                </div>
                                <div className="icon">
                                    <img src={Image1} alt="" />
                                </div>
                            </div>

                        </div>
                    </div>                        
                </div>
            </div>
        </div>   
        </>
    )
}