import React from 'react'
import { EmptyPage } from '../../components/templates'
import './style.scss'

export default function Prototype(): JSX.Element{
    return(        
        <EmptyPage>

            <div className="container prototype">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="grid-txt">
                            "The grid is based on open columns, locked gutter and locked margin. While the gutter and margin size will remain the same, the columns will grow dependant on the size of the users viewport."
                        </div>
                        
                        <div>
                            <br /><br />
                            Container max-width: <strong>1280px</strong>
                        </div>
                    </div>
                </div>
               

                <div className="row gr">
                    <div className="grid-col col-sm-3"><div></div></div>
                    <div className="grid-col col-sm-9"><div></div></div>
                    <div className="grid-col col-sm-3"><div></div></div>
                    <div className="grid-col col-sm-3"><div></div></div>
                    <div className="grid-col col-sm-3"><div></div></div>
                    <div className="grid-col col-sm-3"><div></div></div>
                    <div className="grid-col col-sm-4"><div></div></div>
                    <div className="grid-col col-sm-4"><div></div></div>
                    <div className="grid-col col-sm-4"><div></div></div>
                    <div className="grid-col col-sm-6"><div></div></div>
                    <div className="grid-col col-sm-6"><div></div></div>
                    <div className="grid-col col-sm-12"><div></div></div>
                </div>
            </div>

        </EmptyPage> 
    )
}