import React from 'react'
import ImageObject1 from '../../../assets/images/product-list1.png'
import ImageObject2 from '../../../assets/images/product-list2.png'
import ImageObject3 from '../../../assets/images/product-list3.png'
import './style.scss'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export default function SearchModal(): JSX.Element{

    return(
        <>
        {/* Add .active class to enable login modal: <div className='searchModal active'> */}
        <div className='searchModal active'>

            <div className='searchModal__wrapper'>

                <div className='searchModal__wrapper--content'>
                    <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    <div className='content'>
                        <h2>Søk</h2>      

                        <div className="input">
                            <form className='input'>
                                <span className="icon-MagnifyingGlass"></span>
                                <input type="text" placeholder='Søk etter...'/>
                                <button type="submit" value="Søk">Søk</button>
                            </form>     
                        </div>   

                        <div className="prevResearch">

                            <div className="title">
                                <h3>Tidligere søk</h3>
                            </div>
                            <div className="list">
                                <div className="item">
                                    Gucci - <a href="#">Vintage (1)</a>
                                    <button><span className="icon-X"></span></button>
                                </div>
                                <div className="item">
                                    Tag - <a href="#">Smykker og ur (1)</a>
                                    <button><span className="icon-X"></span></button>
                                </div>
                                <div className="item">
                                    Munch - <a href="#">Kunst (86)</a>
                                    <button><span className="icon-X"></span></button>
                                </div>
                            </div>
                            <div className="btnWrapper">
                                <button>Slett søkehistorikk</button>
                            </div>    

                        </div>

                        <div className="saveResearch">  

                            <div className="title">
                                <h3>Mine lagrede søk</h3>
                            </div>
                            <div className="list">
                                <div className="item">
                                    Brosje - <a href="#">Smykker og ur (8)</a>
                                    <button><span className="icon-X"></span></button>
                                </div>
                                <div className="item">
                                    Balenciaga - <a href="#">Vintage - Vesker (1)</a>
                                    <button><span className="icon-X"></span></button>
                                </div>
                                <div className="item">
                                    Ole Juul - <a href="#">Kunst - Malerier (86)</a>
                                    <button><span className="icon-X"></span></button>
                                </div>
                            </div>
                            <div className="btnWrapper">
                                <button>Lagrede søk</button>
                            </div>                                
                        
                        </div>   



                        <div className='prevSaveResearch'>
                            <div className="accordionDefault">
                                <Accordion preExpanded={['item1']} allowZeroExpanded allowMultipleExpanded>
                                    <AccordionItem uuid="item1">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Tidligere søk <span className="icon-CaretDown"></span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="list">
                                                <div className="item">
                                                    Brosje - <a href="#">Smykker og ur (8)</a>
                                                    <button><span className="icon-X"></span></button>
                                                </div>
                                                <div className="item">
                                                    Balenciaga - <a href="#">Vintage - Vesker (1)</a>
                                                    <button><span className="icon-X"></span></button>
                                                </div>
                                                <div className="item">
                                                    Ole Juul - <a href="#">Kunst - Malerier (86)</a>
                                                    <button><span className="icon-X"></span></button>
                                                </div>
                                            </div>
                                            <div className="btnWrapper"><button>Slett søkehistorikk</button></div>
                                        </AccordionItemPanel>
                                    </AccordionItem>   
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Mine lagrete søk<span className="icon-CaretDown"></span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div className="list">
                                                <div className="item">
                                                    Brosje - <a href="#">Smykker og ur (8)</a>
                                                    <button><span className="icon-X"></span></button>
                                                </div>
                                                <div className="item">
                                                    Balenciaga - <a href="#">Vintage - Vesker (1)</a>
                                                    <button><span className="icon-X"></span></button>
                                                </div>
                                                <div className="item">
                                                    Ole Juul - <a href="#">Kunst - Malerier (86)</a>
                                                    <button><span className="icon-X"></span></button>
                                                </div>
                                            </div>
                                            <div className="btnWrapper"> <button>Lagrede søk</button> </div> 
                                        </AccordionItemPanel>
                                    </AccordionItem>                                 
                                </Accordion>
                            </div> 
                        </div>              

                    </div>
                </div>
       
            </div>

        </div>            
        </>
    )
}