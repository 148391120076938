import React from 'react'
import { EmptyPage } from '../../components/templates'
import VippsLogoColor from '../../assets/images/vipps-logo-color.svg'
import VippsIcon from '../../assets/images/vipps-icon.svg'
import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>
                
                    <div className='modalLogin__wrapper--content confirm-vipps-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Bekreft i Vipps </h2>

                            <img className='vippsLogoColor' src={VippsLogoColor} alt="Vipps" />

                            <img className='vippsIcon' src={VippsIcon} alt="Vipps" />

                            <div className='inputMobileNumber'>
                                <input type="tel"/>
                            </div>

                            <div className='notificationVippsText'>
                                <p>Du har fått et varsel på telefonen din. <br /> Åpne Vipps og følg instruksjonene.</p>
                            </div>
                        </div>
                    </div>

                </div>              
            </div>
  
        </EmptyPage> 
    )
}