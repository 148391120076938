import React,{useState} from 'react'
import './style.scss'

export default function breadCrumbs(): JSX.Element{

    const [password,setPassword] = useState(false)
    const toggleShow= (e:any)=>{
        e.preventDefault()
        setPassword(!password)
    }

    return(
        <div className='password-input'>
            <input type={`${password?'text':'password'}`} defaultValue="12345678" id="Passord"/>
            <button onClick={toggleShow}><span className='icon-EyeSlash'></span></button>
        </div>
    )
}