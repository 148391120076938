import React from 'react'
import { Default } from '../../components/templates'

export default function FrontPage(): JSX.Element{

    const videHero = 'https://player.vimeo.com/video/76979871?background=1&autoplay=1&loop=1&byline=0&title=0'
    
    return(
        <Default frontpage headerColor="light">
        <div className='hero hero-50-50 blue-20'>
            <div className="vimeo-background-mobile img aspectRatio--375x297"><iframe src={videHero}></iframe></div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-sm-6'>
                        <div className='hero__content'>
                            <div className='hero__content--content'>
                                <h1>Vinterens høydepunkter</h1>
                                <p>Med et fantastisk utvalgt kunstverk fra norske og internasjonale mestre avrunder Blomqvist året 2021 med den klassiske auksjonen Høydepunkter.</p>
                                <a className='btn btn-secondary md' href="#" >Les mer</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vimeo-background"><div className="content"><iframe src={videHero}></iframe></div></div>
            </div>
        </div>
        <></>
        </Default>
    )
}