import React from 'react'
import ImageObject1 from '../../../assets/images/product-list1.png'
import ImageObject2 from '../../../assets/images/product-list2.png'
import ImageObject3 from '../../../assets/images/product-list3.png'
import './style.scss'

export default function OtherCategories(): JSX.Element{

    return(
        <>
        <div className='otherCategories'>

            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='otherCategories__title'>
                            <h2>Andre kategorier</h2>
                        </div>
                        <div className="otherCategories__items">
                            <span className="item"><a href="#">Kunst</a></span>
                            <span className="item"><a href="#">Interiør</a></span>
                            <span className="item"><a href="#">Smykker og ur</a></span>
                            <span className="item"><a href="#">Antikviteter</a></span>
                            <span className="item"><a href="#">Vintage</a></span>
                            <span className="item"><a href="#">Glass og porselen</a></span>
                            <span className="item"><a href="#">Samleobjekter</a></span>
                            <span className="item"><a href="#">Vin</a></span>
                        </div>
                    </div>
                </div>
            </div>

        </div>        
        </>
    )
}