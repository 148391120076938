import { useState, useEffect, useRef } from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'
import '../style.scss'

export default function ModalObjectView(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalObjectView active'>
                <div className='modalObjectView__wrapper'>
                    <div className='modalObjectView__wrapper--content'>

                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                        
                        <div className='content'>
                            <div className='wrapper'>

                                <div className='title'>
                                    <h2>Beskrivelse</h2>
                                    <h3>Objektnummer: 141335-6</h3>
                                </div>

                                <p><strong>Sausenebb</strong></p>

                                <p><u>Sølv. H. Møller, Trondheim. Ca. år 1900.</u></p>

                                <p>Dragestil. Støpt, punslet, drevet og siselert dekor. Formet som drage. Rik relieffdekor med slyngede bånd.</p>

                                <p>Høyde 7,0 cmLengde 16,5 cm</p>

                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illoSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore  inventore </p>

                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illoSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore  inventore </p>

                                <p>Sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore  inventore </p>

                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa</p>

                            </div>
                        </div>

                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Lukk vindu</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}