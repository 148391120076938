import { useState, useEffect, useRef } from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'
import '../style.scss'

export default function ModalObjectView(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalObjectView active'>
                <div className='modalObjectView__wrapper'>
                    <div className='modalObjectView__wrapper--content'>

                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                        
                        <div className='content'>
                            <div className='wrapper'>

                                <div className='title'>
                                    <h2>Omkostninger</h2>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo facilisis arcu enim pellentesque nisl purus. Convallis bibendum risus tempor habitasse eu quam lectus. Eu egestas volutpat cursus lobortis orci vitae faucibus a. Volutpat nulla quis neque justo odio enim. Viverra faucibus nunc dictum et sollicitudin. </p>

                                <p>Tellus duis dolor arcu ac, suspendisse blandit posuere placerat. Consectetur in arcu interdum elit est a, et. Pellentesque at eros condimentum mattis ut aliquet nunc, ullamcorper.</p>

                                <p>Est interdum dolor mauris dignissim tortor. Porttitor lectus malesuada sollicitudin venenatis tincidunt mattis egestas. Nullam enim at eu amet faucibus amet. Purus nec accumsan, nunc risus, eleifend. Risus sit eget diam venenatis sit enim. Tristique in sed arcu pellentesque purus amet. Massa a adipiscing nec pulvinar eleifend massa tellus donec pellentesque. Posuere pellentesque dui, laoreet odio rutrum magna magna natoqu.</p>

                            </div>
                        </div>

                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Lukk vindu</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}