import React from 'react'
import Image1 from '../../../assets/images/excellentList1.png'
import Image2 from '../../../assets/images/excellentList2.png'
import Image3 from '../../../assets/images/excellentList3.png'
import './style.scss'

export default function PaymentsCompletedList(): JSX.Element{

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10">
                    <div className="paymentsCompletedList">
                        <div className="paymentsCompletedList__header">
                            <span className='title'>Utførte betalinger </span>
                        </div>
                        <div className="paymentsCompletedList__list">

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="number desktop">Objektnummer: <strong>141151-1</strong></div>
                                        <div className="value mobile">Betalt: <strong>50.000 NOK</strong></div>
                                        <div className="delivery">Utlevering: <strong>Hentes på Fornebu</strong></div>
                                    </div>
                                    <div className="payment">
                                         <div className="value">Betalt: <strong>50.000 NOK</strong></div>
                                         <div className="line"> Utestående lagerleie: <strong className='red'>200 NOK</strong></div>
                                    </div>
                                </div>
                            </a>

                            <a className="item" href='#'>
                                <div className="image">
                                    <span className='icon-Truck'></span>
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Frakt for objektnummer 141151-1</h3>
                                        <div className="number desktop">Ordre.nr: <strong>14115199</strong></div>
                                        <div className="value mobile">Betalt: <strong>400 NOK</strong></div>
                                    </div>
                                    <div className="payment">
                                         <div className="value">Betalt: <strong>400 NOK</strong></div>
                                    </div>
                                </div>
                            </a>                            

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="number">Objektnummer: <strong>141151-1</strong></div>
                                        <div className="delivery">Utlevering: <strong> Utleverert Fornebu 02.01.22</strong></div>
                                    </div>
                                    <div className="payment">
                                         <div className="value">Betalt: <strong>15.000 NOK</strong></div>
                                         <div className="line"> Betalt lagerleie: <strong>200 NOK</strong></div>
                                    </div>
                                </div>
                            </a>

                        </div>
                        <div className="paymentsCompletedList__seeAll">
                            <button className='btn btn-primary'>Vis flere treff</button>
                        </div>
                    </div>                        
                </div>
            </div>
        </div>   
        </>
    )
}