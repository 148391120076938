import React from 'react'
import { EmptyPage } from '../../components/templates'
import VippsLogoColor from '../../assets/images/vipps-logo-color.svg'
import './../../components/modules/modal-login/style.scss'
import './style.scss'

export default function LogIn(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalLogin active'>
                <div className='modalLogin__wrapper'>
                
                    <div className='modalLogin__wrapper--content logIn-vipps-content'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        <div className='content'>
                            <h2>Logg inn med Vipps </h2>

                            <img className='vippsLogoColor' src={VippsLogoColor} alt="Vipps" />

                            <div className='loginMobile'>
                                <div className='input'>
                                    <label>Mobil nummer</label>
                                    <input type="tel"/>
                                </div>

                                <div className='btnLoginWrapper'>
                                    <button  className='btn btn-primary lg'>Fortsett</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>              
            </div>
  
        </EmptyPage> 
    )
}