import React from 'react'
import { Default } from '../../components/templates'
import './style.scss'

export default function PageList(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className="searchResult">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-2 col-lg-8">

                            <div className="searchResult__title">
                                <h1>Søkeresultat</h1>               
                            </div>

                            <div className="searchResult__input">
                                <form className='input'>
                                    <span className="icon-MagnifyingGlass"></span>
                                    <input type="text" placeholder='Ditt søk her'/>
                                    <button type="submit" value="Søk">Søk</button>
                                </form>     
                            </div>

                            <div className="searchResult__result">

                                <div className="lists">

                                    <div className="list">
                                        <div className="title"><h2>Auksjoner / Kategorier</h2></div>
                                        <div className="items">
                                            <div className="item">
                                                <span className="text">Klokker og ur</span>
                                                <span className="total"><a href="#">Klokker  ( 29 treff )</a></span>
                                            </div>
                                            <div className="item">
                                                <span className="text">Antikviteter</span>
                                                <span className="total"><a href="#">Klokker  ( 16 treff )</a></span>
                                            </div>
                                            <div className="item">
                                                <span className="text">Glass</span>
                                                <span className="total"><a href="#">Klokker  ( 216 treff )</a></span>
                                            </div>
                                            <div className="item">
                                                <span className="text">Asiatiske antikviteter og kunst</span>
                                                <span className="total"><a href="#">Klokker  ( 4 treff )</a></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list">
                                        <div className="title"><h2>Artikler</h2></div>
                                        <div className="items">
                                            <div className="item">
                                                <span className="text">Artikler</span>
                                                <span className="total"><a href="#">Klokker som samleobjekt</a></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list">
                                        <div className="title"><h2>Tilslagsliste / Kategorier</h2></div>
                                        <div className="items">
                                            <div className="item">
                                                <span className="text">Klokker og ur</span>
                                                <span className="total"><a href="#">Klokker  ( 19 treff )</a></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list">
                                        <div className="title"><h2>Kalender</h2></div>
                                        <div className="items">
                                            <div className="item">
                                                <span className="text">Arramgnet</span>
                                                <span className="total"><a href="#">Vurderingsarramgnet klokker mars -19 / 2022</a></span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <></>
        </Default>
    )
}