import { useState, useEffect, useRef } from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'
import '../style.scss'

export default function ModalObjectView(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalObjectView active'>
                <div className='modalObjectView__wrapper'>
                    <div className='modalObjectView__wrapper--content'>

                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                        
                        <div className='content'>
                            <div className='wrapper'>

                                <div className='title'>
                                    <h2>Budhistorikk</h2>
                                    <h3>Hvilke objekt gjelder dette for - navn - objekt nr</h3>
                                </div>

                                <div className='bidHistory'>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-1'>12</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                        </div>
                                        <div className="value">4.400 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-2'>11</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span>
                                        </div>
                                        <div className="value">4.300 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-1'>12</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                        </div>
                                        <div className="value">4.200 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-3'>9</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span>
                                        </div>
                                        <div className="value">4.100 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-1'>12</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                        </div>
                                        <div className="value">4.000 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-4'>12</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span>
                                        </div>
                                        <div className="value">3.900 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-1'>12</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                        </div>
                                        <div className="value">4.400 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-2'>11</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span>
                                        </div>
                                        <div className="value">4.300 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-1'>12</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                        </div>
                                        <div className="value">4.200 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-3'>9</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span>
                                        </div>
                                        <div className="value">4.100 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-1'>12</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span> <span className='auto'>Auto</span>
                                        </div>
                                        <div className="value">4.000 NOK</div>
                                    </div>
                                    <div className='item'>
                                        <div className="number">
                                            <span className='color-4'>12</span>
                                            </div>
                                        <div className="dateHour">
                                            <span className="text">07.Okt - 16:11:36</span>
                                        </div>
                                        <div className="value">3.900 NOK</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Lukk vindu</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}