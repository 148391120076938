import React from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'


import '../style.scss'

export default function modalFilter(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalFilter active'>
                <div className='modalFilter__wrapper'>
                    <div className='modalFilter__wrapper--content'>
                        
                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                       
                        <div className='content'>
                            <div className='wrapper'>
                                <h2>Velg land</h2>

                                <div className='listHeader'>
                                    <div className='buttons'>
                                        <span className="icon-ArrowLeft"></span>
                                        <button> Land </button> / <button>  Frankrike </button>
                                    </div>
                                </div>

                                <div className='listItem'>
                                    <button>Vin (1238) <span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Frankrike<span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Vin (1238) <span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Frankrike<span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Vin (1238) <span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Frankrike<span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Vin (1238) <span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Frankrike<span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Vin (1238) <span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                    <button>Frankrike<span className="icon-ArrowRight"></span></button>
                                    <button>Brennevin (420) <span className="icon-ArrowRight"></span></button>
                                </div>
                            </div>                            
                        </div>

                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Avslutt</button>
                            <button className='btn btn-primary sm'>Vis 340 treff</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}