import React,{useState} from 'react'
import ImageObject from '../../../assets/images/sell1.png'
import ImageObject2 from '../../../assets/images/sell2.png'
import ImageObject3 from '../../../assets/images/sell3.png'
import ImageObject4 from '../../../assets/images/sell4.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import './style.scss'


export default function MyBinds(): JSX.Element{

    const Settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
          }          
        ]
    };


    return(
        <>
        <div className='myBinds'>
            
            <div className='container'>
                <div className='row'>

                    <div className='myBinds__content'>

                        <div className="myBinds__content--wrapper">
                            
                            <div className='title'>
                                <h2 className='desktop'>Mine bud</h2>
                                <a className='mobile' href="#"><h2>Mine bud <span className='total'>(8)</span><span className="icon-ArrowRight"></span></h2></a>
                            </div>

                            <Slider {...Settings}>

                                <div className='item'> 
                                    <a href="#">
                                        <div className="content">
                                            <div className='image'>                                                                               
                                                <img src={ImageObject} alt="" />                                            
                                            </div>
                                            <div className='info'>
                                                <div>
                                                    <div className='name'>Erik Werenskiold</div>
                                                    <div className='line'>Ditt bud: <strong>75.000 NOK</strong></div>
                                                </div>
                                                <div>
                                                    <span className='tag green'>Du leder</span>
                                                </div>
                                            </div>                                        
                                        </div>                                        
                                    </a>
                                </div>

                                <div className='item'> 
                                   <a href="#">
                                    <div className="content">
                                            <div className='image'>                                       
                                                <img src={ImageObject4} alt="" />
                                            </div>
                                            <div className='info'>
                                                <div>
                                                    <div className='name'>Erik Werenskiold</div>
                                                    <div className='line'>Ditt bud: <strong>75.000 NOK</strong></div>
                                                </div>
                                                <div>
                                                    <span className='tag green'>Du leder</span>
                                                </div>
                                            </div>                                        
                                        </div>                                       
                                   </a>
                                </div>

                                <div className='item'> 
                                    <a href="#">
                                        <div className="content">
                                            <div className='image'>                                        
                                                <img src={ImageObject3} alt="" />
                                            </div>
                                            <div className='info'>
                                                <div>
                                                    <div className='name'>Erik Werenskiold</div>
                                                    <div className='line'>Ditt bud: <strong>75.000 NOK</strong></div>
                                                </div>
                                                <div>
                                                    <span className='tag red'>Du er overbydd</span>
                                                </div>
                                            </div>                                        
                                        </div>                                        
                                    </a>
                                </div>

                                <div className='item'> 
                                    <a href="#">
                                        <div className="content">
                                            <div className='image'>
                                                <img src={ImageObject2} alt="" />
                                            </div>
                                            <div className='info'>
                                                <div>
                                                    <div className='name'>Erik Werenskiold</div>
                                                    <div className='line'>Ditt bud: <strong>75.000 NOK</strong></div>
                                                </div>
                                                <div>
                                                    <span className='tag red'>Du er overbydd</span>
                                                </div>
                                            </div>                                        
                                        </div>                                        
                                    </a>
                                </div>
                                
                            </Slider>

                            <div className="mobileList">

                                <div className='item'> 
                                    <a href="#">
                                        <div className="content">
                                            <div className='image'>                                                                               
                                                <img src={ImageObject} alt="" />                                            
                                            </div>
                                            <div className='info'>
                                                <div>
                                                    <div className='name'>Erik Werenskiold <span className="icon-CaretRight"></span></div>
                                                    <div className='line'>Ditt bud: <strong>75.000 NOK</strong></div>
                                                </div>
                                                <div>
                                                    <span className='tag green'>Du leder</span>
                                                </div>
                                            </div>                                        
                                        </div>                                        
                                    </a>
                                </div>

                                <div className='item'> 
                                   <a href="#">
                                    <div className="content">
                                            <div className='image'>                                       
                                                <img src={ImageObject4} alt="" />
                                            </div>
                                            <div className='info'>
                                                <div>
                                                    <div className='name'>Erik Werenskiold <span className="icon-CaretRight"></span></div>
                                                    <div className='line'>Ditt bud: <strong>75.000 NOK</strong></div>
                                                </div>
                                                <div>
                                                    <span className='tag green'>Du leder</span>
                                                </div>
                                            </div>                                        
                                        </div>                                       
                                   </a>
                                </div>

                            </div>                                


                            <div className='seeAll'>
                                <a href="#">Se alle</a>
                            </div>

                        </div>

                    </div>


                </div>
            </div>
        
        </div>        
        </>
    )
}