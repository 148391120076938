import React from 'react'
import { Default } from '../../../components/templates'
import SelectSearch from 'react-select-search'

export default function ModalEditProfile(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalEditProfile active'> */}
        <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Levering av vin</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="place">Utleveringssted</label>
                                <div className='select'>
                                    <SelectSearch
                                        options={[
                                            { value: 'option1', name: 'Option1'},
                                            { value: 'Fornebu', name: 'Fornebu'},
                                            { value: 'option2', name: 'Option2'},
                                            { value: 'option3', name: 'Option3'},
                                            { value: 'option4', name: 'Option4'},
                                            { value: 'option5', name: 'Option5'}
                                        ]}
                                        placeholder="Utleveringssted"
                                        value={'Fornebu'}
                                    />
                                </div>
                                <div className='hint-text' >Her kan du velge utlevering av vin. Har du ikke valgt noe hentes vinen hos oss på Fornebu.</div>                                      
                            </div>

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>    
        <></>
        
        </Default>        
    )
}