import react from 'react';
import Image1 from '../../../assets/images/excellentList1.png'
import Image2 from '../../../assets/images/excellentList2.png'
import Image3 from '../../../assets/images/excellentList3.png'
import './style.scss'



export default function ObjectsForPayment(): JSX.Element{

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10"> 
                    <div className="objectsForPayment">
                        <div className="objectsForPayment__header">
                            <div className='info'>
                                <span className='title'>Til betaling <span>(110.000 NOK)</span></span>                                
                            </div>
                            <div className='options'>
                                <button className='btn-link'>Endre</button>
                                <button className='btn-link'>Avslutt</button>
                            </div>
                        </div>
                        <div className="objectsForPayment__list">

                            <div className="item">
                                <div className="imageWrapper">
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image2} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image3} alt="" />
                                    </div>
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                    
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='totalItems'>5 objekter valgt</h3>
                                        <h3 className='totalItems mobile'>Antall objekter: 5</h3>                                        
                                    </div>
                                    <div className="payment">
                                        <div className="value">Totalt 110.000 NOK</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>                        
                </div>
            </div>
        </div>   
        </>
    )
}