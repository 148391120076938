import React from 'react'
import { EmptyPage } from '../../../components/templates'
import '../../../components/modules/modal-filter/style.scss'


import '../style.scss'

export default function modalFilter(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalFilter active'>
                <div className='modalFilter__wrapper'>
                    <div className='modalFilter__wrapper--content'>
                        
                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                       
                        <div className='content'>
                            <div className='wrapper'>
                                <h2>Velg Kategori</h2>

                                <div className='listHeader'>
                                    <div className='buttons'>
                                        <span className="icon-ArrowLeft"></span>
                                        <button> Kunst </button>  
                                    </div>
                                    <button className='reset'>Nullstill</button>
                                </div>

                                <div className='radioButtonWrapperFull'>
                                <div className='radioButtonWrapperFull__item'>
                                    <input type="radio" id="item1" name="accordion1" value="Alle " defaultChecked={true}/>
                                    <label htmlFor="item1">Alle</label>
                                </div>
                                <div className='radioButtonWrapperFull__item'>
                                    <input type="radio" id="item2" name="accordion1" value="Klassisk auksjon (282)" />
                                    <label htmlFor="item2">Klassisk auksjon (282)</label>
                                </div>
                                <div className='radioButtonWrapperFull__item'>
                                    <input type="radio" id="item3" name="accordion1" value="Nettauksjon (1824)" />
                                    <label htmlFor="item3">Nettauksjon (1824)</label>
                                </div>     
                                <div className='radioButtonWrapperFull__item'>
                                    <input type="radio" id="item4" name="accordion1" value="Alle " defaultChecked={true}/>
                                    <label htmlFor="item4">Alle</label>
                                </div>
                                <div className='radioButtonWrapperFull__item'>
                                    <input type="radio" id="item5" name="accordion1" value="Klassisk auksjon (282)" />
                                    <label htmlFor="item5">Klassisk auksjon (282)</label>
                                </div>
                                <div className='radioButtonWrapperFull__item'>
                                    <input type="radio" id="item6" name="accordion1" value="Nettauksjon (1824)" />
                                    <label htmlFor="item6">Nettauksjon (1824)</label>
                                </div>                            
                                </div>
                            </div>
                        </div>
                        
                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Avslutt</button>
                            <button className='btn btn-primary sm'>Vis 340 treff</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}