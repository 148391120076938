import React,{useState} from 'react'
import ImageObject from '../../../assets/images/special-auction1.png'
import ImageObject2 from '../../../assets/images/special-auction2.png'
import ImageObject3 from '../../../assets/images/special-auction3.png'
import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import './style.scss'


export default function SpecialAuctionCarousel(): JSX.Element{

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1420,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 3,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 1200,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 3,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 890,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 2,
                slidesToScroll: 1
            }
          },
          {
            breakpoint: 575,
            settings: {
                arrows: false,
                variableWidth: true,
                initialSlide: 0,
                slidesToShow: 1,
                slidesToScroll: 1
            }
          }
          
        ]
    };


    return(
        <>
        <div className='specialAuctionCarousel'>
            
            <div className='container'>
                <div className="row">
                    <h2 className='specialAuctionCarousel__title'>Samlinger og spesialauksjoner </h2>
                </div>
                <div className='row'>
                    <div className='specialAuctionCarousel__content'>

                        <div className="specialAuctionCarousel__content--wrapper">
                            
                            <Slider {...settings}>

                                <a href='#' className='item'>
                                    <div className="content">
                                        <div className='image'>    
                                            <figure className='img aspectRatio--323x269'>
                                                <img src={ImageObject} alt="" />
                                            </figure>
                                        </div>
                                        <div className='info'>
                                            <h3 className='name'>Gucci Vintage</h3>
                                            <div className='line'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non sem ultrices.</div>
                                        </div>                                        
                                    </div>
                                </a>

                                <a href='#' className='item'>
                                   <div className="content">
                                        <div className='image'>                                        
                                            <figure className='img aspectRatio--323x269'>
                                                <img src={ImageObject2} alt="" />
                                            </figure>
                                        </div>
                                        <div className='info'>
                                            <h3 className='name'>Street art</h3>
                                            <div className='line'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non sem ultrices.</div>                                       
                                        </div>
                                   </div>
                                </a>

                                <a href='#' className='item'>
                                    <div className="content">
                                        <div className='image'> 
                                            <figure className='img aspectRatio--323x269'>
                                                <img src={ImageObject3} alt="" />
                                            </figure>
                                        </div>
                                        <div className='info'>
                                            <h3 className='name'>Thorolf Holmboe</h3>
                                            <div className='line'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non sem ultrices.</div>                                       
                                        </div>                                        
                                    </div>
                                </a>

                                <a href='#' className='item'>
                                   <div className="content">
                                    <div className='image'>
                                            <figure className='img aspectRatio--323x269'>
                                                <img src={ImageObject2} alt="" />
                                            </figure>
                                        </div>
                                        <div className='info'>
                                            <h3 className='name'>Street art</h3>
                                            <div className='line'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non sem ultrices.</div>                                       
                                        </div>                                       
                                   </div>
                                </a>

                            </Slider>

                        </div>

                    </div>


                </div>
            </div>
        
        </div>        
        </>
    )
}