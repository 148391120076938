import React, { useState } from 'react';
import ImageBLock3 from '../../../assets/images/image-block-8.png'
import YouTubeIframe, { Commands } from './react-youtube-iframe';
import './style.scss'

export default function VideoArticle(): JSX.Element{

    const [player, setplayer] = useState<Commands>(Commands.pause);    
    const [activePlayer,setActivePlayer] = useState(false)
    
    function playVideo() {
        setplayer(Commands.play)
        setActivePlayer(!activePlayer)
    }
    
    function closeVideo() {
        setplayer(Commands.pause)
        setActivePlayer(!activePlayer)
    }

    return(
        <>
        <div className={"videoArticle " + `${activePlayer?'active':''}`}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='videoArticle__wrapper'>
                            <figure className='img aspectRatio--842x559'>
                                <img className='thumbnail' src={ImageBLock3} alt="" />
                                <YouTubeIframe command={player} videoId='RzVvThhjAKw' />
                                <button className="btnPlay" onClick={playVideo}></button>
                                <button className="btnClosePlay" onClick={closeVideo}><span className="icon-X"></span></button>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}