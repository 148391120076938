import React from 'react'
import ImageBLock1 from '../../../assets/images/image-block-1.png'
import ImageBLock2 from '../../../assets/images/image-block-2.png'
import './style.scss'

export default function ImageBlock(): JSX.Element{

    return(
        <>
        <div className='imageBlockPortrait-2'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='imageBlockPortrait-2__wrapper'>

                            <div className="items">
                                <div className="item item1">
                                    <figure className='img aspectRatio--405x571'>
                                        <img src={ImageBLock1} alt="" />
                                    </figure>                                
                                </div>

                                <div className="item item2">
                                    <figure className='img aspectRatio--405x571'>
                                        <img src={ImageBLock2} alt="" />
                                    </figure>                                
                                </div>                                
                            </div>
                                
                        </div>   
                    </div>
                </div>
            </div>
        </div>        
        </>
    )
}