import react from 'react';
import Image1 from '../../../assets/images/contactCard-1.png'
import Image2 from '../../../assets/images/contactCard-2.png'
import './style.scss'



export default function ContactCards(): JSX.Element{

    return(
        <>
        <div className='contactCards'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='contactCards__wrapper'>

                            <div className="contactCards__wrapper--card">
                                <div className="image">
                                    <figure className='img aspectRatio--1x1'>
                                        <img src={Image1} alt="" />
                                    </figure>
                                </div>
                                <div className="info">
                                    <div>
                                        <span className="line name">Fornebu</span>
                                        <span className="line">Rolfsbuktveien 4 e-f,</span>
                                        <span className="line">1364 Fornebu</span>
                                    </div>
                                    <div>
                                        <span className="line"><a href="tel:+4745939200">(+47) 45 93 92 00</a></span>
                                        <span className="line"><a href="mailto:kontakt@blomqvist.no">kontakt@blomqvist.no</a></span>
                                    </div>
                                </div>
                            </div>

                            <div className="contactCards__wrapper--card">
                                <div className="image">
                                    <figure className='img aspectRatio--1x1'>
                                        <img src={Image2} alt="" />
                                    </figure>
                                </div>
                                <div className="info">
                                    <div>
                                        <span className="line name">Bergen</span>
                                        <span className="line">Neumanns gate 30,</span>
                                        <span className="line">5015 Bergen</span>
                                    </div>
                                    <div>
                                        <span className="line"><a href="tel:+4746835823">(+47) 46 83 58 23</a></span>
                                        <span className="line"><a href="mailto:merete.heggernes@blomqvist.no">merete.heggernes@blomqvist.no</a></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}