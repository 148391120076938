import { useState, useEffect } from 'react';
import Image1 from '../../../assets/images/excellentList1.png'
import Image2 from '../../../assets/images/excellentList2.png'
import Image3 from '../../../assets/images/excellentList3.png'
import './style.scss'



export default function ExcellentList(): JSX.Element{

    const [options,setOptions] = useState(false)
    const toggleOptions = ()=>{
        setOptions(!options)
    }

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="offset-lg-1 col-lg-10">
                    <div className="excellentList">
                        <div className="excellentList__header">
                            <div className='info'>
                                <span className='value'>Utestående <span>(15.000 NOK)</span></span>
                                <button className='btn-link'>Betal for flere objekter</button>
                            </div>
                            <div className='options'>
                                <button><span className='icon-Filter'></span></button>
                                <button><span className='icon-MagnifyingGlass'></span></button>
                                <button className='mobile' onClick={toggleOptions}><span className='icon-DotsThreeOutline'></span></button>
                            </div>

                            <div className={"optionsMobile " + `${options?'active':''}`}>
                                <div className='optionsMobile__wrapper'>
                                    <div className='optionsMobile__wrapper--content'>

                                        <div className='btnCloseModalWrapper'>
                                            <button className='btnCloseModal' aria-label='Close' onClick={toggleOptions}><span className='icon-X'></span></button>
                                        </div>
                                        
                                        <div className='content' id='contentH'>
                                            <div className='wrapper' id='wrapperH'>

                                                <div className="items">
                                                    <div className="item">
                                                        <button><span className='icon-CreditCard'></span>Betal for flere objerkter</button>             
                                                    </div>
                                                    <div className="item">
                                                        <button><span className='icon-Filter'></span>Søk</button> 
                                                    </div>
                                                    <div className="item">
                                                        <button><span className='icon-MagnifyingGlass'></span>Filter</button>                                                     
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>              
                            </div>                                

                        </div>
                        <div className="excellentList__list">

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image1} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="number desktop">Objektnummer: <strong >141151-1</strong></div>
                                        <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                        <div className="delivery">Levering : <strong>Postpakke</strong></div>
                                    </div>
                                    <div className="payment">
                                         <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                         <div className="line"> Autotrekk: 14.10.2022  |  Visa **** 4385</div>
                                    </div>
                                </div>
                            </a>


                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image2} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="number desktop">Objektnummer: <strong>141151-1</strong></div>
                                        <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                        <div className="delivery">Utlevering : <strong> Hentes på Fornebu</strong></div>
                                    </div>
                                    <div className="payment">
                                         <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                         <div className="line"> Autotrekk: 14.10.2022  |  Visa **** 4385</div>
                                    </div>
                                </div>
                            </a>                            

                            <a className="item" href='#'>
                                <div className="image">
                                    <img src={Image3} alt="" />
                                </div>
                                <div className="info">
                                    <div className="data">
                                        <h3 className='name'>Navn på objekt</h3>
                                        <div className="number desktop">Objektnummer: <strong>141151-1</strong></div>
                                        <div className="value mobile">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                        <div className="delivery">Utlevering : <strong>Hentes i Bergen fra 10.01.22</strong></div>
                                    </div>
                                    <div className="payment">
                                         <div className="value">Utestående: <strong className='red'>50.000 NOK</strong></div>
                                         <div className="line"> Autotrekk: 14.10.2022 | Visa **** 4385</div>
                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>                        
                </div>
            </div>
        </div>   
        </>
    )
}