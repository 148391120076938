import React from 'react'
import { Default } from '../../../components/templates'
import SelectSearch from 'react-select-search'
import {
    PasswordInput
} from '../../../components/primitives'

import './style.scss'

export default function Profile(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='profile'>

                <div className='headerOptions'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span> Min side</a>
                                <h1>Min side</h1>                         
                            </div>
                        </div>
                    </div>    
                </div>

                <div className='profile__form'>
                    <div className='container'>
                        <div className='row'>
                            <div className='offset-lg-3 col-lg-6'>
                        
                                <div className='section'>

                                    <div className='header'>
                                        <h3>Personalia</h3>
                                        <button><span className='icon-Pencil'></span></button>
                                    </div>
                                                                            
                                    <div className='form-group'>
                                        <label htmlFor="customer-number">Kundenummer</label>
                                        <input type="tel" defaultValue="345679" id="customer-number"/>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="name">Navn</label>
                                        <input type="text" defaultValue="Elisabeth" id="name"/>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="last-name">Etterna vn</label>
                                        <input type="text" defaultValue="Henriksen" id="last-name"/>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="birth">Født</label>
                                        <input type="text" defaultValue="01.05.1996" id="birth"/>
                                    </div>

                                </div>

                                <div className='section'>

                                    <div className='header'>
                                        <h3>Firma</h3>
                                    </div>

                                    <div className='form-group'>
                                        <button className='link-button'>Legg til firmainformasjon</button>                                         
                                    </div>

                                </div>

                                <div className='section'>

                                    <div className='header'>
                                        <h3>Passord</h3>
                                        <button><span className='icon-Pencil'></span></button>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="Passord">Passord</label>
                                        <PasswordInput/>                                        
                                    </div>

                                </div>

                                <div className='section'>

                                    <div className='header'>
                                        <h3>Kontakt</h3>
                                        <button><span className='icon-Pencil'></span></button>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="email">E-post</label>
                                        <input type="email" defaultValue="elisabethhenriksen@gmail.com" id="email"/>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="tel">Mobil</label>
                                        <input type="tel" defaultValue="92098647" id="tel"/>
                                    </div>

                                </div>

                                <div className='section'>

                                    <div className='header'>
                                        <h3>Adresse</h3>
                                        <button><span className='icon-Pencil'></span></button>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="address">Gateadresse</label>
                                        <input type="text" defaultValue="Sorgenfrigata 10A" id="address"/>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="postal-code">Postnummer</label>
                                        <input type="tel" defaultValue="0367" id="postal-code"/>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="city">Poststed</label>
                                        <input type="text" defaultValue="0367" id="city"/>
                                    </div>
                                    
                                    <div className='form-group'>
                                        <label htmlFor="country">Land</label>
                                        <input type="text" defaultValue="Norge" id="country"/>
                                    </div>

                                </div>

                                <div className='section'>

                                    <div className='header'>
                                        <h3>Leveringsadresse</h3>
                                    </div>

                                    <div className='form-group'>
                                        <button className='link-button'>Legg til firmainformasjon</button>
                                        <div className='hint-text' >Om du ikke har leveringsadresse brukes faktura til levering</div>                                      
                                    </div>

                                </div>

                                <div className='section'>

                                    <div className='header'>
                                        <h3>Levering av vin</h3>
                                        <button><span className='icon-Pencil'></span></button>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="place">Utleveringssted</label>
                                        <div className='select'>
                                            <SelectSearch
                                                options={[
                                                    { value: 'option1', name: 'Option1'},
                                                    { value: 'Fornebu', name: 'Fornebu'},
                                                    { value: 'option2', name: 'Option2'},
                                                    { value: 'option3', name: 'Option3'},
                                                    { value: 'option4', name: 'Option4'},
                                                    { value: 'option5', name: 'Option5'}
                                                ]}
                                                placeholder="Utleveringssted"
                                                value={'Fornebu'}
                                            />
                                        </div>
                                        <div className='hint-text' >Her kan du velge utlevering av vin. Har du ikke valgt noe hentes vinen hos oss på Fornebu.</div>                                      
                                    </div>

                                </div>

                                <div className='section'>

                                    <div className='header'>
                                        <h3>Annet</h3>
                                        <button><span className='icon-Pencil'></span></button>
                                    </div>

                                    <div className='form-group'>
                                        <label htmlFor="lenguage">Språk</label>
                                        <div className='select'>
                                            <SelectSearch
                                                options={[
                                                    { value: 'option1', name: 'Option1'},
                                                    { value: 'Fornebu', name: 'Fornebu'},
                                                    { value: 'option2', name: 'Option2'},
                                                    { value: 'option3', name: 'Option3'},
                                                    { value: 'option4', name: 'Option4'},
                                                    { value: 'option5', name: 'Option5'}
                                                ]}
                                                placeholder="Utleveringssted"
                                                value={'Fornebu'}
                                            />
                                        </div>
                                        <div className='hint-text' >Her kan du velge utlevering av vin. Har du ikke valgt noe hentes vinen hos oss på Fornebu.</div>    
                                    </div>

                                </div>

                                <div className='section'>

                                    <div className='header'>
                                        <h3>Avslutt kontoen</h3>
                                        {/* <button><span className='icon-Pencil'></span></button> */}
                                    </div>

                                    <div className='form-group'>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu non, orci dictumst fames. Tortor leo ipsum vitae placerat id pharetra ultrices.</p> 
                                        <button className='btn btn-secondary lg'>Avslutt konto</button>
                                    </div>

                                </div>
                    
                            </div>
                        </div>
                    </div>    
                </div>

            </div>   
            <></>

        </Default>        
    )
}