import React from 'react'
import { Default } from '../../components/templates'
import Image1 from '../../assets/images/employees.png'

import './style.scss'

export default function Employees(): JSX.Element{
    return(        
        <Default headerColor="background">

            <div className='employees'>

                <div className='employees__header background'>

                    <div className='breadCrumbs article'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <a href="#" className='breadCrumbs__item'>Breadcrumb</a>
                                    <span className='icon-CaretRight'></span>
                                    <a href="#" className='breadCrumbs__item last'>Breadcrumb</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='intro'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='wrapper'>
                                        <h1>Våre ansatte</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat dolor nisi molestie non risus volutpat. Netus dapibus nisi dolor aliquet lobortis vitae nullam. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            
                <div className='employees__list'>

                    <div className="categories">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>                                                                
                                    <div className="categories__items">
                                        <button className='item active'>Vis alle</button>
                                        <button className='item'>Blomqvist</button>
                                        <button className='item'>Eksperter</button>
                                        <button className='item'>Fotografer</button>
                                        <button className='item'>Regnskap</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    

                    <div className="section">   
                        <div className='container'>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h2>Blomqvist</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div> 

                    <div className="section">   
                        <div className='container'>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h2>Eksperter</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div> 

                    <div className="section">   
                        <div className='container'>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h2>Fotografer</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div> 

                    <div className="section">   
                        <div className='container'>

                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h2>Regnskap</h2>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <div className="item">
                                        <figure className='img aspectRatio--296x204'>
                                            <img src={Image1} alt="" />
                                        </figure>
                                        <div className="info">
                                            <span className='name'>Knut Forsberg</span>
                                            <span className="line">Daglig leder</span>
                                            <span className="line"><a href="mailto:knut@blomqvist.no">knut@blomqvist.no</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div> 


                </div>

            </div>   
            <></>     
        </Default>        
    )
}