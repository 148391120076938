 import React from 'react'
import { EmptyPage } from '../../../../components/templates'
import '../../../../components/modules/modal-filter/style.scss'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import './style.scss'

export default function modalFilter(): JSX.Element{
    return(        
        <EmptyPage>

            <div className='modalFilter active'>
                <div className='modalFilter__wrapper'>
                    <div className='modalFilter__wrapper--content'>

                        <div className='btnCloseModalWrapper'>
                            <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                        </div>
                        
                        <div className='content'>
                            <div className='wrapper'>
                                <h2>Sortering</h2>

                                <div className="accordionFilter">
                                    <Accordion preExpanded={['item1']} allowMultipleExpanded>
                                        <AccordionItem uuid="item1">
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Sorter etter <span className="icon-CaretDown"></span>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div className='radioButtonWrapper'>                                            
                                                <div className='radioButtonWrapper__item'>
                                                        <input type="radio" id="item1" name="accordion1" value="Alle (2140)" defaultChecked={true}/>
                                                        <label htmlFor="item1">Pris, høy til lav</label>
                                                </div>
                                                    <div className='radioButtonWrapper__item'>
                                                        <input type="radio" id="item2" name="accordion1" value="Klassisk auksjon (282)" />
                                                        <label htmlFor="item2">Pris, lav til høy</label>
                                                    </div>
                                                    <div className='radioButtonWrapper__item'>
                                                        <input type="radio" id="item3" name="accordion1" value="Nettauksjon (1824)" />
                                                        <label htmlFor="item3">Flest bud</label>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>

                            </div>
                        </div>

                        <div className='btnWrapper'>
                            <button className='btn btn-gray sm'>Avslutt</button>
                            <button className='btn btn-primary sm'>Vis 340 treff</button>
                        </div>

                    </div>
                </div>              
            </div> 
  
        </EmptyPage> 
    )
}