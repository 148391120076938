import React from 'react'
import { Default } from '../../../../components/templates'
import Image1 from '../../../../assets/images/excellentList1.png'
import SelectSearch from 'react-select-search'
import {
    SalesContractsList
} from '../../../../components/modules'

export default function MyPurchasesPayments(): JSX.Element{
    return(        
        <Default headerColor="light">

            <div className='headerOptions'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="#" className='backMyPage' > <span className='icon-ArrowLeft'></span>Min side</a>
                            <h1>Mine salg og kontrakter</h1>                         
                        </div>
                    </div>
                </div>    
            </div>


            {/* <SalesContractsList/> */}

            <div className="salesAndContractsList">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10">

                            <div className="salesAndContractsList__header">
                                <div className='tabs'>

                                    <div className="desktop">
                                        <button className='item'>Mine salgsobjekter (136)</button>
                                        <button className='item active'>Mine kontrakter (20)</button>
                                    </div>

                                    <div className="mobile">
                                        <div className='select'>
                                            <SelectSearch
                                                options={[
                                                    { value: 'Mine salgsobjekter (136)', name: 'Mine salgsobjekter (136)'},
                                                    { value: 'Mine kontrakter (20)', name: 'Mine kontrakter (20)'} 
                                                ]}
                                                placeholder="Salgsobjekter"
                                                value={'Fornebu'}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className='options'>
                                    <button><span className='icon-MagnifyingGlass'></span></button>                                    
                                    <button><span className='icon-Filter'></span></button>
                                </div>
                            </div>

                            {/* Use the module SalesContractsList */}
                            {/* <SalesContractsList/> */}

                            <div className="salesAndContractsList__list">

                                <a className="item" href='#'>
                                    <div className="imageWrapper">
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>                                    
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Kontrakt: 2418685</h3>
                                            <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                            <div className="line">Antall objekter: <strong>8</strong></div>
                                            
                                            <div className='tags showMobile'><span className='tag orange'>Ikke signert</span></div>
                                        </div>
                                        <div className="payment">
                                            <div className='tags'><span className='tag orange'>Ikke signert kontrakt</span></div>
                                        </div>
                                    </div>
                                </a>

                                <a className="item" href='#'>
                                    <div className="imageWrapper">
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>                                  
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Kontrakt: 2418685</h3>
                                            <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                            <div className="line">Antall objekter: <strong>3</strong></div>
                                            
                                            <div className='tags showMobile'><span className='tag orange'>Ikke signert</span></div>
                                        </div>
                                        <div className="payment">
                                            <div className='tags'><span className='tag orange'>Ikke signert kontrakt</span></div>
                                        </div>
                                    </div>
                                </a>

                                <a className="item" href='#'>                             
                                    <div className="image">
                                        <img src={Image1} alt="" />
                                    </div>
                                
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Kontrakt: 2418685</h3>
                                            <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                            <div className="line">Antall objekter: <strong>1</strong></div>
                                            
                                            <div className='tags showMobile'><span className='tag orange'>Ikke signert</span></div>
                                        </div>
                                        <div className="payment">
                                            <div className='tags'><span className='tag orange'>Ikke signert kontrakt</span></div>
                                        </div>
                                    </div>
                                </a>

                                <a className="item" href='#'>
                                    <div className="imageWrapper">
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>                             
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Kontrakt: 2418685</h3>
                                            <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                            <div className="line">Antall objekter: <strong>2</strong></div>
                                            
                                            <div className='tags showMobile'><span className='tag green'>Signert</span></div>
                                        </div>
                                        <div className="payment">
                                            <div className='tags'><span className='tag green'>Signert</span></div>
                                        </div>
                                    </div>
                                </a>
                                
                                <a className="item" href='#'>
                                    <div className="imageWrapper">
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>                             
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Kontrakt: 2418685</h3>
                                            <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                            <div className="line">Antall objekter: <strong>2</strong></div>
                                            
                                            <div className='tags showMobile'><span className='tag green'>Signert</span></div>
                                        </div>
                                        <div className="payment">
                                            <div className='tags'><span className='tag green'>Signert</span></div>
                                        </div>
                                    </div>
                                </a>

                                <a className="item" href='#'>
                                    <div className="imageWrapper">
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>
                                        <div className="image">
                                            <img src={Image1} alt="" />
                                        </div>                             
                                    </div>
                                    <div className="info">
                                        <div className="data">
                                            <h3 className='name'>Kontrakt: 2418685</h3>
                                            <div className="line hideMobile">Registrert: <strong>15.10.21 - 14:35</strong></div>
                                            <div className="line">Antall objekter: <strong>2</strong></div>
                                            
                                            <div className='tags showMobile'><span className='tag green'>Signert</span></div>
                                        </div>
                                        <div className="payment">
                                            <div className='tags'><span className='tag green'>Signert</span></div>
                                        </div>
                                    </div>
                                </a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <></>

        </Default>        
    )
}