import React from 'react'
import { Default } from '../../components/templates'

import { 
    CookieBanner
} from '../../components/modules'

export default function CookieBanner1(): JSX.Element{
    return(        
        <Default headerColor="light">
            <></>        
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> 
            <CookieBanner color="copper-40"/>    
        </Default>        
    )
}