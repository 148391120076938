import React from 'react'
import { Default } from '../../../components/templates'
import {
    PasswordInput
} from '../../../components/primitives'


export default function ModalEditProfile(): JSX.Element{
    return(        
        <Default headerColor="light">

        {/* Add .active class to enable login modal: <div className='modalEditProfile active'> */}
        <div className='modalEditProfile active'>
            <div className='modalEditProfile__wrapper'>
                <form className='modalEditProfile__wrapper--content'>

                    <div className='btnCloseModalWrapper'>
                        <button className='btnCloseModal' aria-label='Close'><span className='icon-X'></span></button>
                    </div>
                    
                    <div className='content' id='contentH'>
                        <div className='wrapper' id='wrapperH'>

                            <div className='title'>
                                <h2>Endre passord</h2>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="Passord">Gammalt passord</label>
                                <PasswordInput/>                                        
                            </div>    

                            <div className='form-group'>
                                <label htmlFor="Passord">Nytt passord</label>
                                <PasswordInput/>                                        
                            </div>                             

                        </div>
                    </div>

                    <div className='btnWrapper'>
                        <button className='btn btn-primary sm'>Lagre endringer</button>
                    </div>

                </form>
            </div>              
        </div>    
        <></>
        
        </Default>        
    )
}