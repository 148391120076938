import React from 'react'
import { Default } from '../../../components/templates'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { RelatedArticles } from '../../../components/modules'
import './style.scss'

export default function HowToSell(): JSX.Element{
    return(        
        <Default headerColor="background">

            <div className='howToSell'>

                <div className='howToSell__header background'>

                    <div className='breadCrumbs article'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <a href="#" className='breadCrumbs__item'>Selge hos Blomqvist</a>
                                    <span className='icon-CaretRight'></span>
                                    <a href="#" className='breadCrumbs__item last'>Hvordan selge?</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='intro'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='wrapper'>
                                        <h1>Hvordan selge?</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat dolor nisi molestie non risus volutpat. Netus dapibus nisi dolor aliquet lobortis vitae nullam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            
                <div className='howToSell__howToSellSteps'>

                    <div className="tabWrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="tabs">
                                        <button className='active'>Nett og klassisk auksjon</button>
                                        <button>Vinauksjon</button>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>                                
                                
                                <div className="intro">
                                    <p>Blomqvist tilbyr salg og taksering av det beste som finnes på markedet. Våre klassiske auksjoner konsentrerer seg om kunstverk i høye prisklasser og av topp kvalitet av de aller mest kjente norske og utenlandske kunstnerne. Vi tilbyr også diskret salgsformidling av kostbare kunstverk utenom auksjon.</p>
                                </div>
                                
                                <div className="steps">

                                    <div className="step">
                                        <div className="title">
                                            <h2>Steg 1 - Vurdering</h2>
                                        </div>
                                        <div className="text">
                                            <p>Velg hvilken vurderingsmetode som passer best for deg og ditt objekt</p>
                                        </div>

                                        <div className='accordionStep'>
                                            <Accordion allowMultipleExpanded allowZeroExpanded>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Besøk oss <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <div className='content'>
                                                            <p>content</p>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Onlinevurdering <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                    <div className='content'>
                                                            <p>content</p>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            Hjemmebesøk <span className="icon-CaretDown"></span>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <div className='content'>
                                                            <p>content</p>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>

                                    </div>

                                    <div className="step">
                                        <div className="title">
                                            <h2>Steg 2 - Kontrakt</h2>
                                        </div>
                                        <div className="text">
                                            <p>Når du har fått vurderingen, gir våre eksperter deg råd om hvilken auksjon som er mest fordelaktig for å oppnå best pris. Du inngår kontrakt med Blomqvist, som inneholder ekspertenes prisvurdering, en minstepris og våre salgsvilkår. Deretter er ditt objekt klart for høydepunktet – auksjon og salg.</p>
                                        </div>
                                    </div>

                                    <div className="step">
                                        <div className="title">
                                            <h2>Steg 3 - Salg og utbetaling</h2>
                                        </div>
                                        <div className="text">
                                            <p>Når ditt auksjonsobjekt er solgt, vil du motta en bekreftelse på salget. Vi gjør avregning i henhold til kontrakten, og oppgjør blir overført ca. 30 dager etter auksjonen, forutsatt at kjøper har betalt.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>                
                </div>            
            </div>   
            <RelatedArticles/>   
        </Default>        
    )
}